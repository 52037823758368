//write a template for a basic react component

import React from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const FinalConfirmDialogHacerExclusion = ({onConfirmation}) => {
  return (
    <>
    <Dialog
  open={true}
  maxWidth="lg"
  onClose={() => {
    // setShowExclusionConfirmCustomDialog(false);
  }}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <DialogTitle id="alert-dialog-title" style={{ color: "orange", textAlign: "center" }}>
    Confirmar Resolución de Exclusión
  </DialogTitle>
  <DialogContent>
    <DialogContentText style={{ textAlign: "center" }}>
      Esta acción es irreversible, por favor confirmar.
    </DialogContentText>
  </DialogContent>
  <DialogActions style={{ justifyContent: "center" }}>
    <Button
      onClick={() => {onConfirmation("confirmed")}}
      color="primary"
    >
      Confirmar
    </Button>
    <Button
      onClick={() => {onConfirmation("cancelled")}}
      color="primary"
    >
      Cerrar
    </Button>
  </DialogActions>
</Dialog>

     
    </>
  );
}

export default FinalConfirmDialogHacerExclusion;