import Base from "./axiosBase";

const OficinaMateriaService = {
  createOficinaMateria: async (OficinaMateria) => Base.post("/oficina-materia/", OficinaMateria),
  deleteOficinaMateria: async (OficinaMateria) =>
    Base.delete(`oficina-materia/?id=${OficinaMateria.id}&action=${OficinaMateria.action}`).then(
      (res) => res.data
    ),
  updateOficinaMateria: async (OficinaMateria) =>
    Base.put(`oficina-materia/?id=${OficinaMateria.id}`, OficinaMateria).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getOficinaMateria: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.filters) {
      if (params.filters.oficina) {
        url += `id_oficina=${params.filters.oficina.id}`;
      }
      if (params.filters.materia) {
        url += `&id_materia=${params.filters.materia.id}`;
      }
      if (params.filters.habilitado !== undefined) {
        //console.log("WHAT THE HELL");
        //console.log(params.filters.habilitado);
        if (!params.filters.habilitado) {
          url += `&habilitado=D`;
        } else {
          url += `&habilitado=H`;
        }
      }
    }
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (url !== "") {
      url = `oficina-materia/?${url}`;
    } else {
      url = "oficina-materia/";
    }
 
    return Base.get(url).then((res) => res.data);
  },
};

export default OficinaMateriaService;
