import React, { useState, useEffect } from "react";

// @mui material components
import { Box, Button, Card, Grid, IconButton, Icon, Tooltip } from "@mui/material";

// Local components and utilities
import MDBox from "components/MDBox";
import MUIDataTable from "mui-datatables";
import DetailSorteoCustomDialog from "./DetailSorteoDialog/DetailSorteoCustomDialog";
import ResolucionExclusionCustomDialog from "./../../SorteoResolucionExclusion/index.tsx";
import HacerLugarExclusionCustomDialog from "./../../HacerLugarExclusion/index.tsx";
import DeleteDialog from "./DeleteDialog/Crud.js";
import Filters from "./filters/filters.tsx";
import Message from "#administracion-components/Notifications/Message";
import ModalAlert from "#components/GenericPermissionsAlert/ModalAlert.tsx";
import { truncateString } from "services/utils";

// Icons
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Visibility from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import HowToRegIcon from "@mui/icons-material/HowToReg";
// Services
import SorteoService from "services/Sorteo.js";
import SorteoQuery from "services/sorteoQuery.js";
import "./index.css";

interface LocalFilters {
  fechaDesde: Date;
  fechaHasta: Date;
  tipoSorteoTipo: string;
  tipoSorteo: string;
  estado: string;
  materia: string;
  shortId: string;
  origen: string;
  numeroExpediente: string;
  caratulaExpediente: string;
  juez: string;
  userName: string;
  descripcionFuncion: string;
  tipoAgrupadorSorteo: string;
}
function MainDataTable() {
  const [tableOptions, setTableOptions] = useState({});
  // const [tableData, setTableData] = useState([]);

  const tOptions = {
    storageKey: "table-fueros",
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    count: 7,
    page: 0,
    onTableChange: (action, table) => {
      //console.log(action);
      //console.log(table);
      if (action === "changePage") {
        //console.log("change page event");

        // setTableOptions(tOptions);
        async function getData(params) {
          const response = await SorteoQuery.getSorteo(params);
          //console.log("setting table data");
          //console.log(response);

          if (response.error) {
            if (
              response.message_code == "user-cu-not-authoridez" ||
              response.message_code == "err-usher"
            ) {
              setInsufficientPermissions(true);
            }
            return;
          }
          setTableOptions((prev) => ({
            ...prev,
            count: response.count,
            page: table.page,
            data: response.data || [],
          }));
        }
        getData({
          page: table.page * table.rowsPerPage || 0,
          rowsPerPage: table.rowsPerPage || 10,
          sortOrder: "DESC",  filters: filtersState,
        });
      }
    },
    data: [],
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };

  const [displayFilters, setDisplayFilters] = useState(false);
  const [showDialogResolucionExclusion, setShowDialogResolucionExclusion] = useState(false);
  const [showDialogDelete, setShowDialogDelete] = useState(false);
  // const [crudAction, setCrudAction] = useState("create");
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [showHacerLugarDialog, setShowHacerLugarDialog] = useState(false);
  const [selectedRowDetailDialogData, setSelectedRowDetailDialogData] = useState(null);
  const [insufficientPermissions, setInsufficientPermissions] = useState(false);
  // const [crudData, setCrudData] = useState({});
  // const [tiposSorteo, setTiposSorteo] = useState([]);
  // const [origenes, setOrigenes] = useState([]);
  // const [updateTable, setUpdateTable] = useState(false);
  const [filtersState, setFiltersState] = useState<LocalFilters>({
    fechaDesde: null,
    fechaHasta: null,
    tipoSorteoTipo: "",
    tipoSorteo: "",
    estado: "",
    materia: "",
    shortId: "",
    origen: "",
    numeroExpediente: "",
    caratulaExpediente: "",
    juez: "",
    descripcionFuncion: "",
    userName: "",
    tipoAgrupadorSorteo: "todos",
  });

  const columns = [
    {
      label: "Tipo de Sorteo",
      name: "descripcion_oficina",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>{tableOptions.data[dataIndex].tipo_tipo_sorteo.toUpperCase()} </div>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].tipo_sorteo.charAt(0).toUpperCase() +
                  tableOptions.data[dataIndex].tipo_sorteo.slice(1)}
              </p>
            </div>
            <div className="deshabilitadoFueroFont">
              {/* {tableOptions.data[dataIndex].habilitado ? "" : "Deshabilitado"} */}
            </div>
          </span>
        ),
      },
    },
    { label: "Nro. Expediente", name: "numero_expediente", options: { display: "true" } },
    {
      label: "Carátula",
      name: "caratula_expediente",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {truncateString(tableOptions.data[dataIndex].caratula_expediente)[0] +
                  truncateString(tableOptions.data[dataIndex].caratula_expediente)[1]}
              </p>
            </div>
          </span>
        ),
      },
    },
    { label: "Materia", name: "descripcion_materia", options: { display: "true" } },
    {
      label: "Origen",
      name: "descripcion_oficina",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>{tableOptions.data[dataIndex].descripcion_oficina}</div>

            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].descripcion_fuero}
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Estado",
      name: "estado",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].estado_actual_sorteo}
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Fecha",
      name: "fecha_sorteo",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].fecha_sorteo}
              </p>
            </div>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].hora_sorteo}
              </p>
            </div>
          </span>
        ),
      },
    },

    { label: "Fecha de Creacion", name: "fecha_creacion", options: { display: "false" } },
    { label: "Habilitado", name: "habilitado", options: { display: "false" } },
    {
      label: "Acciones",
      name: "acciones",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        viewColumns: false,
        customBodyRenderLite: (dataIndex, rowIndex) => {
          const rowData = tableOptions.data[dataIndex];
          const isRegularSorteo = rowData.tipo_sorteo === "regular";
          const isResolucionExclusion = rowData.tipo_sorteo === "resolucion-exclusion";
          const isPendienteResolver = rowData.estado_actual_sorteo === "pendiente-resolver";
          const isActiveOrResolved =
            rowData.estado_actual_sorteo === "inactivo" ||
            rowData.estado_actual_sorteo === "resuelto";
          const isActive = rowData.estado_actual_sorteo === "activo";
          return (
            <Box display="flex" alignItems="center">
              {/* Resolver Exclusión */}
              {!isActiveOrResolved && !isRegularSorteo && (
                <Tooltip title="Resolver Exclusión">
                  <IconButton
                    aria-label="Resolver Exclusión"
                    onClick={() => {
                      setShowHacerLugarDialog(true);
                      setSelectedRowDetailDialogData(rowData);
                    }}
                    style={{ marginRight: 8 }}
                  >
                    <HowToRegIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* Ver Detalle */}
              <Tooltip title="Ver Detalle">
                <IconButton
                  aria-label="Ver Detalle"
                  onClick={() => {
                    setShowDetailDialog(true);
                    setSelectedRowDetailDialogData(rowData);
                  }}
                  style={{ marginRight: 8 }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>

              {/* Hacer sorteo de Resolución de Exclusión */}
              {!isActiveOrResolved && (
                <Tooltip title="Hacer sorteo de Resolución de Exclusión">
                  <IconButton
                    aria-label="Hacer sorteo de Resolución de Exclusión"
                    onClick={() => {
                      setShowDialogResolucionExclusion(true);
                      setSelectedRowDetailDialogData(rowData);
                    }}
                    style={{ marginRight: 8 }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* Anular Sorteo Regular */}
              {isRegularSorteo && isActive && (
                <Tooltip title="Anular Sorteo Regular">
                  <IconButton
                    aria-label="Anular Sorteo Regular"
                    onClick={() => {
                      setShowDialogDelete(true);
                      setSelectedRowDetailDialogData(rowData);
                    }}
                    style={{ marginRight: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* Anular Sorteo ResolucionExclusion */}

              {isPendienteResolver && isResolucionExclusion && (
                <Tooltip title="Anular Sorteo Resolucion Exclusion">
                  <IconButton
                    aria-label="Anular Sorteo Resolucion Exclusion"
                    onClick={() => {
                      //alert("pending implementation")
                      setShowDialogDelete(true);

                      setSelectedRowDetailDialogData(rowData);
                      // Add your delete logic here
                    }}
                    style={{ marginRight: 8 }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}

              {/* Additional icons can be added here following the same pattern */}
            </Box>
          );
        },
      },
    },
  ];

  useEffect(() => {
    //setShowMessage(true);
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await SorteoQuery.getSorteo(params);
      //console.log("setting table data");
      //console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filtersState,
    });
  }, []);

  useEffect(() => {
    //console.log("child filters changed");
    //console.log(filtersState);

    if (
      filtersState.tipoSorteo == "" &&
      filtersState.habilitado == true &&
      filtersState.fechaDesde == null &&
      filtersState.fechaHasta == null &&
      filtersState.origen == "" &&
      filtersState.estado == "" &&
      filtersState.caratula == "" &&
      filtersState.anulado == false &&
      filtersState.tipoAgrupadorSorteo == "todos"
    ) {
      //if clean filters is applied from child component
      applyFiltersHandler();
    }
  }, [filtersState]);

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................
  const buscarHandler = () => {
    setDisplayFilters((currentValue) => !currentValue);
  };
  const applyFiltersHandler = () => {
    //childFilters apply button clicked.

    setTableOptions(tOptions);
    async function getData(params) {
      const response = await SorteoQuery.getSorteo(params);
      //console.log("setting table data");
      //console.log(response);

      if (response.error) {
        if (
          response.message_code == "user-cu-not-authoridez2" ||
          response.message_code == "err-usher2"
        ) {
          setInsufficientPermissions(true);
        }
        return;
      }

      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filtersState,
    });
  };

  const exportCSVHandler = () => {
    //console.log("export csv");

    //console.log("export csv");

    async function getDataCSV(params) {
      const response = await SorteoQuery.getSorteo(params);
      //console.log("getting csv data");
      //console.log(response);

      if (response.error) {
        if (
          response.message_code === "user-cu-not-authoridez2" ||
          response.message_code === "err-usher2"
        ) {
          setInsufficientPermissions(true);
        }
        return;
      }

      // Filter out columns that start with 'id_'
      const columns = Object.keys(response.data[0]).filter((key) => !key.startsWith("id_"));

      // Create the first row with column names
      const headerString = columns.join(",");

      // Map each row of data to a CSV string, including only the filtered columns
      const rowStrings = response.data.map((row) => {
        return columns.map((column) => row[column]).join(",");
      });

      // Combine headers and rows
      const csvContent = [headerString, ...rowStrings].join("\n");

      // Blob and download
      const blob = new Blob([csvContent], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      let date = new Date();
      let formattedDate =
        ("0" + date.getDate()).slice(-2) +
        "-" +
        ("0" + (date.getMonth() + 1)).slice(-2) +
        "-" +
        date.getFullYear();
      let formattedTime =
        ("0" + date.getHours()).slice(-2) + ("0" + date.getMinutes()).slice(-2) + "hr";
      let fileName = "reporte_sorteos " + formattedDate + "-" + formattedTime + ".csv";
      a.setAttribute("download", fileName);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      if (response.error) {
        if (
          response.message_code == "user-cu-not-authoridez2" ||
          response.message_code == "err-usher2"
        ) {
          setInsufficientPermissions(true);
        }
        return;
      }
    }
    getDataCSV({
      export: true,
      sortOrder: "DESC",
      filters: filtersState,
    });
  };

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................

  return (
    <>
      {insufficientPermissions && <ModalAlert />}
      <Card sx={{ height: "100%" }}>
        <Box sx={{ m: 1, p: 1 }}>
          <Grid container spacing={1} textAlign="right">
            <Grid item xs={12} md={6} lg={12}>
              {/* <Button
                color="warning"
                variant="contained"
                startIcon={<AddIcon />}
                style={{ marginRight: "10px" }}
                onClick={() => {
                 
                  setCrudData({ data: {}, index: {} }); // clear edit data
                  setCrudAction("create");
                  setDisplayDialog(true);
                }}
              >
                Nuevo
              </Button> */}
              <Button
                className={displayFilters ? "selectedButton" : "unselectedButton"}
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={buscarHandler}
              >
                Buscar
              </Button>
              {displayFilters && (
                <Filters
                  filtersState={filtersState}
                  setFiltersState={setFiltersState}
                  applyFiltersHandler={applyFiltersHandler}
                  exportCSVHandler={exportCSVHandler}
                />
              )}
            </Grid>  
          </Grid>
        </Box>

        <MUIDataTable
          title="Sorteos Efectuados"
          data={tableOptions.data}
          columns={columns}
          options={tableOptions}
        />
      </Card>
      {showMessage && <Message message={message} setShowMessage={setShowMessage} />}
      {showDetailDialog && (
        <DetailSorteoCustomDialog
          selectedRowDetailDialogData={selectedRowDetailDialogData}
          setShowDetailDialog={setShowDetailDialog}
          showDetailDialog={showDetailDialog}
        />
      )}

      {showHacerLugarDialog && (
        <HacerLugarExclusionCustomDialog
          selectedRowDetailDialogData={selectedRowDetailDialogData}
          setShowHacerLugarDialog={setShowHacerLugarDialog}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
          setFiltersState={setFiltersState}
          applyFiltersHandler={applyFiltersHandler}
          filtersState={filtersState}
        />
      )}

      {showDialogResolucionExclusion && (
        <ResolucionExclusionCustomDialog
          selectedRowDetailDialogData={selectedRowDetailDialogData}
          setShowDialogResolucionExclusion={setShowDialogResolucionExclusion}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
          setFiltersState={setFiltersState}
          applyFiltersHandler={applyFiltersHandler}
          filtersState={filtersState}
        />
      )}

      {showDialogDelete && (
        <DeleteDialog
          selectedRowDetailDialogData={selectedRowDetailDialogData}
          setShowDialogDelete={setShowDialogDelete}
          showDialogDelete={showDialogDelete}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
        />
      )}
    </>
  );
}

export default MainDataTable;
