// declare a function that converts a date into the format YYYY-MM-DD
function formatDate(date) {
  //console.log("input date value")
  //console.log(date)
  const d = new Date(date);
  const month = "" + (d.getMonth() + 1);
  const day = "" + d.getDate();
  const year = d.getFullYear();

  return [year, month, day].join("/");
}

function truncateString(str) {
  const stringArr = ["", "", ""];
  if (str.length >= 45) {
   
    stringArr[0] = str.slice(0, 45);
    stringArr[1] = str.slice(45, 90);
    return stringArr;
  }
  if (str.length < 45) {
   
     stringArr[0]=str;
    return stringArr
  }
  if (str.length > 90) {
   
    stringArr[0] = str.slice(0, 45);
    stringArr[1] = str.slice(45, 90) + "...";
    return stringArr;
  }
 
return stringArr;
}

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
function stringAvatar(name: string) {
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
  };
}

function getEstadosSorteo() {
return ["activo","anulado","pendiente-resolucion-exclusion","pendiente-resolver","resuelto"]
}
// eslint-disable-next-line import/prefer-default-export
export { formatDate, truncateString, stringToColor, stringAvatar, getEstadosSorteo };
