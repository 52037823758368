import Base from "./axiosBase";
import { formatDate } from "#services/utils";
const SorteoService = {
  createSorteo: async (sorteo) => Base.post("/sorteo-regular/", sorteo),
  deleteSorteo: async (sorteo) =>
    Base.delete(
      `sorteo-regular/?id=${sorteo.id}&id_oficina=${sorteo.id_oficina}&id_usuario=${sorteo.id_usuario}&motivo=${sorteo.motivo}`
    ).then((res) => res.data),
  updateSorteo: async (sorteo) => Base.put(`sorteo-regular/`, sorteo).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    //console.log("params");
    //console.log(params);
    if (localStorage.getItem("id_fuero") == null) {
      alert("debe establecer oficina en la opcion  de administracion=>editar oficina");
    }
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.export) {
      url += `&export=${params.export}`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.idSorteo) {
      url += `&id=${params.idSorteo}`;
    }
    if (url !== "") {
      url = `sorteo-query/?id_fuero=${
        localStorage.getItem("id_fuero") + url
      }&id_oficina=${localStorage.getItem("id_oficina")}`;
    } else {
      url = `sorteo-query/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}`;
    }
    if (params.filters) {
      if (params.filters.fechaDesde) {
        const fomattedDate = formatDate(params.filters.fechaDesde);
        url += `&fecha_sorteo_desde=${fomattedDate}`;
      }
      if (params.filters.fechaHasta) {
        const fomattedDate = formatDate(params.filters.fechaHasta);
        url += `&fecha_sorteo_hasta=${fomattedDate}`;
      }
      if (params.filters.tipoSorteo) {
        url += `&id_tipo_sorteo=${params.filters.tipoSorteo.id}`;
      }
      if (params.filters.estado) {
        url += `&estado=${params.filters.estado}`;
      }

      if (params.filters.anulado) {
        url += `&descripcion=${params.filters.anulado}`;
      }

      if (params.filters.caratula) {
        url += `&caratula_expediente=${params.filters.caratula}`;
      }

      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default SorteoService;
