import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect,useRef } from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import FueroTipoSorteoService from "services/Fuero-Tipo-Sorteo";
import TipoExclusionService from "services/Tipo-Exclusion";
import FueroExclusionService from "services/Fuero-Exclusion";
import SRE from "services/SRE";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { formatOBJSRE } from "./BL.tsx";
import { validateFormData } from "./validateData.tsx";
import "./styles.css";
import isEqual from 'lodash/isEqual';
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Skeleton from "@mui/material/Skeleton";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { usePreviousMonthDisabled } from "@mui/x-date-pickers/internals";
import { PlumbingRounded, Preview } from "@mui/icons-material";
import SREService from "services/SRE";
import { stringToColor, stringAvatar } from "#services/utils.js";

function ResolucionExclusionCustomDialog({
  selectedRowDetailDialogData,
  setShowDialogResolucionExclusion,
  setMessage,
  setShowMessage,
  setFiltersState,
  applyFiltersHandler,
  filtersState,
}) {
  const [idSorteo, setIdSorteo] = useState(null);
  const [loadingJuecesElegibles, setLoadingJuecesElegibles] = useState(true);
  const [habilitadoExclusion, setHabilitadoExclusion] = useState(false);
  const [tiposSorteo, setTiposSorteo] = useState([]);
  const [tiposExclusion, setTiposExclusion] = useState([]);
  const [SREJuezElegible, setSREJuezElegible] = useState([]);
  const [selectedTipoSorteoHeader, setSelectedTipoSorteoHeader] = useState(null);
  const [DummyStateForUpdateRender, setDummyStateForUpdateRender] = useState([]);
  const [checkedJuecesSRE, setCheckedJuecesSRE] = React.useState([]);
  const [errorsRobot, setErrorsRobot] = React.useState([]);
  const [errorsRobotInvissible, setErrorsRobotInvissible] = React.useState([]);
  const [errrosRobotInvisible, setErrorsRobotInvisible] = React.useState([]);
  const [resolverButtonDisabled, setResolverButtonDisabled] = React.useState(false);

  async function setSorteosData(params) {
    ////console.log("got tiposSorteo");
    const response = await FueroTipoSorteoService.getSorteo(params);
    let sorteos_ = response.data;
    ////console.log("sorteos_");
   
    setTiposSorteo(sorteos_);
  }
  async function setTiposExclusionData(params) {
    ////console.log("got tipos exclusion");
    const response = await FueroExclusionService.getFueroExclusion(params);
    //console.log("response")
    //console.log(response)
    //filter only resolucion exclusion from response.data array
    let tiposExclusion_ = response.data.filter((v) => v.tipo == "expediente");
    ////console.log("tiposExclusion_");
    ////console.log(tiposExclusion_);
    setTiposExclusion(tiposExclusion_);
  }
  async function getSREJuezElegible(params) {
    ////console.log("got tiposSorteo");
    const response = await SRE.getSREJuezElegible(params);
    //console.log("response");
    //console.log(response);
    setLoadingJuecesElegibles(false);
    if (response.error) {
      setMessage({
        severity: "error",
        messageText: response.human_message,
      });
      setShowMessage(true);
      return;
    }
   
      setHabilitadoExclusion(true);
  

    let purgedJueces = removeJuezSorteadoRepeated(response.data);
    purgedJueces.juecesPurgedList.forEach((element) => {
      element.checked = false;
      element.observaciones = "";
    });
    setSREJuezElegible(purgedJueces);
  }

  function resolverButtonHandler() {
    //console.log(errorsRobotInvissible)
    
    //setResolverButtonDisabled(true);
    ////console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN CANCELARBTN");
    //validations
    // let validationResult = validateFormData(
    //   SREJuezElegible,
    //   selectedTipoSorteoHeader
    // ).errorsHuman;
    setErrorsRobot(
      validateFormData(SREJuezElegible, selectedTipoSorteoHeader).errorsRobot
    );
      let errorsResolver= validateFormData(SREJuezElegible, selectedTipoSorteoHeader).errorsRobot
      //console.log("^^^^^^^^^^^^^^^^^^^^^^^^^")
      //console.log(errorsResolver)
      //console.log("^^^^^^^^^^^^^^^^^^^^^^^^^")
      if(errorsResolver.length>0){return}
      setResolverButtonDisabled(true);
    SREService.createSRE(formatOBJSRE(SREJuezElegible, selectedTipoSorteoHeader))
      .then((response) => {
        setResolverButtonDisabled(false);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        ////console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Sorteo de Resolucion de Exclusion creado.",
        });
        setShowMessage(true);
        // setDisplayDialogParent(false);
        setFiltersState({ ...filtersState, page: 0 });
        ////console.log("response");
        ////console.log(response);
        setShowDialogResolucionExclusion(false);
      })
      .catch((e) => {
        // setLoading(false);
        ////console.log(e);
      });
    };
  function removeJuezSorteadoRepeated(jueces) {
    //as a joined list will be shown, there will be a repeated judge for every juez_elegible there will be the same record in the juez_sorteadolist. remove
    let sorteados = jueces.jueces_sorteados_list || [];
    let elegibles = jueces.jueces_elegibles_list || [];
    let sorteadoMod = [];
    sorteados.forEach((s) => {
      //need to set a property that indicates this is a sorteado
      const sMod = { ...s, elegible: false };  
      sorteadoMod.push(sMod);
    });
    let elegiblesMod = [];
    elegibles.forEach((e) => {
      //need to set a property that indicates this is a elegible
      const sMod = { ...e, elegible: true };
     
      elegiblesMod.push(sMod);
    });


    let purged_list = [];

    let arrayA = sorteadoMod;
    let arrayB = elegiblesMod;
    // Create a map of elements in arrayB for faster lookup
    const mapB = new Map(arrayB.map((item) => [item.id_juez, item]));

    // Iterate through arrayA and update the 'elegible' property
    arrayA.forEach((itemA) => {
      const matchingItemB = mapB.get(itemA.id_juez);
      if (matchingItemB) {
        itemA.elegible = true;
      }
    });
    ////console.log("AI");
    ////console.log(arrayA);
    purged_list = arrayA;

    jueces.juecesPurgedList = purged_list;
    ////console.log("PURGED LIST");
    ////console.log(jueces);
    ////console.log("PURGED LIST");
    return jueces;
  }
 
  const handleCheckBox = (value) => () => {
    const found = SREJuezElegible.juecesPurgedList.find(
      (element) => element.id_juez === value.id_juez
    );
    ////console.log("found");
    ////console.log(found);
    //const indexFound = SREJuezElegible.juecesPurgedList.indexOf(found);
    const newArr = SREJuezElegible.juecesPurgedList.map((item) => {
      if (item.id_juez === value.id_juez) {
        item.checked = !item.checked;
      }
      return item;
    });
    ////console.log("gonna mod main obj3");
    ////console.log({ ...SREJuezElegible, juecesPurgedList: newArr });
    setSREJuezElegible({ ...SREJuezElegible, juecesPurgedList: newArr });
    ////console.log("SREJuezElegible.jueces_elegibles_list");
    ////console.log(SREJuezElegible.juecesPurgedList);
  };
  function findSorteosID(jueces_elegibles_list, id_juez) {
    ////console.log("finding tiposSorteo id");
    jueces_elegibles_list.forEach((element) => {
      if (element.id_juez === id_juez) {
        ////console.log("found and returning");
        ////console.log(element.tipoExclusionSelected?.id || null);
        return element.tipoExclusionSelected?.id_tipo_exclusion || null;
      }
    });
    return null;
  }

  useEffect(() => {
    setSorteosData({filters:{grupo:"resolucion-exclusion",fuero:localStorage.getItem("id_fuero"),habilitado:"H"}});
    setTiposExclusionData({filters:{tipo:"expediente",habilitado:"H"}});
    ////console.log("selectedRowDetailDialogIndex");
    getSREJuezElegible({ idSorteo: selectedRowDetailDialogData.id_sorteo });
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth="lg"
        onClose={() => {
          ////console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN OUTSIDE ONCLOSE");
          //  setShowExclusionConfirmCustomDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
        Nuevo Sorteo de Resolución de Exclusión
          {/* {exclusionCustomDialogData?.messageText || ""} */}
          {/* {GeneralDialogData.severity} */}
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            className="detailDialog"
            id="alert-dialog-description"
            component={"span"}
          >
            {/* previous line is a fix where material ui puts a div inside a p tag gotta add component= {'span'} prop */}
            <div className="detail-container">
              <div className="top">
                <div>
                  <span style={{ fontWeight: "900" }}>
                    <h2>{selectedRowDetailDialogData.caratula_expediente ?? ""}</h2>
                  </span>
                </div>

                <span style={{ justifyContent: "right" }}>
                  {selectedRowDetailDialogData.numero_expediente ?? ""}
                </span>
              </div>
              <div className="sub-detail">
                <div className="a">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fuero</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_fuero ?? ""}
                </div>
                <div className="b">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>N Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.codigo_sorteo ?? ""}
                </div>
                <div className="c">
                  {" "}
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Materia</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_materia ?? ""}
                </div>
                <div className="d">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Oficina</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_oficina ?? ""}
                </div>
                <div className="e">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Origen</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_origen ?? ""}
                </div>
                <div className="f">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Tipo de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_tipo_sorteo ?? ""}
                </div>
                <div className="g">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Estado de Sorteo</u>
                    </span>
                  </div>
                  {(selectedRowDetailDialogData.estado_actual_sorteo ?? "").toUpperCase()}
                </div>
                <div className="h">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fecha de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.fecha_sorteo +
                    " " +
                    selectedRowDetailDialogData.hora_sorteo}
                </div>
                <div className="i">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Creado Por</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.username ?? ""}
                </div>
              </div>
            </div>

            <Divider></Divider>

            {!loadingJuecesElegibles && !habilitadoExclusion ? (
              <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
                No hay jueces elegibles para este sorteo
              </div>
            ) : (
              <div class="sre-selection-container">
                <div class="judgeSelector">
                  <Autocomplete
                    id="combo-box-dem2"
                    options={tiposSorteo}
                    key={tiposSorteo.id}
                    value={selectedTipoSorteoHeader}
                    sx={{ width: 300, marginTop: "20px", marginBottom: "40px" }}
                    getOptionLabel={(option) => option.descripcion_tipo_sorteo}
                    renderInput={(params) => <TextField {...params} label="Tipo de Sorteo." />}
                    onChange={(event, newValue) => {
                      setSelectedTipoSorteoHeader(newValue);
                    }}
                  />
                  <span style={{ color: "#B90C0C", marginBottom: "45px" }}>
                    {errorsRobot.find((e) => e == "tipo_sorteo") == null ? "" : "Elegir Tipo"}
                  </span>
                  <h3 style={{ marginLeft: "50px" }}>Listado de Jueces</h3>
                  <span style={{ color: "#B90C0C" }}>
                    {errorsRobot.find((e) => e == "juez_checked_required") == null
                      ? ""
                      : "Elegir al menos 1 juez a excluir"}
                  </span>
                  <span style={{ color: "#B90C0C", marginBottom: "45px" }}>
                    {errorsRobot.find((e) => e == "observaciones_required") == null ? "" : "Falta Observacion(> 3 characteres)"}
                  </span>
<div></div>
                   <span style={{ color: "#B90C0C", marginBottom: "45px" }}>
                    {errorsRobot.find((e) => e == "juez_tipo_exclusion_required") == null ? "" : "Falta Tipo de Exclusion"}
                  </span>

                  
                  <List
                    dense
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {loadingJuecesElegibles && (
                      <>
                        <Stack spacing={1}>
                          <Skeleton variant="rectangular" width={210} height={60} />
                          <Skeleton variant="rectangular" width={210} height={60} />
                          <Skeleton variant="rectangular" width={210} height={60} />
                        </Stack>
                      </>
                    )}

                    {SREJuezElegible.juecesPurgedList?.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value}`;
                      return (
                        <>
                          {value.elegible && (
                            <ListItem
                              key={value.id_juez}
                              secondaryAction={
                                <Checkbox
                                  edge="end"
                                  onChange={handleCheckBox(value)}
                                  //   checked={checkedJuecesSRE.indexOf(value) !== -1}
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              }
                              disablePadding
                            >
                              <ListItemButton>
                                <ListItemAvatar>
                                  <Avatar
                                    {...stringAvatar(value.nombre_juez + " " + value.apellido_juez)}
                                  />
                                  {/* <Avatar
                        alt={`Avatar n°${value.nombre_juez + value.apellido_juez + 1}`}
                        src={`/static/images/avatar/${value + 1}.jpg`}
                      /> */}
                                </ListItemAvatar>
                                <ListItemText
                                  id={labelId}
                                  primary={`Juez: ${value.nombre_juez + " " + value.apellido_juez}`}
                                  secondary={`Funcion: ${value.funcion}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          )}

                          {!value.elegible && (
                            <ListItem key={value.id_juez} disablePadding>
                              <ListItemButton>
                                <ListItemAvatar>
                                  <Avatar
                                    {...stringAvatar(value.nombre_juez + " " + value.apellido_juez)}
                                  />
                                  {/* <Avatar
                        alt={`Avatar n°${value.nombre_juez + value.apellido_juez + 1}`}
                        src={`/static/images/avatar/${value + 1}.jpg`}
                      /> */}
                                </ListItemAvatar>
                                <ListItemText
                                  id={labelId}
                                  primary={`Juez: ${value.nombre_juez + " " + value.apellido_juez}`}
                                  secondary={`Funcion: ${value.funcion}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          )}

                          {value.checked && (
                            <>
                              {tiposSorteo.map(
                                (v) =>
                                  v.id ==
                                  findSorteosID(SREJuezElegible.juecesPurgedList, value.id_juez)
                              )}
                              <div style={{ marginTop: "20px", marginLeft: "30px" }}>
                                <Autocomplete
                                  id="combo-box-dem2"
                                  options={tiposExclusion}
                                  key={tiposExclusion.id_tipo_exclusion}
                                  value={tiposExclusion.find(
                                    (v) =>
                                      v.id_tipo_exclusion ==
                                      findSorteosID(SREJuezElegible.juecesPurgedList, value.id_juez)
                                  )}
                                  sx={{ width: 300, marginBottom: "15px" }}
                                  getOptionLabel={(option) => option.descripcion_tipo_exclusion ?? ""}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Tipo de Exclusion." />
                                  )}
                                  onChange={(event, newValue) => {
                                    //console.log(newValue);
                                    setDummyStateForUpdateRender([
                                      ...DummyStateForUpdateRender,
                                      { newValue, id_juez_elegible: value.id_juez },
                                    ]); //weird things is that this is not needed at all, but it makes the render work on real time when choosing a value of the next dropdown.(TODO fix this)
                                    let index = SREJuezElegible.juecesPurgedList.findIndex(
                                      (x) => x.id_juez == value.id_juez
                                    );
                                    let newOBJ = SREJuezElegible.juecesPurgedList[index];
                                    newOBJ = { ...newOBJ, tipoExclusionSelected: newValue };
                                    let modifiedState = SREJuezElegible;
                                    modifiedState.juecesPurgedList[index] = newOBJ;
                                    ////console.log("gonna mod main obj1");
                                    ////console.log(modifiedState);
                                    setSREJuezElegible(modifiedState);
                                  }}
                                />
                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="Observaciones"
                                  multiline
                                  maxRows={4}
                                  style={{ width: "300px" }}
                                  sx={{ marginBottom: "15px" }}
                                  value={
                                    SREJuezElegible.juecesPurgedList.find(
                                      (v) => v.id == value.id_juez
                                    )?.observaciones
                                  }
                                  onChange={(event) => {
                                    ////console.log(event.target.value);
                                    setDummyStateForUpdateRender([
                                      ...DummyStateForUpdateRender,
                                      { id_juez_elegible: value.id_juez },
                                    ]); //weird things is that this is not needed at all, but it makes the render work on real time when choosing a value of the next dropdown.(TODO fix this)
                                    let index = SREJuezElegible.juecesPurgedList.findIndex(
                                      (x) => x.id_juez == value.id_juez
                                    );
                                    let newOBJ = SREJuezElegible.juecesPurgedList[index];
                                    newOBJ = { ...newOBJ, observaciones: event.target.value };
                                    let modifiedState = SREJuezElegible;
                                    modifiedState.juecesPurgedList[index] = newOBJ;
                                    ////console.log("gonna mod main obj2");
                                    setSREJuezElegible(modifiedState);
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <Divider id="divi" style={{ height: "3px", opacity: 0.99 }} />
                        </>
                      );
                    })}
                  </List>
                </div>
                {/* <div class="buttons">accept</div> */}
                <div class="selectedDetail">
                  {SREJuezElegible.juecesPurgedList?.find((value) => value.checked == true) !==
                    undefined && <h3>Jueces Elegidos</h3>}

                  <div style={{ marginTop: "30px" }}>
                    {SREJuezElegible.juecesPurgedList?.map((value) => {
                      return (
                        <>
                          {value.checked && (
                            <>
                              <div>Juez: {value.nombre_juez + " " + value.apellido_juez}</div>
                              <div>
                                Tipo de Exclusion:{value.tipoExclusionSelected?.descripcion_tipo_exclusion}
                              </div>
                              <div>Observaciones:{value.observaciones}</div>

                              <Divider></Divider>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!loadingJuecesElegibles && !habilitadoExclusion ? (
            ""
          ) : (
            <Button
              disabled={resolverButtonDisabled}
              onClick={resolverButtonHandler}
              autoFocus
            >
              Sortear
            </Button>
          )}

          <Button
            onClick={() => {
              ////console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN CANCELARBTN");
              setShowDialogResolucionExclusion(false);
            }}
            autoFocus
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default ResolucionExclusionCustomDialog;
