import Base from "./axiosBase";

const HacerLugarRE = {
  resolver: async (sorteo) => Base.put(`/resolucion-exclusion/`, sorteo).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getPendienteResolver: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    //console.log("params");
    //console.log(params);

    url = "resolucion-exclusion/?id=" + params;

    // if (params.filters) {
    //   if (params.filters.nombre) {
    //     url += `&descripcion=${params.filters.nombre}`;
    //   }
    //   if (!params.filters.habilitado) {
    //     url += `&habilitado=D`;
    //   } else {
    //     url += `&habilitado=H`;
    //   }
    // }
    return Base.get(url).then((res) => res.data);
  },
};

export default HacerLugarRE;
