/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import OficinaService from "services/Oficina.js";
import TipoSorteoService from "services/Tipo-Sorteo";
import SorteoService from "services/Sorteo";
import FueroExclusionService from "services/Fuero-Exclusion";
import OficinaJuezService from "services/Oficina-Juez";
import ExpedienteService from "services/Expediente";
import ExclusionService from "services/Exclusion";
import TipoExclusionService from "services/Tipo-Exclusion";
import { useEffect, useState } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";

import ButtonGroup from "@mui/material/ButtonGroup";

import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton, Tooltip } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import { SorteoProps } from "../interface/sorteo.tsx";
import { formatDate } from "services/utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Spacer from "../../../../../global-styles/spacer.js";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { spacing } from "@mui/system";
import "./crud.css";
import React from "react";
import { margin } from "@mui/system";
import errorsList from "errors/errors_list";
import { ExclusionProps } from "./../interface/exclusion.tsx";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import ExclusionConfirmCustomDialog from "../../Notifications/ExclusionConfirmCustomDialog";
import Message from "../../Notifications/Message";
import { StyledEngineProvider } from "@mui/material/styles";

import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import { size } from "lodash";
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const MAX_LENGTH_OBSERVACIONES = 400;
  const [expedientes, setExpedientes] = useState([]);
  const [jueces, setJueces] = useState([]);
  const [tiposExclusiones, setTiposExclusiones] = useState([]);
  const [dateFromExclusion, setDateFromExclusion] = useState(
    dayjs(crudData.data.fecha_desde) || null
  );
  const [selectedExpediente, setSelectedExpediente] = useState(null);
  const [dateToExclusion, setDateToExclusion] = useState(dayjs(crudData.data.fecha_hasta) || null);
  const [idSelectedJuez, setIdSelectedJuez] = useState(crudData.data.id_juez);
  const [selectedTipoExclusion, setSelectedTipoExclusion] = useState(null);
  const [observaciones, setObservaciones] =useState(
    crudData.data.observaciones || "."
  );
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] =
    useState(MAX_LENGTH_OBSERVACIONES);
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [exclusionCustomDialogData, setExclusionCustomDialogData] = useState({
    severity: "error",
    messageText: "Confirmar acción de exclusión",
    messageCustomBody: "",
    juezExcluir: "",
    tipoExclusion: "",
    caratulaExpediente: "",
    nroExpediente: "",
  });

  const [exclusionMain, setExclusionMain] = useState(
    {
    id_oficina: localStorage.getItem("id_oficina"),
    id_juez: "",
    id_tipo_exclusion: "",
    id_expediente: "",
    fecha_desde: "",
    fecha_hasta: "",
    observaciones: observaciones,
  });
  // const [showExclusionConfirmCustomDialog, setShowExclusionConfirmCustomDialog] = useState(false);
  const [hasConfirmed, setHasConfirmed] = useState(false);
  const [buttonOk, setButtonOk] = useState(false)

  useEffect(()=>{
    console.log(selectedExpediente)
    console.log(selectedTipoExclusion)

    if(idSelectedJuez!= null && selectedTipoExclusion != null){
      if( selectedTipoExclusion.tipo == "expediente" && selectedExpediente!=null){
        setButtonOk(true);
      }else if(selectedTipoExclusion.tipo!="expediente"){setButtonOk(true)}
    }else{setButtonOk(false)};

  }, [idSelectedJuez, selectedTipoExclusion, selectedExpediente]);
  



  useEffect(() => {
    verifyDateRange();
  }, [dateFromExclusion, dateToExclusion]);

  // useEffect(() => {
  //   console.log("useEffect");
  //   if (hasConfirmed) {
  //     if (crudAction == "create") {
  //       createExclusion();
  //     }
  //     if (crudAction == "update") {
  //       updateExclusion();
  //     }
  //     console.log("has confirmed");
  //     setHasConfirmed(false);
  //     setShowExclusionConfirmCustomDialog(false);
      
  //   }
  // }, [hasConfirmed]);

  useEffect(() => {
    getJuecesData();
    getExpedientesData({ rowsPerPage: 10000, first: 0 });
    getTiposExclusionesData();
  }, []);

  function findExclusionById(id) {
    return tiposExclusiones.find((exclusion) => exclusion.id_fuero === id);
  }
  const verifyDateRange = () => {
    // verify if only dateFrom is selected
    // if (dateFromExclusion !== null && dateToExclusion === null) {
    //   //remove error from list
    //   let newErrorsList = validationErrors.filter((error) => error.error !== "dateRangeNotValid");
    //   setValidationErrors(newErrorsList);
    // }

    if (
      dateFromExclusion !== null &&
      dateToExclusion !== null &&
      (dateFromExclusion > dateToExclusion || dateToExclusion < dateFromExclusion)
    ) {
      validationErrors.find((error) => error.error === "dateRangeNotValid")
        ? null
        : setValidationErrors([
            ...validationErrors,
            {
              error: "dateRangeNotValid",
              message: "La fecha de desde no puede ser mayor a la fecha hasta",
            },
          ]);
      console.log(validationErrors);
    } else if (dateFromExclusion == null && dateToExclusion == null) {
      validationErrors.find((error) => error.error === "dateRangeNotValid")
        ? null
        : setValidationErrors([
            ...validationErrors,
            {
              error: "dateRangeNotValid",
              message: "La fecha de desde no puede ser mayor a la fecha hasta",
            },
          ]);
      console.log(validationErrors);
    } else {
      //remove error from list
      let newErrorsList = validationErrors.filter((error) => error.error !== "dateRangeNotValid");
      setValidationErrors(newErrorsList);
      console.log(validationErrors);
    }
  };

  async function getExpedientesData(params?) {
    const response = await ExpedienteService.getExpediente(params || {});
    console.log('response data' + JSON.stringify(response.data))
    setExpedientes(response.data);
    setSelectedExpediente(response.data.find((ex) => ex.id == crudData.data.id_expediente) || null);
  }
  async function getTiposExclusionesData(params?) {
    const response = await FueroExclusionService.getFueroExclusion(params || {});
    console.log(response.data)
    console.log('tipo de exclusiones ...')
    setTiposExclusiones(response.data);
    setSelectedTipoExclusion(
      response.data.find((ex) => ex.id_tipo_exclusion == crudData.data.id_tipo_exclusion) || null
    );
  }

  async function getJuecesData(params?) {
    const response = await OficinaJuezService.getOficinaJuez(params || {});
    console.log(response);
    response.data.map((r) => {
      r.fullName = r.nombre + " " + r.apellido;
    });
    setJueces(response.data);
  }

  const dateFromChangeHandler = (date) => {
    if (selectedTipoExclusion.tipo == "temporal" || selectedTipoExclusion.tipo == "permanente") {
      console.log("dateFromExcludeChange");
      console.log(date);
      let date_simple = date._d;
      console.log("setting date pure");
      console.log(date_simple);
      setDateFromExclusion(date);
    }
  };
  const dateToChangeHandler = (date) => {
    if (selectedTipoExclusion.tipo == "temporal") {
      let date_simple = date._d;
      console.log("dateToExcludeChange");
      console.log(date);
      console.log("setting date pure");
      console.log(date_simple);
      setDateToExclusion(date);
    }
  };
  const handleObservacionesChange = (event) => {
    let value = event.target.value;
    if (value.length <= 255) {
      setObservaciones(value);
    }
  };

  async function createExclusion(createObjectFinal) {
    console.log("insertExclusion");    

    console.log(JSON.stringify(createObjectFinal))
    //reeplace null values in fecha_desde and fecha_hasta in main object with empty string if found
    if (createObjectFinal.fecha_desde == null) {
      createObjectFinal.fecha_desde = "";
    }
    if (createObjectFinal.fecha_hasta == null) {
      createObjectFinal.fecha_hasta = "";
    }

    const response = await ExclusionService.createExclusion(createObjectFinal);
    console.log("********");
    console.log(response);
    if (response.error == true) {
      setMessage({
        severity: "error",
        messageText: response.human_message,
      });
    } else if (response.error == false){
      console.log(response);
      setMessage({
        severity: "success",
        messageText: "Exclusión creada correctamente.",
      });
      //clear all inputs
      setExclusionMain({
        id_oficina: localStorage.getItem("id_oficina"),
        id_juez: "",
        id_tipo_exclusion: "",
        id_expediente: "",
        fecha_desde: "",
        fecha_hasta: "",
        observaciones: observaciones,
      });
      setIdSelectedJuez(null);
      setSelectedTipoExclusion(null);
      setSelectedExpediente(null);
      setDateFromExclusion(null);
      setDateToExclusion(null);
      setObservaciones(".");
      setDisplayDialogParent(false);
    }
    setShowMessage(true);
  }

  async function updateExclusion(createObjectUpdate) {
    console.log("updateExclusion");
    //reeplace null values in fecha_desde and fecha_hasta in main object with empty string if found
    if (createObjectUpdate.fecha_desde == null || createObjectUpdate.fecha_desde == "NaN/NaN/NaN") {
      createObjectUpdate.fecha_desde = "";
    }
    if (createObjectUpdate.fecha_hasta == null || createObjectUpdate.fecha_hasta == "NaN/NaN/NaN") {
      createObjectUpdate.fecha_hasta = "";
    }

    const response = await ExclusionService.updateExclusion(createObjectUpdate);
    console.log("********");
    console.log(response);
    if (response.error == true) {
      setMessage({
        severity: "error",
        messageText: response.human_message,
      });
    } else {
      console.log(response);
      setMessage({
        severity: "success",
        messageText: "Exclusión actualizada correctamente.",
      });
      //clear all inputs
      setExclusionMain({
        id_oficina: localStorage.getItem("id_oficina"),
        id_juez: "",
        id_tipo_exclusion: "",
        id_expediente: "",
        fecha_desde: "",
        fecha_hasta: "",
        observaciones: observaciones,
      });
      setIdSelectedJuez(null);
      setSelectedTipoExclusion(null);
      setSelectedExpediente(null);
      setDateFromExclusion(null);
      setDateToExclusion(null);
      setObservaciones(".");
      setDisplayDialogParent(false);
    }
    setShowMessage(true);
  }

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  const deleteExclusionHandler = async () => {
    console.log("deleteExclusionHandler");
    console.log();
    const response = await ExclusionService.deleteExclusion({
      action: isHabilitado ? "disable" : "enable",
      id: crudData.data.id,
    });
    console.log(response);
    if (response.error == true) {
      setMessage({
        severity: "error",
        messageText: response.human_message,
      });
    } else {
      setMessage({
        severity: "success",
        messageText: "Exclusión anulada correctamente.",
      });
      setDisplayDialogParent(false);
      updateTableRow(crudData.data.id);
    }
    setShowMessage(true);
  };

  function validateExclusion() {
  

    if(crudAction=="create"){    

      let createObjectFinal={
        ...exclusionMain,
        id_oficina: localStorage.getItem("id_oficina"),
        id_juez: idSelectedJuez ?? null,
        id_juez: dateToExclusion ? idSelectedJuez : null,
        id_tipo_exclusion: selectedTipoExclusion.id_tipo_exclusion
          ? selectedTipoExclusion.id_tipo_exclusion
          : null,
        id_expediente:
          selectedTipoExclusion.tipo == "expediente"
            ? selectedExpediente.id
              ? selectedExpediente.id
              : null
            : "",
        fecha_desde: dateFromExclusion ? formatDate(dateFromExclusion) : null,
        fecha_hasta: dateToExclusion ? formatDate(dateToExclusion) : null,
        observaciones: observaciones,
      // id: crudData.data.id?? "",
      }


      // setExclusionMain({
      //   ...exclusionMain,
      //   id_oficina: localStorage.getItem("id_oficina"),
      //   id_juez: idSelectedJuez ?? null,
      //   id_juez: dateToExclusion ? idSelectedJuez : null,
      //   id_tipo_exclusion: selectedTipoExclusion.id_tipo_exclusion
      //     ? selectedTipoExclusion.id_tipo_exclusion
      //     : null,
      //   id_expediente:
      //     selectedTipoExclusion.tipo == "expediente"
      //       ? selectedExpediente.id
      //         ? selectedExpediente.id
      //         : null
      //       : "",
      //   fecha_desde: dateFromExclusion ? formatDate(dateFromExclusion) : null,
      //   fecha_hasta: dateToExclusion ? formatDate(dateToExclusion) : null,
      //   observaciones: observaciones,
      // // id: crudData.data.id?? "",
      // });

      createExclusion(createObjectFinal); 
    
    }
    if (crudAction=="update") {
      let createObjectUpdate={
        ...exclusionMain,
        fecha_desde: dateFromExclusion ? formatDate(dateFromExclusion) : null,
        fecha_hasta: dateToExclusion ? formatDate(dateToExclusion) : null,
        id_juez: idSelectedJuez ?? null,
        id_tipo_exclusion: selectedTipoExclusion.id_tipo_exclusion
          ? selectedTipoExclusion.id_tipo_exclusion
          : null,
        id_expediente:
          selectedTipoExclusion.tipo == "expediente"
            ? selectedExpediente.id
              ? selectedExpediente.id
              : null
            : "",
        observaciones: observaciones,
        id: crudData.data.id?? "",
        id_oficina: localStorage.getItem("id_oficina"),
      }
      // setExclusionMain({
      //   ...exclusionMain,
      //   fecha_desde: dateFromExclusion ? formatDate(dateFromExclusion) : null,
      //   fecha_hasta: dateToExclusion ? formatDate(dateToExclusion) : null,
      //   id_juez: idSelectedJuez ?? null,
      //   id_tipo_exclusion: selectedTipoExclusion.id_tipo_exclusion
      //     ? selectedTipoExclusion.id_tipo_exclusion
      //     : null,
      //   id_expediente:
      //     selectedTipoExclusion.tipo == "expediente"
      //       ? selectedExpediente.id
      //         ? selectedExpediente.id
      //         : null
      //       : "",
      //   observaciones: observaciones,
      //   id: crudData.data.id?? "",
      //   id_oficina: localStorage.getItem("id_oficina"),
      // });

      updateExclusion(createObjectUpdate);
    }
   
   

  
    let errors = [];

    if (!idSelectedJuez) {
      errors.push({
        error: "id_juez",
        message: "Debe seleccionar un juez",
      });
    }
    if (!selectedTipoExclusion.id_tipo_exclusion) {
      errors.push({
        error: "id_tipo_exclusion",
        message: "Debe seleccionar un tipo de Exclusión",
      });
    }

    if (
      !selectedExpediente?.id &&
      selectedTipoExclusion.descripcion_tipo_exclusion == "RECUSACION"
    ) {
      errors.push({
        error: "id_expediente",
        message: "Debe seleccionar un expediente",
      });
    }
    if (
      !dateFromExclusion &&
      (selectedTipoExclusion.tipo == "permanente" || selectedTipoExclusion.tipo == "temporal")
    ) {
      errors.push({
        error: "fecha_desde",
        message: "Debe seleccionar una fecha desde",
      });
    }
    if (!dateToExclusion && selectedTipoExclusion.tipo == "temporal") {
      errors.push({
        error: "fecha_desde",
        message: "Debe seleccionar una fecha hasta",
      });
    }

    if (errors.length > 0) {
      setValidationErrors(errors);
      alert(JSON.stringify(errors));
    } else {
      //find name of selected juez just to show on custom dialog confirmation
      let juezName = "";
      jueces.forEach((juez) => {
        if (juez.id_juez == idSelectedJuez) {
          juezName = juez.nombre + " " + juez.apellido;
        }
      });
      //find other data just to show on custom dialog confirmation
      let expedienteName = "";
      expedientes.forEach((expediente) => {
        if (expediente.id == selectedExpediente?.id) {
          expedienteName = expediente.caratula;
        }
      });
      let tipoExclusionName = "";
      tipoExclusionName = selectedTipoExclusion.descripcion_tipo_exclusion;
      // tiposExclusiones.forEach((tipoExclusion_) => {
      //   if (tipoExclusion_.id_tipo_exclusion == selectedTipoExclusion.id_tipo_exclusion) {
      //     console.log("Theres a match in exclusion");
      //     console.log(tipoExclusion_);

      //   }
      // });

      setExclusionCustomDialogData(exclusionMain);
      setExclusionCustomDialogData({
        ...exclusionCustomDialogData,
        juezExcluir: juezName,
        tipoExclusion: tipoExclusionName,
        caratulaExpediente: expedienteName,
        nroExpediente: selectedExpediente?.numero,
      });
      console.log("SETTING DIALOG BOOL SHOW TRUE");
      // console.log(showExclusionConfirmCustomDialog);
      // setShowExclusionConfirmCustomDialog(true);
      console.log("SETTING DIALOG BOOL SHOW FALSE");
    }
  }

  return (
    // <StyledEngineProvider injectFirst>
    // <Dialog open onClose={handleClose}>
    //   <DialogTitle id="form-dialog-title">
    //     {crudAction === "create" ? "Nueva Exclusión" : "Editar Exclusión"}
    //     <IconButton
    //       aria-label="close"
    //       onClick={handleClose}
    //       sx={{
    //         position: "absolute",
    //         right: 8,
    //         top: 8,
    //         color: (theme) => theme.palette.grey[500],
    //       }}
    //     >
    //       <CloseIcon />
    //     </IconButton>
    //   </DialogTitle>

    //   <DialogContent dividers>
    <>
        {/* {showExclusionConfirmCustomDialog && (
          <ExclusionConfirmCustomDialog
            exclusionCustomDialogData={exclusionCustomDialogData}
            setHasConfirmed={setHasConfirmed}
            setShowExclusionConfirmCustomDialog={setShowExclusionConfirmCustomDialog}
          />
        )}  */}

        {/* {!triggerDeshabilitarConfirmation && ( */}
          <>
          
            {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <MDBox 
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  flexDirection: "row",
                  alignItems: "right",
                  marginBottom: "2%"
                  
                }}
                >
                <MDButton
                  size="small"
                  color={isHabilitado ? "error" : "success"}
                  variant="contained"
                  startIcon={<SaveIcon />}
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(true);
                  }}
                >
                  {isHabilitado ? "Deshabilitar" : "Habilitar"}
                </MDButton>
                </MDBox>
            )} */}

            <MDBox >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  
                }}
              >
                <div className="nombrecrud">
                  <Autocomplete
                    disableClearable
                    id="combo-box-jueces"
                    options={jueces}
                    value={
                      jueces.find((juez) => juez.id_juez === idSelectedJuez) ||
                      jueces.find((juez) => juez.id_juez === crudData.data.id_juez) ||
                      null
                    }
                    sx={{
                      width: 350,
                      marginBottom: "15px",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                    getOptionLabel={(option) => option.fullName}
                    renderInput={(params) => <TextField {...params} label="Juez" />}
                    onChange={(event, newValue) => {
                      console.log("setting juez:");
                      console.log(newValue.id_juez);
                      setIdSelectedJuez(newValue.id_juez);
                    }}
                  />
                </div>
                <Autocomplete
                  disableClearable
                  id="combo-box-exclusion"
                  options={tiposExclusiones}
                  value={selectedTipoExclusion}
                  sx={{ 
                    width: 350, 
                    marginBottom: "15px", 
                    marginLeft: "50px", 
                    marginRight: "50px" }}
                  getOptionLabel={(option) => option.descripcion_tipo_exclusion ?? ""}
                  renderInput={(params) => <TextField {...params} label="Tipo de Exclusión" />}
                  onChange={(event, newValue) => {
                    console.log("********VALUEEEEE*****");
                    console.log(newValue);

                    setSelectedTipoExclusion(newValue);
                  }}
                />
                {selectedTipoExclusion?.tipo == "expediente" && (
                  <Autocomplete
                    disableClearable
                    id="combo-box-expediente"
                    options={expedientes}
                    // value={
                    //   expedientes.find((expediente) => expediente.id === selectedExpediente?.id) || null
                    // }
                    value={selectedExpediente}
                    sx={{
                      width: 350,
                      marginBottom: "15px",
                      marginLeft: "50px",
                      marginRight: "50px",
                    }}
                    getOptionLabel={(option) => option.caratula}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.caratula}
                        </li>
                      );
                    }}
                    renderInput={(params) => <TextField {...params} label="Expedientes" />}
                    onChange={(event, newValue) => {
                      console.log("setting expediente:");
                      console.log(newValue);
                      setSelectedExpediente(newValue || null);
                    }}
                  />
                )}

                {selectedTipoExclusion?.tipo == "permanente" && (
                  <div>
                    {/* <Spacer size={5} /> */}
                    {/* <div>
                      <span>Fecha Desde:</span>
                    </div> */}

                    <DatePicker 
                      label="Fecha desde:"
                      value={dateFromExclusion} 
                      onChange={dateFromChangeHandler}
                      sx={{
                        width: 350,
                        marginBottom: "15px",
                        marginLeft: "50px",
                        marginRight: "50px",
                      }} />
                  </div>
                )}
                {selectedTipoExclusion?.tipo == "temporal" && (
                  <>
                    {/* <Spacer size={15} /> */}
                    {/* <div> */}
                      {/* <div>
                        <span>Fecha Desde:</span>
                      </div> */}

                      <DatePicker 
                        label="Fecha desde:"
                        value={dateFromExclusion} 
                        onChange={dateFromChangeHandler} 
                        sx={{
                          width: 350,
                          marginBottom: "15px",
                          marginLeft: "50px",
                          marginRight: "50px",
                        }} />
                      {/* <Spacer size={10} /> */}

                      {/* <div>
                        <span>Fecha Hasta:</span>
                      </div> */}

                      <DatePicker
                        label="Fecha hasta:"
                        value={dateToExclusion}
                        onChange={(newValue) => dateToChangeHandler(newValue)}
                        sx={{
                          width: 350,
                          marginBottom: "15px",
                          marginLeft: "50px",
                          marginRight: "50px",
                        }} />

                      <div>
                        <span style={{ color: "red", fontSize: "0.85rem" }}>
                          {validationErrors.find((e) => e.error == "dateRangeNotValid")?.message}
                        </span>
                      </div>
                    {/* </div> */}
                  </>
                )}
                 <Spacer size={15} />
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ 
                      width: 350, 
                      marginBottom: "15px", 
                      marginLeft: "50px", 
                      marginRight: "50px" }}
                    multiline
                    rows={5}
                    value={observaciones}
                    onChange={handleObservacionesChange}
                  />
                </div>
{/* 

                <div>
                  <Spacer size={15} />

                  <Spacer size={32} />
                </div>

                <Spacer size={72} /> */}
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{  marginRight: "130px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>                 
                  <Tooltip title={ !buttonOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                        disabled={!buttonOk || crudAction === "readonly"} 
                        onClick={validateExclusion} 
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </MDButton>
                    </span>
                  </Tooltip>
                </div>
               
              </MDBox>
            </MDBox>
          </>
        {/* )} */}

        {/* {triggerDeshabilitarConfirmation && (
          <div className="containercrud">
            <div className="nombrecrud">
              <Typography
                variant="h8"
                component="div"
                gutterBottom
                color={isHabilitado ? "red" : "green"}
              >
                {isHabilitado
                  ? "¿Está seguro que desea deshabilitar la Exclusión?"
                  : "¿Está seguro que desea habilitar la Exclusión?"}
              </Typography>
            </div>
            <div className="fueroName">
              <Typography variant="h8" component="div" gutterBottom>
                <div>

                  {/* {"id":"04d0dd05-9282-4f62-8241-1c63cdca68f4",
                  "id_fuero":"bc39cf45-d29d-4f18-a15d-eba785ef2e3f",
                  "descripcion_fuero":"SUPREMA CORTE DE JUSTICIA",
                  "habilitado_fuero":true,"id_tipo_exclusion":
                  "cf662be1-12c5-4227-97fd-a6bf96981a75",
                  "descripcion_tipo_exclusion":"DEFUNCION",
                  "tipo":"permanente","habilitado_tipo_exclusion":false,
                  "observaciones":"",
                  "username":"carolinamagni@jus.mendoza.gov.ar",
                  "fecha_creacion":"04-01-2024 12:28","fecha_actualizacion":"29-05-2024 10:16",
                  "habilitado":false,"fullName":"undefined undefined"}, */}
                  {/* {tiposExclusiones?.find(
                    (tipo) => tipo.id_tipo_exclusion === selectedTipoExclusion.id_tipo_exclusion
                  ).descripcion_tipo_exclusion ||
                    tiposExclusiones?.find(
                      (tipo) => tipo.id_tipo_exclusion === crudData.data.id_tipo_exclusion
                    ).descripcion_tipo_exclusion ||
                    null}
                </div>
                {jueces.find((juez) => juez.id_juez === idSelectedJuez).fullName ||
                  jueces.find((juez) => juez.id_juez === crudData.data.id_juez).fullName ||
                  null}
              </Typography>
            </div>
            <div className="botonesaccionescrud">
              <MDButton
                sx={{ marginRight: "5px" }}
                size="small"
                color="secondary"
                onClick={() => {
                  setTriggerDeshabilitarConfirmation(false);
                }}
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Cancelar
              </MDButton>
              <MDButton
                onClick={deleteExclusionHandler}
                size="small"
                color="primary"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Confirmar
              </MDButton>
            </div>
          </div> */}
        {/* )} */}
      {/* </DialogContent>
    </Dialog>
    </StyledEngineProvider> */}
  </>
  );
}

export default Crud;
