import Base from "./axiosBase";

const OficinaService = {
  createOficina: async (oficina) => Base.post("/oficina/", oficina),
  deleteOficina: async (oficina) => {
    return Base.delete(`oficina/?id=${oficina.id}&action=${oficina.action}`).then(
      (res) => {
        console.log("from oficina delete service")
        console.log(res.data);
        return (res.data);
       
      }
    );
  },
  // updateOficina: async (oficina) => Base.put(`oficina/`, oficina).then((res) => res.data),
  updateOficina: async (oficina) => 
    Base.put(`oficina/?id=${oficina.id}`, oficina).then((res) => res.data),

  // eslint-disable-next-line arrow-body-style
  getOficina: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
  
  //console.log(params.filters);
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.id_fuero) {
      url += `&id_fuero=${params.id_fuero}`;
    }
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }




 

    if (url !== "") {
      url = `oficina/?${url}`;
    } else {
      url = "oficina/";
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default OficinaService;
