/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import React, { useState } from "react";
// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import Card from "@mui/material/Card";
import PhoneIcon from "@mui/icons-material/Phone";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PersonPinIcon from "@mui/icons-material/PersonPin";
// Administracion page components
import PaymentMethod from "layouts/administracion/components/PaymentMethod";
import Invoices from "layouts/administracion/components/Invoices";
import BillingInformation from "layouts/administracion/components/BillingInformation";
// import Transactions from "layouts/administracion/components/Transactions";
import Fueros from "layouts/administracion/components/Fueros/index.tsx";
import Jueces from "layouts/administracion/components/Jueces/index.tsx";
import Oficinas from "layouts/administracion/components/Oficinas/index.tsx";
import Materias from "layouts/administracion/components/Materias/index.tsx";
import Expedientes from "layouts/administracion/components/Expedientes/index.tsx";
import Exclusiones from "layouts/administracion/components/Exclusiones/index.tsx";
import ExclusionesMain from "layouts/administracion/components/Exclusiones-Main/index.tsx";
import Sorteos from "layouts/administracion/components/Tipo-Sorteo/index.tsx"
import Origenes from "layouts/administracion/components/Origenes/index.tsx"
import OficinaMateria from "layouts/administracion/components/Oficina-Materia/index.tsx";  
import OficinaJuez from "layouts/administracion/components/Oficina-Juez/index.tsx";
import FueroExclusion from "layouts/administracion/components/Fuero-Exclusion/index.tsx";
import FueroSorteo from "layouts/administracion/components/Fuero-Sorteo/index.tsx";

import Tabs from "@mui/material/Tabs"; 
import Tab from "@mui/material/Tab";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { Collapse } from "@mui/material";

function Administracion() {
  const [value, setValue] = React.useState(0);
  const [selectedTabName, setSelectedTabName] = useState("Fueros");
  const selectedMenuColor = "#49a3f1";
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    //console.log(event);
    //console.log(event.target.innerText);
    setSelectedTabName(event.target.innerText);
    setValue(newValue);
  };
  const [menuMini, setMenuMini] = useState(false)
  return (
    <DashboardLayout>
      <DashboardNavbar/>

    
      <MDBox sx={{display: 'flex', justifyContent: 'center'}}>
      <Grid container spacing={3} sx={{sm: {maxWidth: '100%', minWidth: '1250px', paddingTop:'74px'}}}>

      {/* <Collapse in={menuMini} orientation="horizontal"> */}
      <Card>
          <Grid >
            <nav aria-label="main mailbox folders">
              <Divider />
              <List>
                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Fueros" ? selectedMenuColor:""}}>
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fueros" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Oficinas" ? selectedMenuColor:""}}>
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Oficinas" />
                  </ListItemButton>
                </ListItem>


                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Jueces" ? selectedMenuColor:""}}>
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Jueces" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Tipos de Sorteos" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tipos de Sorteos" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Tipos de Exclusiones" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Tipos de Exclusiones" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Materias" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Materias" />
                  </ListItemButton>
                </ListItem>


                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Orígenes" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Orígenes" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Materias de Oficina" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Materias de Oficina" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Jueces de Oficina" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Jueces de Oficina" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Fuero-Tipo de Exclusión" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fuero-Tipo de Exclusión" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Fuero-Tipo de Sorteo" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Fuero-Tipo de Sorteo" />
                  </ListItemButton>
                </ListItem>


                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Exclusiones" ? selectedMenuColor:""}}> 
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Exclusiones" />
                  </ListItemButton>
                </ListItem>

                <ListItem disablePadding sx={{backgroundColor : selectedTabName==="Expedientes" ? selectedMenuColor:""}}>
                  <ListItemButton onClick={handleChange}>
                    <ListItemIcon>
                      <DraftsIcon />
                    </ListItemIcon>
                    <ListItemText primary="Expedientes" />
                  </ListItemButton>
                </ListItem>
              </List>
            </nav>
            <Divider />
          </Grid>
          </Card>
        {/* </Collapse> */}

          <Grid item xs={12} md={9} sx={{ justifySelf: "center" }}>
            {selectedTabName == "Fueros" && <Fueros />}
            {selectedTabName == "Jueces" && <Jueces />}
            {selectedTabName == "Oficinas" && <Oficinas />}
            {selectedTabName == "Expedientes" && <Expedientes />}
            {selectedTabName == "Materias" && <Materias />}
            {selectedTabName == "Tipos de Exclusiones" && <Exclusiones />}
            {selectedTabName == "Tipos de Sorteos" && <Sorteos />}
            {selectedTabName == "Orígenes" && <Origenes />}
            {selectedTabName == "Materias de Oficina" && <OficinaMateria />}
            {selectedTabName == "Jueces de Oficina" && <OficinaJuez />}
            {selectedTabName == "Fuero-Tipo de Exclusión" && <FueroExclusion />}
            {selectedTabName == "Fuero-Tipo de Sorteo" && <FueroSorteo />}
            {selectedTabName == "Exclusiones" && <ExclusionesMain />}
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Administracion;
