/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Component imports
import Crud from "layouts/administracion/components/Expedientes/dialogs/Crud";
import MUIDataTable from "mui-datatables";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import "./index.css";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
  Collapse,
} from "@mui/material";
import { Box } from "@mui/system";

// ##############ICONS IMPORTS################
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
// ##############ICONS IMPORTS################

import ExpendienteService from "services/Expediente";
import Filters from "./filters/filters.tsx";
import Message from "../Notifications/Message";
import "global-styles/forms.css";
import { LocalFilters } from "./LocalFilters";
import { first } from "lodash";
import { CrudLayout } from "layouts/administracion/CrudLayout";
import { AccionesLayout } from "layouts/administracion/AccionesLayout";
import ExpedienteService from "services/Expediente";
import { AlertDisableLayout } from "layouts/administracion/AlertDisableLayout";
let firstRun=true;
function Expedientes() {
  const [tableOptions, setTableOptions] = useState({});
  // const [tableData, setTableData] = useState([]);
  const [filtersState, setFiltersState] = useState<LocalFilters>({
    numero: "",
    caratula: "",
    habilitado: true,
    fuero: null,
    materia: null,
  });
  const [displayDialog, setDisplayDialog] = useState(false);
  // const [open, setOpen] = React.useState(true)

  const tOptions = {
    storageKey: "table-Expedientes",
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    count: 7,
    page: 0,
    isRowExpandable: (dataIndex, expandedRows) => {
      return true;
    },
    renderExpandableRow: (rowData, rowMeta) => {
      const colSpan = rowData.length + 1;
      return (
        <TableRow>
          <TableCell colSpan={colSpan} style={{ backgroundColor: "#dce8f5" }}>
            {/* <Collapse in={open}> */}
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              <u>Carátula</u>{" "}
            </div>
            <div style={{ fontWeight: "400", textAlign: "center" }}>{rowData[1]}</div>

            {rowData[3].length > 1 ? (
              <>
                <div style={{ textAlign: "center", marginBottom: "10px" }}>
                  <u>Observaciones</u>{" "}
                </div>
                <div style={{ fontWeight: "400", textAlign: "center" }}>{rowData[3]}</div>
              </>
            ) : (
              ""
            )}
            {/* </Collapse> */}
          </TableCell>
        </TableRow>
      );
    },

    expandableRows: true,
    expandableRowsOnClick: true,
    onTableChange: (action, table) => {

     
      if (action === "changePage") {
      //  setTableOptions(tOptions);
        // eslint-disable-next-line no-inner-declarations
        async function getData(params) {
          const response = await ExpendienteService.getExpediente(params);
          console.log("calling from on table change")

          setTableOptions((prev) => ({
            ...prev,
            count: response.count,
            page: table.page,
            data: response.data || [],
           
          }));
        }
        getData({
          page: table.page * table.rowsPerPage || 0,
          rowsPerPage: table.rowsPerPage || 10,
          sortOrder: "DESC",
          filters: filtersState,

        });
      }
    },
    data: [],
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };

  const [displayFilters, setDisplayFilters] = useState(false);
  // const [displayDialog, setDisplayDialog] = useState(false);
  const [crudAction, setCrudAction] = useState("create");
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [crudData, setCrudData] = useState({});
  const [fullName, setFullName] = useState('')
  const [displayAlert, setDisplayAlert] = useState(false);

  const columns = [
    {
      label: "Numero",
      name: "numero",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            {`${tableOptions.data[dataIndex].numero}`}{" "}
            <div className="deshabilitadoJuezFont">
              {tableOptions.data[dataIndex].habilitado ? "" : "Deshabilitado"}
            </div>
          </span>
        ),
      },
    },
    { label: "Carátula", name: "caratula" },
    { label: "ID Usuario", name: "id_usuario", options: { display: "false" } },
    { label: "Observaciones", name: "observaciones", options: { display: "false" } },
    { label: "Fecha de Actualizacion", name: "fecha_actualizacion", options: { display: "false" } },
    { label: "Fecha de Creacion", name: "fecha_creacion", options: { display: "false" } },
    { label: "Habilitado", name: "habilitado", options: { display: "false" } },
    {
      label: "Acciones",
      name: "acciones",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        viewColumns: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          // <Box>
          //   {tableOptions.data[dataIndex].habilitado ? 
          //       <Tooltip title="Editar">
          //         <IconButton
          //           aria-label="Editar"
          //           onClick={() => {
          //             //console.log("WILL SET VALUE");
          //             //console.log(tableOptions.data[dataIndex]);
          //             setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
          //             setCrudAction("update");
          //             setDisplayDialog(true);
          //           }}
          //         >
          //           <Icon>edit</Icon>
          //         </IconButton>
          //       </Tooltip>
          //     : <div></div>
          //   }
          // </Box>

          <AccionesLayout
                  setCrudData = {setCrudData}
                  crudData = {crudData}
                  setCrudAction={setCrudAction}
                  setDisplayDialogParent={setDisplayDialog}
                  dataIndex = {dataIndex}
                  tableOptions = {tableOptions}
                  setFullName={setFullName}
                  datoAlert={'caratula'}
                  setDisplayAlertParent={setDisplayAlert}
                  
           />
        ),
      },
    },
  ];
  function initTable() {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await ExpendienteService.getExpediente(params);
      console.log("calling from init table")
      //console.log("setting table data");
      //console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filtersState,
    });
    firstRun=false;
  }
  // useEffect(() => {
  //   initTable();
  // }, []);

  useEffect(() => {
    initTable();
  }, [displayDialog]);

  useEffect(() => {
    //console.log("child filters changed");
    //console.log(filtersState);
    if (
      filtersState.numero == "" &&
      filtersState.habilitado == true &&
      filtersState.caratula == "" &&
      filtersState.fuero == null &&
      !firstRun
    ) {
      //if clean filters is applied from child component
     
      applyFiltersHandler();
    }
  }, [filtersState]);

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................
  const buscarHandler = () => {
    setDisplayFilters((currentValue) => !currentValue);
  };
  const updateTableRow = (data) => {
    //console.log(data);
    if (Object.keys(data).length === 0) {
      initTable(); //if no data, refresh table (create from crud dialog)
    }
    setTableOptions((prev) => ({
      ...prev,
      data: prev.data.map((row) => {
        if (row.id === data.id) {
          return data;
        }
        return row;
      }),
    }));
  };

  const applyFiltersHandler = () => {
    //childFilters apply button clicked.
    
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await ExpendienteService.getExpediente(params);
      console.log("calling from apply filters")
      //console.log("setting table data");
      //console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filtersState,
    });
  };

  // const applyFiltersHandler = () => {
  //   //childFilters apply button clicked.

  //   setTableOptions(tOptions);
  //   async function getData(params) {
  //     const response = await SorteoQuery.getSorteo(params);
  //     //console.log("setting table data");
  //     //console.log(response);

  //     if (response.error) {
  //       if (
  //         response.message_code == "user-cu-not-authoridez2" ||
  //         response.message_code == "err-usher2"
  //       ) {AccionesLayout
  //         setInsufficientPermissions(true);
  //       }
  //       return;
  //     }

  //     setTableOptions((prev) => ({
  //       ...prev,
  //       count: response.count,
  //       data: response.data || [],
  //     }));
  //   }
  //   getData({
  //     page: tableOptions.page || 0,
  //     rowsPerPage: tableOptions.rowsPerPage || 10,
  //     sortOrder: "DESC",
  //     filters: filtersState,
  //   });
  // };


  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <Box sx={{ m: 1, p: 1 }}>
          <Grid container spacing={1} textAlign="right">
            <Grid item xs={12} md={6} lg={12}>
              <Button
                color="warning"
                variant="contained"
                startIcon={<AddIcon />}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setCrudData({ data: {}, index: {} }); // clear edit data
                  setCrudAction("create");
                  setDisplayDialog(true);
                }}
              >
                Nuevo
              </Button>
              <Button
                className={displayFilters ? "selectedButton" : "unselectedButton"}
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={buscarHandler}
              >
                Buscar
              </Button>
              {displayFilters && (
                <Filters
                  filtersState={filtersState}
                  setFiltersState={setFiltersState}
                  applyFiltersHandler={applyFiltersHandler}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <MUIDataTable
          title="Expedientes"
          data={tableOptions.data}
          columns={columns}
          options={tableOptions}
        />
        {/* <Fuero
        color="dark"
        icon="priority_high"
        name="Webflow"
        description="26 March 2020, at 05:00 AM"
        value="Pending"
      /> */}
      </Card>
      {(displayAlert || displayDialog) &&(
        <CrudLayout setDisplayDialogParent={setDisplayDialog} setDisplayAlertParent={setDisplayAlert} 
        title={ crudAction === "create" ? "Nuevo Expediente"
          : crudAction === "readonly" ? "Información de Expediente"
          : crudAction === "delete" ? "Deshabilitar Expediente"
          : "Editar Oficina"}        >
                   {displayDialog && (
            <Crud
              setDisplayDialogParent={setDisplayDialog}
              setMessage={setMessage}
              setShowMessage={setShowMessage}
              crudAction={crudAction}
              crudData={crudData}
              updateTableRow={updateTableRow}
            />)}
            
          {displayAlert && (
            <AlertDisableLayout
              setDisplayDialogParent={setDisplayDialog}
              setDisplayAlertParent={setDisplayAlert}
              setMessage={setMessage}
              setShowMessage={setShowMessage}          
              crudAction={crudAction}
              crudData={crudData}
              updateTableRow={updateTableRow}
              tipo={"Expediente"}
              datos={fullName}
              service ={ExpedienteService.deleteExpediente}
            />)}  

        </CrudLayout>
      )}
      {showMessage && <Message message={message} setShowMessage={setShowMessage} />}
    </>
  );
}

export default Expedientes;
