
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import { AlertDisableLayout } from '../administracion/AlertDisableLayout';

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Component imports
import Crud from "layouts/administracion/components/Jueces/dialogs/Crud";
import MUIDataTable from "mui-datatables";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
  colors,
} from "@mui/material";
import { Box } from "@mui/system";

// ##############ICONS IMPORTS################
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// ##############ICONS IMPORTS################



export function AccionesLayout( {
    setCrudData,
    setCrudAction,
    setDisplayAlertParent,
    setDisplayDialogParent,
    datoAlert,
    tableOptions,
    dataIndex,
    setFullName,
})
{
  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
    setDisplayAlertParent(false);
  };

  const [buttonHabilitar, setButtonHabilitar] = useState(false);
  const [buttonDeshabilitar, setButtonDeshabilitar] = useState(false);
  const [buttonEditar, setButtonEditar] = useState(false);

  useEffect(() => {

    console.log('llama al use efect acciones..........' + (tableOptions.storageKey ))
    disableDeshabilitar();
    disableEditar();
    disableHabilitar();
  
    // return () => {
    //   second
    // }
  }, [tableOptions.storageKey])

  let count=0;

const disableHabilitar = () => {
  console.log('llama al use efect acciones..........' + (tableOptions.storageKey !== 'table-Materias' && tableOptions.data[dataIndex].habilitado !== false))
  if(tableOptions.storageKey === 'table-Materias' && tableOptions.data[dataIndex].habilitado !== false){
    setButtonHabilitar(false);
  }else if(tableOptions.storageKey !== 'table-Materias' && tableOptions.data[dataIndex].habilitado ||  (!tableOptions.data[dataIndex].habilitado && (tableOptions.data[dataIndex].habilitado_juez === false && tableOptions.data[dataIndex].habilitado_juez !== undefined)) ){
    setButtonHabilitar(false)
  }else {   setButtonHabilitar(true); } 
    
}
const disableDeshabilitar = () => {

  if((!tableOptions.data[dataIndex].habilitado) || (tableOptions.data[dataIndex].habilitado && (tableOptions.data[dataIndex].id_sorteo_juez_exclusion !== undefined && tableOptions.data[dataIndex].id_sorteo_juez_exclusion !== null)  || 
    tableOptions.data[dataIndex].disable === false)){
    setButtonDeshabilitar(false);
  }else{ setButtonDeshabilitar(true);}
}
const disableEditar = () => {
  if((!tableOptions.data[dataIndex].habilitado) || ( 
  (tableOptions.data[dataIndex].id_sorteo_juez_exclusion !== undefined && tableOptions.data[dataIndex].id_sorteo_juez_exclusion !== null)  || 
  tableOptions.data[dataIndex].editable === false)){
  setButtonEditar(false);
  }else{
    setButtonEditar(true);
  }
}




let dato = "";
if(datoAlert === 'FullName'){
  const nombre = eval(`tableOptions.data[${dataIndex}].nombre`);
  const apellido = eval(`tableOptions.data[${dataIndex}].apellido`);
  dato = nombre + ' ' +apellido;
}else if(datoAlert === 'FueroExclusion'){
  const nombre = eval(`tableOptions.data[${dataIndex}].descripcion_fuero`);
  const apellido = eval(`tableOptions.data[${dataIndex}].descripcion_tipo_exclusion`);
  dato = nombre + ' - ' +apellido;
}else if(datoAlert === 'FueroSorteo'){
const nombre = eval(`tableOptions.data[${dataIndex}].descripcion_fuero`);
const apellido = eval(`tableOptions.data[${dataIndex}].descripcion_tipo_sorteo`);
dato = nombre + ' - ' +apellido;
}else if(datoAlert === 'Exclusion'){
const exclusion = eval(`tableOptions.data[${dataIndex}].descripcion_tipo_exclusion`);
const nombre = eval(`tableOptions.data[${dataIndex}].nombre_juez`);
  const apellido = eval(`tableOptions.data[${dataIndex}].apellido_juez`);
  dato = exclusion +' - ' +  nombre + ' ' +apellido ;
}else{ dato  = eval(`tableOptions.data[${dataIndex}].`+datoAlert);}

return(
  
        <Box
        sx={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "row",
        alignItems: "left",
        width: 50,
            marginBottom: "15px",
            marginLeft: "10px",
            marginRight: "50px",                                
        }}
    >
            <Tooltip title="Editar">
              <span>
              <IconButton
                aria-label="Editar"
                // sx={{ color: '#FF7F50' }}
                color = {'primary'}
                disabled = {!buttonEditar}
                onClick={() => {                  
                  setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
                  setCrudAction("update");
                  setDisplayDialogParent(true); 
                  setDisplayAlertParent(false)  ;
                }}
              >
                <Icon>edit</Icon>
              </IconButton>
              </span>
            </Tooltip>
            {datoAlert!=="caratula" && ( //Si es expediente no se puede habilitar una vez deshabilitado, directamente elimino el ícono
            <Tooltip title="Habilitar">
              <span>
              <IconButton
                aria-label="Habilitar"
                color = {'success'}
                disabled = {!buttonHabilitar}
                onClick={() => {
                  setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
                  setCrudAction("delete");
                  setDisplayAlertParent(true)  
                  setDisplayDialogParent(false); 
                  setFullName(dato)
                  
                }}
              >
                <Icon
                >check_circle</Icon>
              </IconButton>
              </span>
            </Tooltip>
            )}
            <Tooltip title="Deshabilitar">
            <span>
              <IconButton
                aria-label="Deshabilitar"
                color={'error'}
                disabled= {!buttonDeshabilitar}
                onClick={() => { 
                  setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
                  setFullName(dato)
                  setCrudAction("delete");
                  setDisplayAlertParent(true)                  
                  setDisplayDialogParent(false);      
                          
                }}
              >
                <Icon >remove_circle</Icon>
              </IconButton>
              </span>
            </Tooltip>
          </Box>
          )
            }