export function formatOBJSRE(obj: any,selectedTipoSorteoHeader:any) {

    let input = obj

    //console.log("input", input)
    //need to filter out the non "checked" items inside jueces_elegibles_list list
  //let purged_jueces_elegibles_list=input.jueces_elegibles_list.filter(i=>i.checked)
  //workaround for now
  let purged_jueces_elegibles_list=input.juecesPurgedList.filter(i=>i.elegible)
  //console.log("purged list is")
  //console.log(purged_jueces_elegibles_list)

  let output={
        "id_sorteo_origen": input.sorteo_origen.id_sorteo,
        "id_sorteo_origen_de_origen": input.sorteo_origen.id_sorteo_origen,
        "jueces_sorteados_origen_list": input.jueces_sorteados_list.map((juez)=>{
            return {
                "id_juez": juez.id_juez
            }
        }
        ),
        "jueces_elegibles_list": purged_jueces_elegibles_list.map((juez)=>{
            return {
                "id_juez": juez.id_juez,
                "id_tipo_exclusion": juez.tipoExclusionSelected?.id_tipo_exclusion??"",
                "notas": juez.observaciones
            }
        }
        ),
        "id_expediente": input.sorteo_origen.id_expediente,
        "id_tipo_sorteo": selectedTipoSorteoHeader.id_tipo_sorteo,
        "id_oficina": input.sorteo_origen.id_oficina,
        "id_usuario": input.sorteo_origen.id_usuario


  }
    return output;

}

