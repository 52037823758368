export function formatOBJHacerLugarSE(obj: any) {

    let input = obj

    //console.log("input", input)
    //need to filter out the non "checked" items inside jueces_elegibles_list list
  //let purged_jueces_elegibles_list=input.jueces_elegibles_list.filter(i=>i.checked)
  //workaround for now
//   let purged_jueces_elegibles_list=input.juecesPurgedList.filter(i=>i.elegible)
//   //console.log("purged list is")
//   //console.log(purged_jueces_elegibles_list)
  let jueces_resolucion_list=[]
  let output={
        "id_sorteo_origen": input.sorteo.id_sorteo_origen,
        "id_sorteo": input.sorteo.id_sorteo,
        
}


input.sorteo_juez_exclusion_list.find((j)=>{
  if('haceLugarState' in j)
  {
    jueces_resolucion_list.push({
      "id_juez": j.id_juez,
      "resolucion": j.haceLugarState?"shl":"nhl",
      "notas": j.observaciones,
  })
  }
})

output["jueces_resolucion_list"]= jueces_resolucion_list
//console.log("returning")
//console.log(output)
    return output;

}


