import Base from "./axiosBase";

const TipoSorteoService = {
  createSorteo: async (sorteo) => Base.post("/tipo-sorteo/", sorteo),
  deleteSorteo: async (sorteo) =>
    Base.delete(`tipo-sorteo/?id=${sorteo.id}&action=${sorteo.action}`).then((res) => res.data),
  // updateSorteo: async (sorteo) => Base.put(`tipo-sorteo/`, sorteo).then((res) => res.data),
  updateSorteo: async (sorteo) => 
    Base.put(`tipo-sorteo/?id=${sorteo.id}`, sorteo).then((res) => res.data),

  // eslint-disable-next-line arrow-body-style
  getSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    //console.log("getSorteo params")
    console.log(params.filters)
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (params.filters) {
      if (params.filters.tipoAgrupadorSorteo) {
        url += `&tipo_grupo=${params.filters.tipoAgrupadorSorteo}`;
      }

      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero}`;
      }

      if (params.filters.habilitado) {
        url += `&habilitado=H`;
      } else {
        url += `&habilitado=D`;
      }
    }

    if (url !== "") {
      url = `tipo-sorteo/?${url}`;
    } else {
      url = "tipo-sorteo/";
    }
    return Base.get(url).then((res) => res.data);
  },
  getSorteoPorFuero: async (params) => {
    // eslint-disable-next-line prettier/prettier
    //console.log("getSorteo params")
    //console.log(params)
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (params.filters) {
      if (params.filters.tiposSorteo) {
        url += `&id_tipo_sorteo=${params.filters.tipoSorteo.id}`;
      }

      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero}`;
      }
      if (params.filters.tipoAgrupadorSorteo) {
        url += `&tipo_grupo=${params.filters.tipoAgrupadorSorteo}`;
      }
      if (params.filters.habilitado) {
        url += `&habilitado=H`;
      } else {
        url += `&habilitado=D`;
      }
    }

    if (url !== "") {
      url = `fuero-tipo-sorteo/?${url}`;
    } else {
      url = "fuero-tipo-sorteo/";
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default TipoSorteoService;
