import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect, useRef } from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import TipoSorteoService from "services/Tipo-Sorteo";
import TipoExclusionService from "services/Tipo-Exclusion";
import HacerLugarRE from "services/HacerLugarRE.js";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { formatOBJHacerLugarSE } from "./BL.tsx";
import { validateFormData } from "./validateData.tsx";
import "./styles.css";
import isEqual from "lodash/isEqual";
import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Skeleton from "@mui/material/Skeleton";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import { usePreviousMonthDisabled } from "@mui/x-date-pickers/internals";
import { PlumbingRounded, Preview } from "@mui/icons-material";
import SREService from "services/SRE";
import { stringToColor, stringAvatar } from "#services/utils.js";
import FinalConfirmDialogHacerExclusion from "./FinalConfirmDialogHacerExclusion.tsx";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

function HacerLugarExclusionCustomDialog({
  selectedRowDetailDialogData,
  setShowHacerLugarDialog,
  setMessage,
  setShowMessage,
  setFiltersState,
  applyFiltersHandler,
  filtersState,
}) {
  const [idSorteo, setIdSorteo] = useState(null);
  const [loadingJuecesElegibles, setLoadingJuecesElegibles] = useState(true);
  const [habilitadoExclusion, setHabilitadoExclusion] = useState(false);
  const [sorteos, setSorteos] = useState([]);
  const [tiposExclusion, setTiposExclusion] = useState([]);
  const [SREJuezElegible, setSREJuezElegible] = useState([]);
  const [selectedTipoSorteoHeader, setSelectedTipoSorteoHeader] = useState(null);
  const [DummyStateForUpdateRender, setDummyStateForUpdateRender] = useState([]);
  const [checkedJuecesSRE, setCheckedJuecesSRE] = React.useState([]);
  const [errorsRobot, setErrorsRobot] = React.useState([]);
  const [errorsRobotInvissible, setErrorsRobotInvissible] = React.useState([]);
  const [errrosRobotInvisible, setErrorsRobotInvisible] = React.useState([]);
  const [resolverButtonDisabled, setResolverButtonDisabled] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [displayHacerLugarExclusionDialog, setDisplayHacerLugarExclusionDialog] =
    React.useState(false);
  async function setSorteosData(params) {
    const response = await TipoSorteoService.getSorteo(params);
    let sorteos_ = response.data.filter((v) => v.tipo == "resolucion-exclusion");

    setSorteos(sorteos_);
  }
  async function setTiposExclusionData(params) {
    const response = await TipoExclusionService.getTipoExclusion(params);
    //filter only resolucion exclusion from response.data array
    let tiposExclusion_ = response.data.filter((v) => v.tipo == "expediente");

    setTiposExclusion(tiposExclusion_);
  }
  async function getPendienteResolver(params) {
    //console.log("resolving new service params");
    //console.log(params);
    const response = await HacerLugarRE.getPendienteResolver(params.idSorteo);
    //console.log("response");
    //console.log(response);

    setLoadingJuecesElegibles(false);
    if (response.error) {
      setMessage({
        severity: "error",
        messageText: response.human_message,
      });
      setShowMessage(true);
      return;
    }

    setHabilitadoExclusion(true);
    response.sorteo_juez_exclusion_list?.map((value) => {
      (value.haceLugarState = false), (value.observaciones = "Sin Observaciones");
    });
    setSREJuezElegible(response);
  }

  function resolverButtonHandler() {
    //console.log(errorsRobotInvissible);
    //display confirmation dialog
    setDisplayHacerLugarExclusionDialog(true);
  }
  const handleHacerLugarConfirmation = (result) => {
    if (result == "confirmed") {
      triggerResolver();
      setDisplayHacerLugarExclusionDialog(false);
    } else {
      setDisplayHacerLugarExclusionDialog(false);
    }
  };
  function triggerResolver() {
    HacerLugarRE.resolver(formatOBJHacerLugarSE(SREJuezElegible))
      .then((response) => {
        setResolverButtonDisabled(false);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        ////console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Sorteo de Resolucion de Exclusión resuelto.",
        });
        setShowMessage(true);
        // setDisplayDialogParent(false);
        setFiltersState({ ...filtersState, page: 0 });
        ////console.log("response");
        ////console.log(response);
        setShowHacerLugarDialog(false);
      })
      .catch((e) => {
        // setLoading(false);
        ////console.log(e);
      });
  }

  const handleCheckBox = () => () => {
    // const found = SREJuezElegible.juecesPurgedList.find(
    //   (element) => element.id_juez === value.id_juez
    // );

    // const newArr = SREJuezElegible.juecesPurgedList.map((item) => {
    //   if (item.id_juez === value.id_juez) {
    //     item.checked = !item.checked;
    //   }
    //   return item;
    // });

    setSREJuezElegible({ ...SREJuezElegible });
  };
  // function findSorteosID(jueces_elegibles_list, id_juez) {
  //   ////console.log("finding sorteos id");
  //   jueces_elegibles_list.forEach((element) => {
  //     if (element.id_juez === id_juez) {
  //       return element.tipoExclusionSelected?.id || null;
  //     }
  //   });
  //   return null;
  // }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, id_juez) => {
    setChecked(event.target.checked);
  };
  const handleChangeOption = (event: React.ChangeEvent<HTMLInputElement>, id_juez) => {
    let state = event.target.value == "true" ? true : false;

    setDummyStateForUpdateRender([...DummyStateForUpdateRender, { id_juez_elegible: id_juez }]); //weird things is that this is not needed at all, but it makes the render work on real time when choosing a value of the next dropdown.(TODO fix this)
    let index = SREJuezElegible.sorteo_juez_exclusion_list.findIndex((x) => x.id_juez == id_juez);
    let newOBJ = SREJuezElegible.sorteo_juez_exclusion_list[index];
    console.log("state");
    console.log(state);
    newOBJ = { ...newOBJ, haceLugarState: state };
    let modifiedState = SREJuezElegible;
    modifiedState.sorteo_juez_exclusion_list[index] = newOBJ;
    //console.log("gonna mod main obj1");
    //console.log(modifiedState);
    setSREJuezElegible(modifiedState);
    //setChecked(event.target.checked)
    //console.log("does this works****************");
    //console.log(id_juez);
    //console.log(
    //   SREJuezElegible.sorteo_juez_exclusion_list?.find((j) => j.id_juez === id_juez).haceLugarState
    // );
    //console.log("does this works****************");
  };

  useEffect(() => {
    setSorteosData({});
    setTiposExclusionData({});
    ////console.log("selectedRowDetailDialogIndex");
    getPendienteResolver({ idSorteo: selectedRowDetailDialogData.id_sorteo });
  }, []);

  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth="lg"
        onClose={() => {
          ////console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN OUTSIDE ONCLOSE");
          //  setShowExclusionConfirmCustomDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Resolución de Exclusión</DialogTitle>
        <DialogContent>
          <DialogContentText
            className="detailDialog"
            id="alert-dialog-description"
            component={"span"}
          >
            {/* previous line is a fix where material ui puts a div inside a p tag gotta add component= {'span'} prop */}
            <div className="detail-container">
              <div className="top">
                <div>
                  <span style={{ fontWeight: "900" }}>
                    <h2>{selectedRowDetailDialogData.caratula_expediente ?? ""}</h2>
                  </span>
                </div>

                <span style={{ justifyContent: "right" }}>
                  {selectedRowDetailDialogData.numero_expediente ?? ""}
                </span>
              </div>
              <div className="sub-detail">
                <div className="a">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fuero</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_fuero ?? ""}
                </div>
                <div className="b">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>N Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.codigo_sorteo ?? ""}
                </div>
                <div className="c">
                  {" "}
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Materia</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_materia ?? ""}
                </div>
                <div className="d">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Oficina</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_oficina ?? ""}
                </div>
                <div className="e">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Origen</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_origen ?? ""}
                </div>
                <div className="f">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Tipo de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_tipo_sorteo ?? ""}
                </div>
                <div className="g">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Estado de Sorteo</u>
                    </span>
                  </div>
                  {(selectedRowDetailDialogData.estado_actual_sorteo ?? "").toUpperCase()}
                </div>
                <div className="h">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fecha de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.fecha_sorteo +
                    " " +
                    selectedRowDetailDialogData.hora_sorteo}
                </div>
                <div className="i">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Creado Por</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.username ?? ""}
                </div>
              </div>
            </div>

            <Divider></Divider>

            {!loadingJuecesElegibles && !habilitadoExclusion ? (
              <div style={{ display: "flex", justifyContent: "center", fontWeight: "bold" }}>
                No hay jueces elegibles para este sorteo
              </div>
            ) : (
              <div className="sre-selection-container">
                <div className="judgeSelector">
                  {/* <Autocomplete
                    id="combo-box-dem2"
                    options={sorteos}
                    key={sorteos.id}
                    value={selectedTipoSorteoHeader}
                    sx={{ width: 300, marginTop: "20px", marginBottom: "40px" }}
                    getOptionLabel={(option) => option.descripcion}
                    renderInput={(params) => <TextField {...params} label="Tipo de Sorteo." />}
                    onChange={(event, newValue) => {
                      setSelectedTipoSorteoHeader(newValue);
                    }}
                  /> */}

                  <h3 style={{ marginLeft: "50px" }}>Jueces que resuelven</h3>

                  <ul style={{ marginLeft: "70px", marginBottom: "30px", marginTop: "10px" }}>
                    {SREJuezElegible.sorteo_juez_list?.map((value) => {
                      return (
                        <>
                          <li>{`${value.nombre_juez + " " + value.apellido_juez}`}</li>
                        </>
                      );
                    })}
                  </ul>
                  <h3 style={{ marginLeft: "50px" }}>Jueces a resolver</h3>
                  <span style={{ color: "#B90C0C" }}>
                    {errorsRobot.find((e) => e == "juez_checked_required") == null
                      ? ""
                      : "Elegir al menos 1 juez a excluir"}
                  </span>
                  <span style={{ color: "#B90C0C", marginBottom: "45px" }}>
                    {errorsRobot.find((e) => e == "observaciones_required") == null
                      ? ""
                      : "Falta Observacion"}
                  </span>
                  <div></div>
                  <span style={{ color: "#B90C0C", marginBottom: "45px" }}>
                    {errorsRobot.find((e) => e == "juez_tipo_exclusion_required") == null
                      ? ""
                      : "Falta Tipo de Exclusion"}
                  </span>

                  <List
                    dense
                    sx={{
                      width: "100%",
                      maxWidth: 360,
                      bgcolor: "background.paper",
                      marginLeft: "10px",
                      marginTop: "20px",
                    }}
                  >
                    {loadingJuecesElegibles && (
                      <>
                        <Stack spacing={1}>
                          <Skeleton variant="rectangular" width={210} height={60} />
                          <Skeleton variant="rectangular" width={210} height={60} />
                          <Skeleton variant="rectangular" width={210} height={60} />
                        </Stack>
                      </>
                    )}
                    {SREJuezElegible.sorteo_juez_exclusion_list?.map((value) => {
                      const labelId = `checkbox-list-secondary-label-${value}`;
                      return (
                        <>
                          {true && (
                            <ListItem key={value.id_juez} disablePadding>
                              <ListItemButton>
                                {/* <ListItemAvatar>
                                  <Avatar
                                    {...stringAvatar(value.nombre_juez + " " + value.apellido_juez)}
                                  />
                                </ListItemAvatar> */}
                                <ListItemText
                                  id={labelId}
                                  primary={`Juez: ${value.nombre_juez + " " + value.apellido_juez}`}
                                  secondary={`Tipo de Exclusión: ${value.descripcion_tipo_exclusion}`}
                                />
                              </ListItemButton>
                            </ListItem>
                          )}

                          {true && (
                            <>
                              <div style={{ marginTop: "20px", marginLeft: "30px" }}>
                                <FormControl>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    onChange={(event) => handleChangeOption(event, value.id_juez)}
                                    defaultValue="false"
                                  >
                                    <FormControlLabel
                                      value="false"
                                      control={<Radio />}
                                      label="No hacer lugar a la Exclusión"
                                    />
                                    <FormControlLabel
                                      value="true"
                                      control={<Radio />}
                                      label="Sí hacer lugar a la Exclusión"
                                    />
                                  </RadioGroup>
                                </FormControl>

                                {/* <FormControl>
                                  <FormLabel id="demo-controlled-radio-buttons-group"></FormLabel>

                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={
                                          SREJuezElegible.sorteo_juez_exclusion_list?.find(
                                            (j) => j.id_juez === value.id_juez
                                          ).haceLugarState || false
                                        }
                                        onChange={(event) =>
                                          handleChangeOption(event, value.id_juez)
                                        }
                                        inputProps={{ "aria-label": "controlled" }}
                                        name="a"
                                      />
                                    }
                                    label={
                                      SREJuezElegible.sorteo_juez_exclusion_list?.find(
                                        (j) => j.id_juez === value.id_juez
                                      ).haceLugarState
                                        ? "Hacer lugar a la Exclusion"
                                        : "No hacer lugar a la Exclusion"
                                    }
                                  />
                                </FormControl> */}

                                <TextField
                                  id="outlined-multiline-flexible"
                                  label="Observaciones"
                                  multiline
                                  maxRows={4}
                                  style={{ width: "300px" }}
                                  sx={{ marginBottom: "15px", marginTop: "15px" }}
                                  value={
                                    SREJuezElegible.sorteo_juez_exclusion_list.find(
                                      (v) => v.id == value.id_juez
                                    )?.observaciones
                                  }
                                  onChange={(event) => {
                                    ////console.log(event.target.value);
                                    setDummyStateForUpdateRender([
                                      ...DummyStateForUpdateRender,
                                      { id_juez_elegible: value.id_juez },
                                    ]); //weird things is that this is not needed at all, but it makes the render work on real time when choosing a value of the next dropdown.(TODO fix this)
                                    let index =
                                      SREJuezElegible.sorteo_juez_exclusion_list.findIndex(
                                        (x) => x.id_juez == value.id_juez
                                      );
                                    let newOBJ = SREJuezElegible.sorteo_juez_exclusion_list[index];
                                    newOBJ = { ...newOBJ, observaciones: event.target.value };
                                    let modifiedState = SREJuezElegible;
                                    modifiedState.sorteo_juez_exclusion_list[index] = newOBJ;
                                    ////console.log("gonna mod main obj2");
                                    setSREJuezElegible(modifiedState);
                                  }}
                                />
                              </div>
                            </>
                          )}
                          <Divider id="divi" style={{ height: "3px", opacity: 0.99 }} />
                        </>
                      );
                    })}
                  </List>
                </div>

                {/* <div className="buttons">accept</div> */}
                <div className="selectedDetail">
                  {SREJuezElegible.juecesPurgedList?.find((value) => value.checked == true) !==
                    undefined && <h3>Jueces Elegidos</h3>}

                  <div style={{ marginTop: "30px" }}>
                    {SREJuezElegible.juecesPurgedList?.map((value) => {
                      return (
                        <>
                          {value.checked && (
                            <>
                              <div>Juez: {value.nombre_juez + " " + value.apellido_juez}</div>
                              <div>
                                Tipo de Exclusion:{value.tipoExclusionSelected?.descripcion}
                              </div>
                              <div>Observaciones:{value.observaciones}</div>

                              <Divider></Divider>
                            </>
                          )}
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!loadingJuecesElegibles && !habilitadoExclusion ? (
            ""
          ) : (
            <Button disabled={resolverButtonDisabled} onClick={resolverButtonHandler} autoFocus>
              Resolver
            </Button>
          )}

          <Button
            onClick={() => {
              ////console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN CANCELARBTN");
              setShowHacerLugarDialog(false);
            }}
            autoFocus
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      {displayHacerLugarExclusionDialog && (
        <FinalConfirmDialogHacerExclusion onConfirmation={handleHacerLugarConfirmation} />
      )}
    </div>
  );
}

export default HacerLugarExclusionCustomDialog;
