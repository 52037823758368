import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OficinaService from "services/Oficina";
import OficinaMateriaService from "services/Oficina-Materia";
import OficinaJuezService from "services/Oficina-Juez";
import JuezService from "services/Juez";
// eslint-disable-next-line react/prop-types

// ['id_oficina', 'uuid-required'],
// ['id_juez', 'uuid-required'],
// ['tipo_juez', 'string'],
// ['observaciones', 'string'],
// ['id_usuario', 'uuid-required'],

function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idOficinaJuez = crudData.data.id || "";
  const [nombreMateria, setNombreMateria] = useState(crudData.data.descripcion_materia || "");
  const [observacionesOficinaJuez, setObservacionesOficinaJuez] = useState(
    crudData.data.observaciones || ""
  );

  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [materias, setMaterias] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [selectedOficina, setSelectedOficina] = useState(crudData.data.id_oficina || "");
  const [selectedJuez, setSelectedJuez] = useState(crudData.data.id_juez || "");
  const [jueces, setJueces] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState("");
  const [isTitularChecked, setisTitularChecked] = React.useState(
    crudData.data.tipo_juez == "suplente" ? false : true
  );
  useEffect(() => {
    OficinaService.getOficina({}).then((response) => {
      setOficinas(response.data);
    });
    JuezService.getJuez({}).then((response) => {
      setJueces(response.data);
    });
    // MateriaService.getMateria({}).then((response) => {
    //   setMaterias(response.data);
    // });
    // if (idOficina !== "") {
    //   loadOficinas();
    // }
  }, []);

  const loadOficinas = (id_fuero) => {
    OficinaService.getOficina({ id_fuero: id_fuero })

      .then((response) => {
        setOficinas(response.data);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const loadMaterias = (id_fuero) => {
    MateriaService.getMateria({ id_fuero: id_fuero })

      .then((response) => {
        setMaterias(response.data);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  function updateModifiedValues(row, action) {
    crudData.data.id_fuero = row.id_fuero;
    crudData.data.id_juez = row.id_juez;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id_oficina= row.id_oficina;
    crudData.data.id = row.id;
    crudData.data.tipo_juez = row.tipo_juez;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    //inquiry about what to show when its disabled/deleted./

    return crudData.data;
  }

  const handleObservacionesOficinaJuez = (e) => {
    setObservacionesOficinaJuez(e.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleIsTitularChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setisTitularChecked(event.target.checked);
  };
  const handleNombreMateria = (e) => {
    setNombreMateria(e.target.value);
  };

  const newOficinaJuezHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    OficinaJuezService.createOficinaJuez({
      id_juez: selectedJuez,
      id_oficina: selectedOficina,
      observaciones: observacionesOficinaJuez,
      tipo_juez: isTitularChecked ? "titular" : "suplente",
    })
      .then((response) => {
        //console.log("outside error")
          //console.log(response);
        if (response.error) {
          //console.log("inside error")
          //console.log(response);
          setMessage({
            severity: "error",
            messageText:  response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Juez asignado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateJuezHandler = () => {
    // setLoading(true);
    const updateObj = {
      id_juez: selectedJuez,
      id_oficina: selectedOficina,
      observaciones: observacionesOficinaJuez,
      id: idOficinaJuez,
      descripcion_oficina: oficinas.find((oficina) => oficina.id === selectedOficina).descripcion,
      tipo_juez: isTitularChecked ? "titular" : "suplente",
    };
    OficinaJuezService.updateOficinaJuez(updateObj)
      .then((response) => {
        //console.log("RESPONSE")
        //console.log(response)
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Juez actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteOficinaJuezHandler = () => {
  //   // setLoading(true);
  //   OficinaJuezService.deleteOficinaJuez({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idOficinaJuez,
  //   })
  //     .then((response) => {
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText:  response.data.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Juez fue deshabilitado" : "Juez fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create"
    //         ? "Nuevo Oficina-Juez"
    //         : crudAction === "readonly"
    //         ? "Información de Oficina-Juez"
    //         : "Editar Oficina-Juez"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
    <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    {/* <Autocomplete
                      id="combo-box-demo"
                      options={fueros}
                      value={fueros.find((fuero) => fuero.id === crudData.data.id_fuero) || null}
                      sx={{ width: 300, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        setSelectedFuero(newValue);
                        loadMaterias(newValue.id);
                        loadOficinas(newValue.id);
                      }}
                    /> */}

                    
                    <div>
                    <FormGroup sx={{ float: "right", marginTop: "5px", marginBottom: "12px" }}>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={isTitularChecked}
                            onChange={handleIsTitularChecked}
                            inputProps={{ "aria-label": "controlled" }}
                            color="primary"
                            // color={isTitularChecked ? "success" : "warning"}
                          />
                        }
                        label={isTitularChecked ? "Titular" : "Suplente"}
                      />
                    </FormGroup>

                    <Autocomplete
                      id="combo-box-demo"
                      options={jueces.filter((j)=>j.habilitado)}
                      value={jueces.find((juez) => juez.id === selectedJuez) || null}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.fullName || ""}
                      renderInput={(params) => <TextField {...params} label="Jueces" />}
                      onChange={(event, newValue) => {
                        //console.log("setting juez:")
                        //console.log(newValue)
                        setSelectedJuez(newValue.id);
                      }}
                    />

                    </div>

                    <Autocomplete
                      id="combo-box-demo"
                      options={oficinas.filter((of)=>of.habilitado)}
                      value={oficinas.find((oficina) => oficina.id === selectedOficina) || null}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion_oficina || ""} 
                      renderInput={(params) => <TextField {...params} label="Oficina" />}
                      onChange={(event, newValue) => {
                        setSelectedOficina(newValue.id);
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Oficina-Juez"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesOficinaJuez}
                    onChange={handleObservacionesOficinaJuez}
                  />
                </div>
                {/* {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesOficinaJuez}
                    />
                  </div>
                )} */}

                {/* reeplace this for a filter and exclude the disabled ones */}

                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <MDButton
                    onClick={crudAction === "create" ? newOficinaJuezHandler : updateJuezHandler}
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={crudAction === "readonly"}
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </MDButton>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar la Oficina-Juez?"
                    : "¿Está seguro que desea habilitar la Oficina-Juez?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreMateria}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteOficinaJuezHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
    </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
