import Base from "./axiosBase";
import { formatDate } from "#services/utils";
const SorteoQty = {
  // eslint-disable-next-line arrow-body-style
  getSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    console.log("params in service");
    console.log(params);
    //debugger;
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.export) {
      url += `&export=${params.export}`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (params.filters) {
      if (params.filters.fechaDesde) {
        url += `&fecha_sorteo_desde=${formatDate(params.filters.fechaDesde)}`;
      }

      if (params.filters.fechaHasta) {
        url += `&fecha_sorteo_hasta=${formatDate(params.filters.fechaHasta)}`;
      }

      if (params.filters.tipoGrupo) {
        url += `&tipo_grupo=${params.filters.tipoGrupo}`;
      }
      if (params.filters.funcion) {
        url += `&funcion=${params.filters.funcion}`;
      }
    }
    if (url !== "") {
      url = `sorteo-qty/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}${url}`;
    } else {
      url = `sorteo-qty/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}`;
    }

    return Base.get(url).then((res) => res.data);
  },
};

export default SorteoQty;
