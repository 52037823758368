import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MateriaService from "services/Materias";
import OficinaMateriaService from "services/Oficina-Materia";
import ExpedienteService from "services/Expediente";
import OficinaService from "services/Oficina";
import OrigenService from "services/Origen";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idExpediente = crudData.data.id || "";
  const [observacionesExpediente, setObservacionesExpediente] = useState(
    crudData.data.observaciones || ""
  );

  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] =
    useState(MAX_LENGTH_OBSERVACIONES);
  const [oficinas, setOficinas] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [selectedMateria, setSelectedMateria] = useState("");
  const [expediente, setExpediente] = useState({
    id: crudData.data.id || "",
    caratula: crudData.data.caratula || "",
    numero: crudData.data.numero || "",
    id_materia: crudData.data.id_materia || "",
    id_origen: crudData.data.id_origen || "",
    id_oficina: localStorage.getItem("id_oficina"),
    observaciones: crudData.data.observaciones || "",
    habilitado: crudData.data.habilitado || false,
  });
  //error carátula
  const [error, setError ] = useState({
    error: false,
    message: "",
  })
  const [errorExp, setErrorExp ] = useState({
    error: false,
    message: "",
  })

  const [errorOrigen, setErrorOrigen ] = useState({
    error: false,
    message: "El origen es requerido"
  })

 const [errorMateria, setErrorMateria] = useState({
    error: false,
    message: "La materia es requerida"
  })

  function updateModifiedValues(row, action) {
    crudData.data.numero = row.numero;
    crudData.data.caratula = row.caratula;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.id_fuero = row.id_fuero;
    crudData.data.id_materia = row.id_materia;
    crudData.data.id_oficina = row.id_oficina;
    crudData.data.id_origen = row.id_origen;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const loadOficinas = (id_fuero) => {
    OficinaService.getOficina({ id_fuero: id_fuero })

      .then((response) => {
        setOficinas(response.data);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const loadMaterias = (id_fuero) => {
    console.log('llamado a load materias');
    OficinaMateriaService.getOficinaMateria({
      filters: {
        fuero: {
          id: localStorage.getItem("id_fuero"),
        },
        oficina: { id: localStorage.getItem("id_oficina") },
        habilitado: {habilitado: "H"},
      },
    })

      .then((response) => {
        setMaterias(response.data);
        setSelectedMateria(
          response.data.find((m) => m.id_materia === crudData.data.id_materia) ?? null
        );
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    OrigenService.getOrigen({
      filters: { fuero: { id: localStorage.getItem("id_fuero") }, habilitado: true },
    }).then((response) => {
      setOrigenes(response.data);
    });

    if (crudAction === "update") {
      loadOficinas(crudData.data.id_fuero || localStorage.getItem("id_fuero"));
    }
    loadMaterias(localStorage.getItem("id_fuero"));
  }, []);

  const handleExpedienteCaratula = (e) => {
    if(e.target.value.length<5){
      setError({
        error:true,
        message: "La carátula es requerida (al menos 5 caracteres)"
      })
    }else{
      setError({
        error:false,
        message: "",
      })
    }
    setExpediente((prev) => ({ ...prev, caratula: e.target.value }));
  };

  const handleExpedienteNumero = (e) => {
    if(e.target.value.length<3){
      setErrorExp({
        error:true,
        message: "El número es requerido (al menos 3 caractéres)"
      })
    }else{
      setErrorExp({
        error: false,
        message: "",
      })
    }
    setExpediente((prev) => ({ ...prev, numero: e.target.value }));
  };

  const handleObservacionesExpediente = (e) => {
    setCharactersLeftObservaciones(MAX_LENGTH_OBSERVACIONES + 1 - e.target.value.length);
    if (e.target.value.length <= MAX_LENGTH_OBSERVACIONES) {
      setExpediente((prev) => ({ ...prev, observaciones: e.target.value }));
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const newExpedienteHandler = () => {
    
    console.log(expediente.id_materia+' materia deseleccionada')
    // Validaciones para no enviar el form con errores...
    if(expediente.id_materia === "" || expediente.id_origen === "" || expediente.numero.length <= 3 || expediente.caratula.length<10){
      
      let datoFaltante = "";

      if(expediente.caratula.length<10){
        setError({
          error:true,
          message: "La carátula es requerida (al menos 5 caracteres)"
        })
        datoFaltante = "correctamente la carátula (al menos 5 caracteres)";
      }

      if (expediente.id_materia === "" && expediente.id_origen === ""){
        if(expediente.numero.length <= 3){
          setErrorExp({
            error: true,
            message: "El número es requerido (al menos 3 caractéres)",
          })
          datoFaltante = 'el número ni la materia ni el origen'
        }else{ datoFaltante = "la materia ni el origen";}
      }else if(expediente.id_origen === ""){
        if(expediente.numero.length <= 3){
          setErrorExp({
            error: true,
            message: "El número es requerido (al menos 3 caractéres)",
          })
          datoFaltante = "el número ni el origen";
        }else{ datoFaltante = "el origen";
      }
      }else if(expediente.id_materia === ""){
        if(expediente.numero.length <= 3){
          setErrorExp({
            error: true,
            message: "El número es requerido (al menos 3 caractéres)",
          })
          datoFaltante = "el número ni la materia"
        }else{ datoFaltante = "la materia";}
      }else if(expediente.numero.length <= 3 ){ 
        setErrorExp({
          error: true,
          message: "El número es requerido (al menos 3 caractéres)",
        })
        datoFaltante = "correctamente el número (al menos 3 caracteres)";
      }
      setMessage({
        severity: "error",
        messageText: `No se indicó ${datoFaltante} para el nuevo expediente`
      });      
      setShowMessage(true);
      return;
    }
    
    
    const updateObj = {
      caratula: expediente.caratula,
      numero: expediente.numero,
      observaciones: expediente.observaciones,
      id_oficina: expediente.id_oficina,
      id_materia: expediente.id_materia,
      id_origen: expediente.id_origen,
    };
    // setLoading(true);
    //console.log("sending obj to insert expediente");
    //console.log(updateObj);
    ExpedienteService.createExpediente(updateObj)
      .then((r) => r.data)
      .then((response) => {
        //console.log("what the hell is this response?");
        //console.log(response);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Expediente creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow({});
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateExpedienteHandler = () => {
    // setLoading(true);
    const updateObj = {
      caratula: expediente.caratula,
      numero: expediente.numero,
      observaciones: expediente.observaciones,
      id_oficina: expediente.id_oficina,
      id_materia: expediente.id_materia,
      id_origen: expediente.id_origen,
      id: expediente.id,
    };
    ExpedienteService.updateExpediente(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Expediente actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteExpedienteHandler = () => {
  //   // setLoading(true);
  //   ExpedienteService.deleteExpediente({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idExpediente,
  //   })
  //     .then((response) => {
  //       //console.log("response");
  //       //console.log(response);
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Expediente fue deshabilitado" : "Expediente fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submit");
  }

  return (
    <>
    {/* <StyledEngineProvider injectFirst>
      <Dialog open onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {crudAction === "create" ? "Nuevo Expediente" : "Editar Expediente"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers> */}
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                <div className="nombrecrud">
                  <div>
                  <TextField
                    required
                    error={error.error}
                    helperText={error.message}
                    sx={{ width: "350px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 300,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Carátula del Expediente"
                    autoFocus
                    value={expediente.caratula}
                    onChange={handleExpedienteCaratula}
                  />
                  </div>
                  <div>
                  <TextField
                    error={errorExp.error}
                    helperText={errorExp.message}
                    required
                    sx={{ width: "350px", pb: 1, marginTop: "10px" }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Número de Expediente"
                    value={expediente.numero}
                    onChange={handleExpedienteNumero}
                  />
                  </div>
                  <div>
                    <Autocomplete
                      required
                      id="combo-box-demo"
                      getOptionLabel={(option) => option.descripcion_origen ?? ""} // if not found, return empty string to avoid controlled/uncontrolled component warning
                      options={origenes}
                      value={origenes.find((origen) => origen.id === expediente.id_origen) ?? null} // if not found, return empty string to avoid controlled/uncontrolled component warning
                      sx={{ width: '350px', marginTop: "10px" }}
                      renderInput={(params) => <TextField {...params} label="Origen" error={errorOrigen.error} helperText={errorOrigen.message}/>}
                      onChange={(event, newValue) => {                       
                        //console.log("DROPDOWN ORIGEN CHANGED");
                        console.log(event.target.id)
                        if (newValue !== null ) {
                          loadOficinas(newValue.id_fuero);
                          loadMaterias(newValue.id_fuero);
                          setExpediente((prev) => ({ ...prev, id_origen: newValue.id }));
                          setErrorOrigen({
                            error: false,
                            message: ""
                          })
                        } else {  
                          setSelectedMateria(null);                        
                          setExpediente((prev) => ({ ...prev, id_origen: ""}));
                          setErrorOrigen({
                            error: true,
                            message: "El origen es requerido"
                          })
                        }
                      }}
                      
                    />
                  </div>

                  <div>
                    <Autocomplete                      
                      id="combo-box-demo"
                      getOptionLabel={(option) => option.descripcion_materia ?? ""}
                      options={materias}
                      value={ selectedMateria}
                      sx={{ width: '350px', marginTop: "10px" }}
                      renderInput={(params) => <TextField {...params} label="Materia" error={errorMateria.error} helperText={errorMateria.message}/>}
                      onChange={(event, newValue) => {
                        setSelectedMateria(newValue);
                        if (newValue) {
                          setExpediente((prev) => ({ ...prev, id_materia: newValue.id_materia }));
                          setErrorMateria({
                            error: false,
                            message: "",
                          })
                        } else {
                          setExpediente((prev) => ({ ...prev, id_materia: "" }));
                          setErrorMateria({
                            error: true,
                            message: "La materia es requerida",
                          })
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="observacionescrud">
                  <TextField                  
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ width: "350px", pb: 2, marginTop: "15px" }}
                    multiline
                    rows={5}
                    value={expediente.observaciones}
                    onChange={handleObservacionesExpediente}
                  />
                </div>
                <div className="botonesaccionescrud">
                  <MDButton
                    type="submit"
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<CancelIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <MDButton
                    onClick={
                      crudAction === "create" ? newExpedienteHandler : updateExpedienteHandler
                    }
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </MDButton>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Expediente?"
                    : "¿Está seguro que desea habilitar el Expediente?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {expediente.caratula}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<CancelIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteExpedienteHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
    </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
