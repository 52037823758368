// This file contains the list of errors that can be returned by the API
const errorsList = [
  {
    message_code: "user-cu-not-authorized",
    human_message: "el usuario no tiene los permisos para ejecutar el caso de uso",
  },
  {
    message_code: "err-exclusion-sorteo-en-curso-not-allowed",
    human_message: "hay un sorteo en curso actualmente en la Oficina",
  },
  {
    message_code: "err-exclusion-automatica-edit-not-allowed",
    human_message:
      "no es posible editar la exclusión debido a que fue generada automaticamente desde la resolucion de un sorteo",
  },
  {
    message_code: "err-exclusion-cambio-not-allowed",
    human_message: "no es posible cambiar el tipo de la exclusion",
  },
  {
    message_code: "err-exclusion-tipo-fuero-not-allowed",
    human_message: "el tipo de exclusión no esta asociado al fuero",
  },
  {
    message_code: "err-exclusion-expediente-oficina-not-allowed",
    human_message: "el expediente no pertenece a la oficina",
  },
  {
    message_code: "err-exclusion-juez-existente-not-allowed",
    human_message: "el juez ya tiene una exclusión en el expediente",
  },
  {
    message_code: "err-exclusion-asociada-sorteo-edit-not-allowed",
    human_message:
      "no es posible editar la exclusión debido a que se encuentra asociada a un sorteo",
  },
  {
    message_code: "err-exclusion-fecha-desde-invalid",
    human_message: "fecha desde invalida",
  },
  {
    message_code: "err-exclusion-fecha-hasta-invalid",
    human_message: "fecha hasta invalida",
  },
  {
    message_code: "err-exclusion-periodo-invalid",
    human_message: "periodo de fechas invalido",
  },
  {
    message_code: "err-exclusion-fecha-desde-not-allowed",
    human_message: "fecha desde no permitida. debe ser >= hoy",
  },
  {
    message_code: "err-exclusion-modificacion-anterior-hoy-not-allowed",
    human_message: "no es posible modificar una exclusión anterior a hoy",
  },
  {
    message_code: "err-exclusion-modificacion-fecha-desde-not-allowed",
    human_message: "no es posible modificar la fecha desde del periodo de la exclusion temporal",
  },
  {
    message_code: "err-exclusion-modificacion-fecha-hasta-not-allowed",
    human_message: "la fecha hasta del periodo de exclusión temporal no puede ser anterior a hoy",
  },
  {
    message_code: "err-exclusion-fecha-desde-not-allowed",
    human_message: "la fecha desde del periodo de exclusion debe ser >= a hoy",
  },
  {
    message_code: "err-exclusion-periodo-superpuesto-not-allowed",
    human_message: "el periodo de exclusion se superpone con otra exclusion del juez en la oficina",
  },
  {
    message_code: "err-exclusion-sorteo-activo-not-allowed",
    human_message: "el juez tiene al menos un sorteo activo en el periodo de la exclusion",
  },
  {
    message_code: "err-exclusion-modificacion-exclusion-anterior-igual-hoy-not-allowed",
    human_message: "no es posible modificar exclusion permanente anterior o igual a hoy",
  },
  {
    message_code: "err-exclusion-permanente-existente-not-allowed",
    human_message:
      "no es posible registrar la exclusión ya que el juez tiene actualmente registrada una exclusion permanente",
  },
  {
    message_code: "err-exclusion-tipo-expediente-not-allowed",
    human_message: "no es posible registrar la exclusion de tipo expediente",
  },
  {
    message_code: "err-exclusion-superpuesto-not-allowed",
    human_message:
      "no es posible registrar la exclusión debido a que el periodo se superpone con exclusion permanente",
  },
  {
    message_code: "err-exclusion-observaciones-required",
    human_message: "observaciones de exclusión requerido",
  },
  {
    message_code: "err-exclusion-automatica-delete-not-allowed",
    human_message:
      "no es posible deshabilitar la exclusión debido a que fue generada automaticamente desde la resolucion de un sorteo",
  },
  {
    message_code: "err-exclusion-delete-not-allowed",
    human_message:
      "no es posible anular la exclusion de expediente. se encuentra asociada a sorteos activos",
  },
  {
    message_code: "err-exclusion-delete-anterior-hoy-not-allowed",
    human_message: "no es posible anular exclusion temporal anterior a hoy",
  },
  {
    message_code: "err-exclusion-motivo-anulacion-required",
    human_message: "motivo de la anulacion de la exclusion requerido",
  },
  {
    message_code: "err-exclusion-cambio-oficina-not-allowed",
    human_message: "no es posible cambiar la oficina de la exclusion",
  },
  {
    message_code: "err-exclusion-juez-oficina-not-allowed",
    human_message: "el juez no pertenece a la oficina",
  },
  {
    message_code: "err-exclusion-cambio-tipo-not-allowed",
    human_message: "no es posible cambiar el tipo de exclusion de la exclusion",
  },
  {
    message_code: "err-exclusion-tipo-fuero-not-allowed",
    human_message: "el tipo de exclusion no esta habilitado en el fuero de la oficina",
  },
  {
    message_code: "err-expediente-fuero-oficina-materia-invalid",
    human_message: "el fuero de oficina debe ser igual a fuero de la materia",
  },
  {
    message_code: "err-expediente-fuero-oficina-origen-invalid",
    human_message: "el fuero de oficina debe ser igual a fuero del origen",
  },
  {
    message_code: "err-expediente-numero-expediente-invalid",
    human_message: "numero de expediente invalido - length >= 3",
  },
  {
    message_code: "err-expediente-caratula-expediente-invalid",
    human_message: "caratula de expediente invalido - length >= 10",
  },
  {
    message_code: "err-expediente-already-exist",
    human_message: "el expediente ya existe",
  },
  {
    message_code: "err-expediente-anulacion-not-allowed",
    human_message: "no es posible anular un expediente que tiene sorteos no anulados",
  },
  {
    message_code: "err-fuero-tipo-exclusion-already-exist",
    human_message: "el fuero - tipo de exclusión ya existe",
  },
  {
    message_code: "err-fuero-tipo-sorteo-already-exist",
    human_message: "el fuero - tipo de sorteo ya existe",
  },
  {
    message_code: "err-fuero-descripcion-required",
    human_message: "descripcion de fuero requerido",
  },
  {
    message_code: "err-fuero-already-exist",
    human_message: "el fuero ya existe",
  },
  
  {
    message_code: "err-juez-con-exclusion-permanente-not-allowed",
    human_message: "el juez tiene actualmente registrada una exclusion permanente",
  },
  {
    message_code: "err-juez-nombre-required",
    human_message: "nombre de juez requerido",
  },
  {
    message_code: "err-juez-apellido-required",
    human_message: "apellido de juez requerido",
  },
  {
    message_code: "err-juez-already-exist",
    human_message: "el juez ya existe",
  },
  {
    message_code: "err-materia-descripcion-required",
    human_message: "descripcion requerida",
  },
  {
    message_code: "err-materia-already-exist",
    human_message: "la materia ya existe",
  },
  {
    message_code: "err-oficina-juez-tipo-juez-invalid",
    human_message: "el tipo_juez es invalido [titular, suplente]",
  },
  {
    message_code: "err-oficina-juez-already-exist",
    human_message: "oficina-juez ya existe",
  },
  {
    message_code: "err-oficina-materia-fuero-oficina-materia-invalid",
    human_message: "fuero de oficina debe ser igual a fuero de materia",
  },
  {
    message_code: "err-oficina-materia-already-exist",
    human_message: "oficina-materia ya existe",
  },
  {
    message_code: "err-oficina-descripcion-required",
    human_message: "descripcion requerida",
  },
  {
    message_code: "err-oficina-already-exist",
    human_message: "oficina ya existe",
  },
  {
    message_code: "err-origen-descripcion-required",
    human_message: "descripcion requerida",
  },
  {
    message_code: "err-origen-already-exist",
    human_message: "origen ya existe",
  },
  {
    message_code: "err-sorteo-tipo-sorteo-not-allowed",
    human_message: "no es posible resolver un sorteo que no es de resolucion de exclusion",
  },
  {
    message_code: "err-sorteo-estado-not-allowed",
    human_message:
      "no es posible resolver un sorteo de resolucion de exclusion que no se encuentre en estado pendiente-resolver actualmente",
  },
  {
    message_code: "err-sorteo-jueces-resolucion-list-required",
    human_message: "jueces_resolucion_list requerido",
  },
  {
    message_code: "err-sorteo-jueces-resolucion-list-not-allowed",
    human_message: "jueces duplicados en jueces_resolucion_list",
  },
  {
    message_code: "err-sorteo-origen-invalid",
    human_message: "id_sorteo_origen invalido",
  },
  {
    message_code: "err-sorteo-estado-sorteo-origen-not-allowed",
    human_message:
      "sorteo de origen debe encontrarse en estado pendiente-resolucion-exclusion o inactivo",
  },
  {
    message_code: "err-sorteo-juez-oficina-not-allowed",
    human_message: "el juez no pertenece a la oficina",
  },
  {
    message_code: "err-sorteo-juez-exclusion-not-allowed",
    human_message: "el juez no tiene planteo de exclusion en el sorteo",
  },
  {
    message_code: "err-sorteo-resolucion-invalid",
    human_message: "resolucion invalido [shl, nhl]",
  },
  {
    message_code: "err-sorteo-resolucion-notas-required",
    human_message: "notas de resolucion requerido",
  },
  {
    message_code: "err-sorteo-tipo-not-allowed",
    human_message:
      "tipo no valido [unipersonal, colegiado, plenario] tipo no valido [resolucion-exclusion]",
  },
  {
    message_code: "err-sorteo-tipo-sorteo-fuero-not-allowed",
    human_message: "tipo de sorteo no habilitado en el fuero de la oficina",
  },
  {
    message_code: "err-sorteo-expediente-oficina-not-allowed",
    human_message: "expediente no pertenece a la oficina",
  },
  {
    message_code: "err-sorteo-regular-not-allowed",
    human_message:
      "no es posible hacer sorteo regular del expediente dado que tiene sre(s) en estado pendiente-resolucion-exclusion o pendiente-resolver",
  },
  {
    message_code: "err-sorteo-sorteo-en-curso-not-allowed",
    human_message: "hay un sorteo en curso actualmente en la Oficina",
  },
  {
    message_code: "err-sorteo-jueces-sorteables-insuficientes-not-allowed",
    human_message: "no es posible sortear. insuficiente cantidad de jueces sorteables",
  },
  {
    message_code: "err-sorteo-tipo-sorteo-previo-not-allowed",
    human_message:
      "no es posible llevar a cabo sorte de este tipo de sorteo debido al tipo de sorteo del sorteo previo del expediente",
  },
  {
    message_code: "err-sorteo-anulacion-sre-not-allowed",
    human_message: "no es posible anular un sorteo de tipo resolucion-exclusion",
  },
  {
    message_code: "err-sorteo-already-disabled",
    human_message: "sorteo ya se encuentra anulado",
  },
  {
    message_code: "err-sorteo-no-activo-not-allowed",
    human_message: "no es posible anular un sorteo regular que no se encuentra activo actualmente",
  },
  {
    message_code: "err-sorteo-sr-no-ultimo-not-allowed",
    human_message: "el sr de origen no es el ultimo sr activo del expediente",
  },
  {
    message_code: "err-sorteo-oficina-anula-not-allowed",
    human_message: "oficina que anula debe ser igual a oficina del sorteo",
  },
  {
    message_code: "err-sorteo-motivo-anulacion-required",
    human_message: "motivo de anulacion del sorteo requerido",
  },
  {
    message_code: "err-sorteo-id-not-exist",
    human_message: "id de sorteo no existe",
  },
  {
    message_code: "err-sorteo-estado-sorteo-origen-not-allowed",
    human_message:
      "no es posible hacer un sorteo de resolucion de exclusion a partir del sorteo indicado debido a su estado actual",
  },
  {
    message_code: "err-sorteo-juez-no-existe-not-allowed",
    human_message: "no existe el juez del sorteo de origen",
  },
  {
    message_code: "err-sorteo-juez-deshabilitado-not-allowed",
    human_message: "el juez del sorteo de origen se encuentra deshabilitado",
  },
  {
    message_code: "err-sorteo-juez-no-existe-en-oficina-not-allowed",
    human_message: "no existe en la oficina el juez del sorteo de origen",
  },
  {
    message_code: "err-sorteo-juez-no-habilitado-en-oficina-not-allowed",
    human_message: "el juez del sorteo de origen ya no esta habilitado en la oficina",
  },
  {
    message_code: "err-sorteo-juez-exluido-en-expediente-not-allowed",
    human_message: "el juez del sorteo de origen se encuentra excluido para el expediente",
  },
  {
    message_code: "err-sorteo-juez-exluido-temporalmente-not-allowed",
    human_message: "el juez del sorteo de origen se encuentra excluido el dia de hoy",
  },
  {
    message_code: "err-sorteo-sre-not-allowed",
    human_message:
      "no es posible hacer un sre ya que el sorteo de origen actualmente tiene sre pendientes de resolver",
  },
  {
    message_code: "err-sorteo-jueces-sorteados-origen-list-required",
    human_message: "jueces_sorteados_origen_list requerido",
  },
  {
    message_code: "err-sorteo-jueces-elegibles-list-required",
    human_message: "jueces_elegibles_list requerido",
  },
  {
    message_code: "err-sorteo-sin-jueces-titulares-not-allowed",
    human_message: "oficina sin jueces titulares",
  },
  {
    message_code: "err-sorteo-oficina-invalid",
    human_message: "la oficina recibida es distinta de la oficina del sorteo",
  },
  {
    message_code: "err-sorteo-tipo-not-allowed",
    human_message:
      "tipo no valido [unipersonal, colegiado, plenario] tipo no valido [resolucion-exclusion]",
  },
  {
    message_code: "err-sorteo-tipo-sorteo-fuero-not-allowed",
    human_message: "tipo de sorteo no habilitado en el fuero de la oficina",
  },
  {
    message_code: "err-sorteo-expediente-oficina-not-allowed",
    human_message: "expediente no pertenece a la oficina",
  },
  {
    message_code: "err-sorteo-expediente-invalid",
    human_message: "expediente invalido",
  },
  {
    message_code: "err-sorteo-origen-invalid",
    human_message: "el sorteo de origen recibido es incorrecto",
  },
  {
    message_code: "err-sorteo-sr-no-ultimo-not-allowed",
    human_message: "el sr de origen no es el ultimo sr activo del expediente",
  },
  {
    message_code: "err-sorteo-jueces-sorteados-informados-not-allowed",
    human_message: "jueces sorteados informados difieren de jueces sorteados en sorteo de origen",
  },
  {
    message_code: "err-sorteo-estado-sorteo-origen-not-allowed",
    human_message:
      "no es posible hacer un sorteo de resolucion de exclusion a partir del sorteo indicado debido a su estado actual",
  },
  {
    message_code: "err-sorteo-jueces-elegibles-informados-not-allowed",
    human_message: "jueces elegibles informados difieren de jueces elegibles en sorteo de origen",
  },
  {
    message_code: "err-sorteo-jueces-sorteables-insuficientes-not-allowed",
    human_message: "no es posible sortear. insuficiente cantidad de jueces sorteables",
  },
  {
    message_code: "err-sorteo-anulacion-sorteo-regular-not-allowed",
    human_message: "no es posible anular un sorteo de tipo regular",
  },
  {
    message_code: "err-sorteo-already-disabled",
    human_message: "sorteo ya se encuentra anulado",
  },
  {
    message_code: "err-sorteo-no-pendiente-resolver-not-allowed",
    human_message:
      "No es posible anular un sorteo de resolucion de exclusion que no se encuentra pendiente-resolver actualmente",
  },
  {
    message_code: "err-sorteo-oficina-anula-not-allowed",
    human_message: "oficina que anula debe ser igual a oficina del sorteo",
  },
  {
    message_code: "err-sorteo-motivo-anulacion-required",
    human_message: "motivo de anulacion del sorteo requerido",
  },
  {
    message_code: "err-tipo-exclusion-id-not-exist",
    human_message: "id no existe",
  },
  {
    message_code: "err-tipo-exclusion-tipo-exclusion-invalid",
    human_message: "tipo de exclusion invalido [expediente, temporal, permanente]",
  },
  {
    message_code: "err-tipo-exclusion-descripcion-required",
    human_message: "descripcion requerido",
  },
  {
    message_code: "err-tipo-exclusion-cambio-tipo-not-allowed",
    human_message: "cambio del tipo de tipo de exclusion no permitido",
  },
  {
    message_code: "err-tipo-exclusion-already-exist",
    human_message: "tipo de exclusión ya existe",
  },
  {
    message_code: "err-tipo-sorteo-id-not-exist",
    human_message: "id no existe",
  },
  {
    message_code: "err-tipo-sorteo-tipo-sorteo-invalid",
    human_message:
      "tipo de sorteo invalido [unipersonal, colegiado, plenario, resolucion-exclusion]",
  },
  {
    message_code: "err-tipo-sorteo-descripcion-required",
    human_message: "descripcion requerido",
  },
  {
    message_code: "err-tipo-sorteo-cantidad-jueces-not-allowed",
    human_message:
      "cantidad de jueces no permitida para tipo de sorteo de resolucion de exclusion ( = 0 ) cantidad de jueces no permitida para tipo de sorteo regular ( > 0 )",
  },
  {
    message_code: "err-tipo-sorteo-already-exist",
    human_message: "tipo de exclusión ya existe",
  },
  {
    message_code: "err-tipo-sorteo-cambio-tipo-not-allowed",
    human_message: "cambio del tipo de tipo de sorteo no permitido",
  },
  {
    message_code: "err-tipo-sorteo-tipo-sorteo-previo-not-allowed",
    human_message: "Tipo de Sorteo inválido",
  },
  {
    message_code: "err-expediente-oficina-materia-not-allowed",
    human_message: "la oficina no puede sortear expedientes de la materia",
  },
  {
    message_code: "err-sorteos-fuero-oficina-materia-invalid",
    human_message: "fuero de oficina debe ser igual a fuero de materia",
  },
  {
    message_code: "err-juez-juez-oficina-not-allowed" || "err-tipo-exclusion-tipo-fuero-not-allowed", 
    human_message: "El juez no pertenece a la oficina",
  },
  {
    message_code: "err-tipo-exclusion-tipo-fuero-not-allowed", 
    human_message: "El juez no pertenece a la oficina",
  },
  { message_code: "err-juez-not-enabled", 
    human_message: "Juez no habilitado",
  },
  { message_code: "err-unexpected",  
    human_message: "No puede deshabilitar una exclusión de tipo permanente",
  },
  { message_code: "err-tipo-exclusion-not-enabled",
    human_message: "Tipo de exclusión no habilitado",
  },
  { message_code: "err-tipo-sorteo-not-enabled",
    human_message: "Tipo de sorteo no habilitado",
  },
  { message_code: "err-fuero-not-enabled",
    human_message: "Fuero no habilitado",
  },
  { message_code: "err-expediente-actualizacion-not-allowed",
    human_message: "No es posible actualizar un expediente con sorteos",
  },
];

export default errorsList;
