import Base from "./axiosBase";

const TipoExclusionService = {
  createTipoExclusion: async (exclusion) => Base.post("/tipo-exclusion/", exclusion),
  deleteTipoExclusion: async (exclusion) =>
    Base.delete(
      `tipo-exclusion/?id=${exclusion.id}&action=${exclusion.action}`
    ).then((res) => res.data),
  updateTipoExclusion: async (exclusion) =>
    Base.put(`tipo-exclusion/?id=${exclusion.id}`, exclusion).then((res) => res.data),
  
  // eslint-disable-next-line arrow-body-style
  getTipoExclusion: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (url !== "") {
      url = `tipo-exclusion/?${url}`;
    } else {
      url = "tipo-exclusion/";
    }
    //console.log("PARAMS FILTERS");
    //console.log(params.filters);
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (params.filters.tipo) {
        url += `&tipo=${params.filters.tipo.descripcion}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default TipoExclusionService;
