// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import OficinaService from "services/Oficina.js";
import ExpedienteService from "services/Expediente";
import TipoSorteoService from "services/Tipo-Sorteo";
import FueroTipoSorteoService from "services/Fuero-Tipo-Sorteo";
import SorteoService from "services/Sorteo";
import React, { useState, useEffect } from "react";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDButton";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { ListItemButton } from "@mui/material";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import { SorteoProps } from "./interface/sorteo";
import Message from "./../administracion/components/Notifications/Message";
import { stringToColor, stringAvatar } from "#services/utils.js";
import { Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ModalAlert from "#components/GenericPermissionsAlert/ModalAlert.tsx";
import "./styles.css";
function Tables() {
  let tiposSorteosHabilitadosBotones = ["UNIPERSONAL", "COLEGIADO", "PLENARIO"];
  const [oficinas, setOficinas] = useState([]);
  const [sorteos, setSorteos] = useState([]);
  const [displayConfirmSorteo, setDisplayConfirmSorteo] = useState(false);
  const [tiposSorteos, setTiposSorteos] = useState([]);
  const [expedientes, setExpedientes] = useState([]);
  const [selectedExpediente, setSelectedExpediente] = useState(null);
  const [selectedTipoSorteo, setSelectedTipoSorteo] = useState(null);
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [tipoSorteoBotonera, setTipoSorteoBotonera] = useState("UNIPERSONAL");
  const [observaciones, setObservaciones] = useState("");
  const [crearButtonDisabled, setCrearButtonDisabled] = React.useState(false);
  const [insufficientPermissions, setInsufficientPermissions] = useState(false);
  const [sorteoBig, setSorteoBig] = useState<SorteoProps>({
    id_tipo_sorteo: "",
    id_expediente: "",
    observaciones: "",
    id_oficina: localStorage.getItem("id_oficina"),
  });
  async function setOficinasData(params) {
    const response = await OficinaService.getOficina(params);
    setOficinas(response);
  }
  async function setSorteosData(params) {
    //console.log("got sorteos");
    //console.log("sending params to fuero tipo sorteo");
    //console.log(params);
    const response = await FueroTipoSorteoService.getSorteo(params);
    //console.log("data");
    //console.log(response);

    setTiposSorteos(response.data);

    if (response.error) {
      if (
        response.message_code == "user-cu-not-authoridez2" ||
        response.message_code == "err-usher2"
      ) {
        setInsufficientPermissions(true);
      }
      return;
    }

    let sorteos_ = response.data;
    //console.log("sorteos_");
    //console.log(sorteos_);
    setSorteos(sorteos_);
    setSelectedTipoSorteo(sorteos_[0]);
    setSorteoBig({ ...sorteoBig, id_tipo_sorteo: sorteos_[0]?.id_tipo_sorteo ?? null });
  }
  async function setExpedientesData(params) {
    const response = await ExpedienteService.getExpediente(params);
    //console.log("got expedientes");
    //console.log(response);
    setExpedientes(response.data);
  }
  const observacionesHandler = (event) => {
    //console.log("observacionesHandler");
    setObservaciones(event.target.value);
    setSorteoBig({ ...sorteoBig, observaciones: event.target.value });
  };
  function prepareCaratulaForAvatar(caratula) {
    let new_string = "";
    let caratula_arr = caratula.split(" ");
    if (caratula_arr.length > 1) {
      new_string = caratula_arr[0] + " " + caratula_arr[1];
      return new_string;
    } else {
      return caratula[0] + " " + caratula[0];
    }
  }
  useEffect(() => {
    setOficinasData({});
    setSorteosData({ filters: { habilitado: true, grupo: "regular" } });
    setExpedientesData({ sortOrder: "DESC", filters: { habilitado: true } });
  }, []);
  // useEffect(() => {
  //   sorteos.forEach((sorteo) => {
  //     //will set unipersonal by default at first load of sorteos, should not change twice
  //     if (sorteo.descripcion.split(" ")[1] === "UNIPERSONAL") {
  //       //console.log("setting id_tipo_sorteo");
  //       //console.log(sorteo);
  //       setSorteoBig({ ...sorteoBig, id_tipo_sorteo: sorteo.id });
  //     }
  //   });
  // }, [sorteos]);

  function handleDisplayConfirmSorteo() {
    //console.log("confirmarSorteo");
    setDisplayConfirmSorteo(true);
  }

  async function sortear() {
    //console.log("sorteando");
    //console.log(sorteoBig);

    if (!sorteoBig.id_expediente) {
      setMessage({
        severity: "error",
        messageText: "Debe seleccionar un expediente.",
      });
      setShowMessage(true);
      return;
    }
    if (!sorteoBig.id_tipo_sorteo) {
      setMessage({
        severity: "error",
        messageText: "Debe seleccionar un tipo de sorteo.",
      });
      setShowMessage(true);
      return;
    }
    // if (observaciones.length > 400) {
    //   setMessage({
    //     severity: "error",
    //     messageText: "Las observaciones no pueden superar los 400 caracteres.",
    //   });
    //   setShowMessage(true);
    //   return;
    // }

    setCrearButtonDisabled(true);

    const response = await SorteoService.createSorteo(sorteoBig);
    //console.log("*******************response*************************");
    //console.log(response);
    setTimeout(() => {
      setCrearButtonDisabled(false);
    }, 2500);

    if (response.error) {
      setMessage({
        severity: "error",
        messageText: response.data.human_message,
      });
      setShowMessage(true);
      return;
    }
    //console.log(response);
    setMessage({
      severity: "success",
      messageText: "Sorteo Creado.",
    });
    setShowMessage(true);
    cleanInputs();
  }

  function cleanInputs() {
    setSelectedExpediente(null);
    setSelectedTipoSorteo(null);
    setObservaciones("");

    setSorteoBig({ ...sorteoBig, id_tipo_sorteo: null, id_expediente: null });
  }

  const handleConfirmarSorteo = () => {
    sortear();
    setDisplayConfirmSorteo(false);
  };
  const handleTipoSorteoSelected = (evt) => {
    //console.log("click");
    //console.log(evt.target.innerText);
    //console.log(sorteos);
    // setSorteoBig({ ...sorteoBig, id_tipo_sorteo: " "});
  };

  function setExpedienteFromRecents(value) {
    //clicked from latest sorteo list
    //console.log("setExpedienteFromRecents");
    //console.log(value);
    expedientes.forEach((expediente) => {
      if (expediente.id === value.id) {
        setSelectedExpediente(expediente);
        setSorteoBig({ ...sorteoBig, id_expediente: value.id });
        //break loop
        return false;
      }
    });
    // setSelectedExpediente(value);
    //setSorteoBig({ ...sorteoBig, id_expediente: value.id });
  }
  //declare a function that receives a string and converts it to all lower case except the first letter
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox pt={6} pb={3} sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={6} sx={{ maxWidth: "1200px" }}> */}
      {/* <Grid item xs={12}> */}
      {insufficientPermissions && <ModalAlert />}
      <Card>
        <MDBox
          mx={2}
          mt={2}
          py={3}
          px={2}
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
        >
          <MDTypography variant="h6" color="white">
            Nuevo Sorteo Regular
          </MDTypography>
        </MDBox>

        <div className="container-sorteos">
          <div style={{ justifyContent: "center" }} className="main">
            <Autocomplete
              id="combo-box-dem2"
              options={tiposSorteos}
              value={selectedTipoSorteo}
              sx={{ width: 550, marginBottom: "15px", marginRight: "15px" }}
              getOptionLabel={(option) => option.descripcion_tipo_sorteo}
              renderInput={(params) => <TextField {...params} label="Tipo de Sorteo" />}
              onChange={(event, newValue) => {
                //console.log("*************");
                //console.log(newValue);
                //console.log("*************");
                if (newValue == null || newValue == undefined) {
                  return;
                } else {
                  //console.log(newValue?.id);
                  //  setSelectedExpediente(newValue||null);
                  setSelectedTipoSorteo(newValue || null);
                  setSorteoBig({ ...sorteoBig, id_tipo_sorteo: newValue?.id_tipo_sorteo });
                }
              }}
            />

            <Autocomplete
              id="combo-box-demo"
              options={expedientes}
              key={expedientes?.id}
              value={selectedExpediente}
              sx={{ width: 550, marginBottom: "15px", marginRight: "15px" }}
              getOptionLabel={(option) => option.caratula}
              renderInput={(params) => <TextField {...params} label="Expedientes" />}
              onChange={(event, newValue) => {
                //console.log("*************");
                //console.log(newValue);
                //console.log("*************");
                if (newValue == null || newValue == undefined) {
                  return;
                } else {
                  setSelectedExpediente(newValue || null);
                  setSorteoBig({ ...sorteoBig, id_expediente: newValue?.id });
                }
              }}
            />

            <TextField
              id="outlined-multiline-static"
              label="Observaciones (limite 400 caracteres)"
              multiline
              sx={{ width: 400 }}
              value={observaciones}
              rows={4}
              onChange={observacionesHandler}
              // sx={{ width: 400 }}
            />
          </div>
          <div className="latest">
            <Typography variant="h6" component="div" gutterBottom>
              Expedientes Recientes
            </Typography>
            {/* <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}> */}
            <List>
              {expedientes?.slice(0, 4).map((expediente) => (
                <ListItemButton
                  alignItems="flex-start"
                  onClick={() => setExpedienteFromRecents(expediente)}
                >
                  <ListItemAvatar>
                    {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                    <Avatar {...stringAvatar(prepareCaratulaForAvatar(expediente.caratula))} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={expediente.caratula}
                    secondary={
                      <>
                        <Typography
                          sx={{ display: "inline" }}
                          component="span"
                          variant="body2"
                          color="text.primary"
                        >
                          Nro. {expediente.numero}
                        </Typography>
                        <div>Creado el: {expediente.fecha_creacion}</div>
                      </>
                    }
                  />
                </ListItemButton>
              ))}
              <Divider variant="inset" component="li" />
            </List>
          </div>
        </div>

        <div className="containerButtons">
          <div className="finalButtons">
            <Button
              sx={{ marginRight: "30px", backgroundColor: "#AED6F1" }}
              variant="contained"
              onClick={handleDisplayConfirmSorteo}
              disabled={crearButtonDisabled}
            >
              Sortear
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ backgroundColor: "#FAD7A0" }}
              onClick={cleanInputs}
            >
              Limpiar
            </Button>
          </div>
        </div>
      </Card>

      {displayConfirmSorteo && (
        <Dialog open onClose={1}>
          <DialogTitle id="form-dialog-title">
            Atención
            <IconButton
              aria-label="close"
              onClick={() => {
                setDisplayConfirmSorteo(false);
              }}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent dividers>
            <div style={{ textAlign: "center" }}>
              <b>Se está por llevar a cabo el sorteo regular del expediente</b>
              <br />
              <br />
              <b>
                <u>Origen</u>
              </b>
              <br />
              {selectedExpediente?.descripcion_origen} <br />
              <b>
                {" "}
                <u>Número</u>
              </b>
              <br />
              {selectedExpediente?.numero}
              <br />
              <b>
                {" "}
                <u>Carátula</u>
              </b>
              <br />
              {selectedExpediente?.caratula}
              <br />
              <b>
                {" "}
                <u>Materia</u>
              </b>
              <br />
              {selectedExpediente?.descripcion_materia}
            </div>

            <div style={{ textAlign: "right", marginTop: "40px" }}>
              <Button
                onClick={handleConfirmarSorteo}
                sx={{ backgroundColor: "#AED6F1" ,marginRight: "30px"}}
                variant="contained"
              >
                Confirmar
              </Button>
              <Button
                onClick={() => {
                  setDisplayConfirmSorteo(false);
                }}
                sx={{ backgroundColor: "#FAD7A0" }}
              >
                Cancelar
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      )}
      <Footer />
      {showMessage && <Message message={message} setShowMessage={setShowMessage} />}
    </DashboardLayout>
  );
}

export default Tables;
