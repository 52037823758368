import Base from "./axiosBase";

const ExpedienteService = {
  createExpediente: async (expediente) => Base.post("/expediente/", expediente),
  deleteExpediente: async (expediente) =>
    Base.delete(`expediente/?id=${expediente.id}&action=${expediente.action}`).then(
      (res) => res.data
    ),
  updateExpediente: async (expediente) =>
    Base.put(`expediente/?id=${expediente.id}`, expediente).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getExpediente: async (params) => {
    // eslint-disable-next-line prettier/prettier

    console.log('en el service');
    console.log(params)
    let url = "";
    if (params.page) {
      url += `&first=${params.page}`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.filters) {
      if (params.filters.caratula) {
        url += `&caratula=${params.filters.caratula}`;
      }
      if (params.filters.numero) {
        url += `&numero=${params.filters.numero}`;
      }
      if (params.filters.materia) {
        url += `&id_materia=${params.filters.materia.id_materia}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }

    if (url !== "") {
      url = `expediente/?id_fuero=${
        localStorage.getItem("id_fuero") + url
      }&id_oficina=${localStorage.getItem("id_oficina")}`;
    } else {
      url = `expediente/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}`;
    }

// if (url !== "") {
    //   url = `expediente/?id_fuero=${localStorage.getItem("id_oficina") + url}`;
    // } else {
    //   url = `expediente/?id_fuero=${localStorage.getItem("id_oficina")}`;
    // }

    return Base.get(url).then((res) => res.data);
  },
};

export default ExpedienteService;
