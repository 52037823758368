import Base from "./axiosBase";

const FueroTipoSorteoService = {
  createSorteo: async (sorteo) => Base.post("/tipo-sorteo/", sorteo),
  deleteSorteo: async (sorteo) =>
    Base.delete(`tipo-sorteo/?id=${sorteo.id}&action=${sorteo.action}`).then((res) => res.data),
  updateSorteo: async (sorteo) => Base.put(`tipo-sorteo/`, sorteo).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    if (localStorage.getItem("id_fuero") == null) {
      alert("debe establecer oficina en la opcion  de administracion=>editar oficina");
    }
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (url !== "") {
      url = `fuero-tipo-sorteo/?id_fuero=${localStorage.getItem("id_fuero") + url}`;
    } else {
      url = `fuero-tipo-sorteo/?id_fuero=${localStorage.getItem("id_fuero")}`;
    }

    if (params.filters) {
      if (params.filters.tiposSorteo) {
        url += `&id_tipo_sorteo=${params.filters.tipoSorteo.id}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }

      if (params.filters.grupo) {
        url += `&tipo_grupo=${params.filters.grupo}`;
      }

      if ("habilitado" in params.filters) {
        if (!params.filters.habilitado) {
          url += `&habilitado=D`;
        } else {
          url += `&habilitado=H`;
        }
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default FueroTipoSorteoService;
