
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import React,{useState} from "react";
import './DialogComponent.css';

function ModalAlert() {
 
  return (
 
    <Dialog
    open={true}
    fullScreen
  >
     <div className="dialog-background">
            <div className="dialog-card">
                <h2>AVISO</h2>
                <p>Su usuario no tiene permisos suficientes para utilizar esta aplicación.</p>
                <p>Por favor contáctese con informática.</p>
                <button onClick={() => console.log('Closed')}>Cerrar</button>
            </div>
        </div>
       </Dialog>
  )

 
    
   
}
export default ModalAlert;
