/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// ##############ICONS IMPORTS################
import JuezService from "services/Juez";
import "global-styles/forms.css";
import SaveIcon from "@mui/icons-material/Save";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import {
  Button,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";

export function AlertDisableLayout( {
    
    crudData,
    updateTableRow,
    setDisplayAlertParent,
    setMessage,
    setShowMessage,
    service,
    tipo,
    datos,
    updateModifiedValues,
    }){

      const fullNameHandler = () => {
      if(tipo === 'Juez' || 'Juez Oficina'){setFullName(crudData.nombre+' '+crudData.apellido)}}
      // servicio
      // datos a mostrar
      // 
      const handleClose = () => {
        // eslint-disable-next-line react/prop-types
        setDisplayAlertParent(false);
        // setDisplayAlertParent(false);
      };


      const tipoComp = tipo || "";
      const datoAlerta = datos || "";
      const isHabilitado = crudData.data.habilitado || "";
      const id = crudData.data.id || "";
      // const service = new Function(`${tipoComp}Service.delete${tipoComp}`)
      const deleteHandler = () => {
            // setLoading(true);
            console.log(crudData)
            service({
              action: isHabilitado ? "disable" : "enable",
              id: id,
            })
              .then((response) => {
                console.log(response)
                // if (response == undefined) {
                //           //console.log("undefined");
                //           //temp workaround to find bug                          
                //           setShowMessage(true);
                //           setDisplayAlertParent(false);
                //           updateTableRow(updateModifiedValues(crudData.data, "delete"));
                //           return;
                //         }
                if (response.error || response == undefined) {
                  setMessage({
                    severity: "error",
                    messageText: response.human_message,
                  });
                  setShowMessage(true);
                  setDisplayAlertParent(false);
                  return;
                }
                //console.log("disabled OK");
                //console.log(response);
                setMessage({
                  severity: "success",
                  messageText: isHabilitado ?  `${tipoComp} fue deshabilitado` : `${tipoComp} fue habilitado`,
                });
                setShowMessage(true);
        
                // if(!isHabilitado){ 
                //   settriggerHabilitarParaEditar(false);
                //   setIsHabilitado(true);     
                // }else{
                //   setDisplayDialogParent(false);
                // }
                
               
                crudData.data.habilitado = !isHabilitado;
                setDisplayAlertParent(false);
                //setDisplayDialogParent(false);
                updateTableRow(crudData.data);
               
                


                



              })
              .catch((e) => {
                // setLoading(false);
                //console.log(e);
              });
          };

    
    return(
        <>
            <div className="containercrud">
                <div className="nombrecrud">
                    <Typography
                    variant="h8"
                    component="div"
                    gutterBottom
                    color={isHabilitado ? "red" : "green"}
                    >
                    {isHabilitado
                        ?`¿Está seguro que desea deshabilitar?`
                        : `¿Está seguro que desea habilitar?`}
                    </Typography>
                </div>
                <div className="fueroName">
                    <Typography variant="h8" component="div" gutterBottom>
                    {datoAlerta}
                    </Typography>
                </div>
                <div className="botonesaccionescrud">
                    <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setDisplayAlertParent(false);
                    }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    >
                    Cancelar
                    </MDButton>
                    <MDButton
                    onClick={deleteHandler}
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                    >
                    Confirmar
                    </MDButton>
                </div>
            </div>
</>)
    }
   
      