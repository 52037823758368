import Base from "./axiosBase";
import { formatDate } from "#services/utils";
const SorteoQuery = {
  // eslint-disable-next-line arrow-body-style
  getSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    console.log("params in service");
    console.log(params);
    //debugger;
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.export) {
      url += `&export=${params.export}`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (params.filters) {
      if (params.filters.fechaDesde) {
        url += `&fecha_sorteo_desde=${formatDate(params.filters.fechaDesde)}`;
      }

      if (params.filters.fechaHasta) {
        url += `&fecha_sorteo_hasta=${formatDate(params.filters.fechaHasta)}`;
      }

      if (params.filters.tipoSorteo) {
        url += `&id_tipo_sorteo=${params.filters.tipoSorteo.id_tipo_sorteo}`;
      }
      if (params.filters.estado) {
        url += `&estado=${params.filters.estado}`;
      }
      if (params.filters.materia) {
        url += `&id_materia=${params.filters.materia.id_materia}`;
      }
      if (params.filters.shortId) {
        url += `&codigo=${params.filters.shortId}`;
      }
      if (params.filters.origen) {
        url += `&id_origen=${params.filters.origen.id}`;
      }
      if (params.filters.numeroExpediente) {
        url += `&numero_expediente=${params.filters.numeroExpediente}`;
      }
      if (params.filters.caratula) {
        url += `&caratula_expediente=${params.filters.caratula}`;
      }
      if (params.filters.juez) {
        url += `&id_juez=${params.filters.juez.id}`;
      }
      if (params.filters.descripcionFuncion) {
        url += `&funcion=${params.filters.descripcionFuncion}`;
      }
      if (params.filters.userName) {
        url += `&username=${params.sortOrder}`;
      }
      if (params.filters.tipoAgrupadorSorteo) {
        url += `&tipo_grupo=${params.filters.tipoAgrupadorSorteo}`;
      }
    }
    if (url !== "") {
      url = `sorteo-query/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}${url}`;
    } else {
      url = `sorteo-query/?id_fuero=${localStorage.getItem(
        "id_fuero"
      )}&id_oficina=${localStorage.getItem("id_oficina")}`;
    }

    return Base.get(url).then((res) => res.data);
  },
};

export default SorteoQuery;
