import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TipoExclusionService from "services/Tipo-Exclusion";
import SorteoService from "services/Sorteo";
import SREService from "services/SRE";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  selectedRowDetailDialogData,
  setShowDialogDelete,
  showDialogDelete,
  setMessage,
  setShowMessage,
}) {
  const idExclusion = selectedRowDetailDialogData.id_sorteo || "";

  const [deletionComments, setDeletionComments] = useState("");

  const deleteExclusionHandler = () => {
    //console.log("whole data");
    //console.log(selectedRowDetailDialogData);
    // setLoading(true);
    // //console.log({
    //   id: idExclusion,
    //   accion: "deshabilitado desde front",
    // });

    if (selectedRowDetailDialogData.descripcion_tipo_sorteo !== "RESOLUCION DE EXCLUSION") {
      SorteoService.deleteSorteo({
        action: "disable",
        id: idExclusion,
        motivo: deletionComments,
        id_oficina: localStorage.getItem("id_oficina"),
        id_usuario: localStorage.getItem("id_usuario"),
      })
        .then((response) => {
          if (response.error) {
            setMessage({
              severity: "error",
              messageText: response.human_message,
            });
            setShowMessage(true);
            return;
          }
          //console.log(response.data);
          setMessage({
            severity: "success",
          messageText: "Sorteo anulado.",
          });
          setShowMessage(true);
          setShowDialogDelete(false);
        })
        .catch((e) => {
          // setLoading(false);
          //console.log(e);
        });
    }

    if (selectedRowDetailDialogData.descripcion_tipo_sorteo === "RESOLUCION DE EXCLUSION") {
      SREService.deleteSorteo({
        action: "disable",
        id: idExclusion,
        motivo: deletionComments,
        id_oficina: localStorage.getItem("id_oficina"),
        id_usuario: localStorage.getItem("id_usuario"),
      })
        .then((response) => {
          if (response.error) {
            setMessage({
              severity: "error",
              messageText: response.human_message,
            });
            setShowMessage(true);
            return;
          }
          //console.log(response.data);
          setMessage({
            severity: "success",
            messageText: "Sorteo anulado.",
          });
          setShowMessage(true);
          setShowDialogDelete(false);
        })
        .catch((e) => {
          // setLoading(false);
          //console.log(e);
        });
    }
  };
  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setShowDialogDelete(false);
  };

  return (
    <StyledEngineProvider injectFirst>
      <Dialog open onClose={handleClose} fullWidth={true}>
        <DialogTitle id="form-dialog-title">
          Anular Sorteo
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers>
          <>
            <div className="observacionescrud">
              <TextField
                id="observaciones"
                variant="outlined"
                size="small"
                name="observaciones"
                placeholder="Motivo de anulación"
                // label={`Motivo (${1} caracteres restantes)`}
                sx={{ width: "100%", pb: 2 }}
                multiline
                rows={5}
                value={deletionComments}
                onChange={(e) => {
                  setDeletionComments(e.target.value);
                }}
              />
            </div>

            <div className="botonDeshabilitar">
              <MDButton
                size="small"
                color={true ? "error" : "error"}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={deleteExclusionHandler}
              >
                {true ? "Anular" : "Habilitar"}
              </MDButton>
            </div>

            <div className="botonesaccionescrud">
              <MDButton
                sx={{ marginRight: "5px" }}
                size="small"
                color="secondary"
                onClick={handleClose}
                variant="contained"
                startIcon={<SaveIcon />}
              >
                Cancelar
              </MDButton>
            </div>
          </>
        </DialogContent>
      </Dialog>
    </StyledEngineProvider>
  );
}

export default Crud;
