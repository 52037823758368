/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable */
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import Keycloak from "keycloak-js";
import jwt_decode from "jwt-decode";
const axios = require('axios');
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import 'dayjs/locale/es';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
// Material Dashboard 2 React Context Provider
import Base from "./services/axiosBase";
import { MaterialUIControllerProvider } from "context";

let initOptions = {
  url: process.env.REACT_APP_KC_URL,
  realm: "devops",
  clientId: "sorteos-corte",
  onLoad: "login-required",
  checkLoginIframe: false,
};
let keycloak = Keycloak(initOptions);


keycloak
  .init({ onLoad: initOptions.onLoad })
  .success((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.info("Authenticated");
    }
    
    localStorage.setItem("token", keycloak.token);
    localStorage.setItem("refresh-token", keycloak.refreshToken);

    var token = keycloak.token;
    var decoded = jwt_decode(token);

    localStorage.setItem("HumanName", decoded.name);
    localStorage.setItem("userName", decoded.email.split("@")[0]);
  
    // axios.baseURL = "https://dev-backend.pjm.gob.ar/ged-backend-cloud-master/";
   
    Base.defaults.headers.common.Authorization = "Bearer " + keycloak.token;

    // axios.interceptors.response.use(
    //   function (response) {
    //     // Do something with response data

    //     if (response.data.code === "401") {
    //       //should be checking this on frontend not back
    //       // alert("ERROR")
    //       // alert(response.data.status.error)
    //     }
    //     return response;
    //   },
    //   function (error) {
    //     // Do something with response error
    //     return Promise.reject(error);
    //   }
    // );

    ReactDOM.render(
      <BrowserRouter>
       
        <MaterialUIControllerProvider>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
          <App />
          </LocalizationProvider>
        </MaterialUIControllerProvider>
       
      </BrowserRouter>,
      document.getElementById("root")
    );

    setTimeout(() => {
      keycloak
        .updateToken(70)
        .success((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
            axios.defaults.headers.common.Authorization = "Bearer " + refreshed;
            localStorage.setItem("token", refreshed);
            
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .error(() => {
          console.error("Failed to refresh token");
        });
    }, 60000); 
  })
  .error(() => {
    console.error("Authenticated Failed");
  });
