import Base from "./axiosBase";

const FueroExclusionService = {
  createFueroExclusion: async (juez) =>
    Base.post("/fuero-tipo-exclusion/", juez).then((res) => res),
  deleteFueroExclusion: async (juez) =>
    Base.delete(`fuero-tipo-exclusion/?id=${juez.id}&action=${juez.action}`).then(
      (res) => res.data
    ),
  updateFueroExclusion: async (juez) =>
    Base.put(`fuero-tipo-exclusion/?id=${juez.id}`, juez).then((res) => res.data),

  // eslint-disable-next-line arrow-body-style
  getFueroExclusion: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (url !== "") {
      url = `fuero-tipo-exclusion/?id_fuero=${localStorage.getItem("id_fuero") + url}`;
    } else {
      url = `fuero-tipo-exclusion/?id_fuero=${localStorage.getItem("id_fuero")}`;
    }

    if (params.filters) {
      if (params.filters.tipoExclusion) {
        url += `&id_tipo_exclusion=${params.filters.tipoExclusion.id}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (params.filters.tipo) {
        url += `&tipo=${params.filters.tipo}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => {
      res.data.data.forEach((j) => {
        j.fullName = `${j.nombre} ${j.apellido}`;
      });
      // res.data.map((juez) => {
      //   juez.fullName = `${juez.nombre} ${juez.apellido}`;
      // });
      return res.data;
    });
  },
};

export default FueroExclusionService;
