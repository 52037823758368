import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OrigenService from "services/Origen";
import FueroService from "services/Fuero";

// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idOrigen = crudData.data.id || "";
  const [nombreOrigen, setNombreOrigen] = useState(crudData.data.descripcion_origen || "");
  const [observacionesOrigen, setObservacionesOrigen] = useState(
    crudData.data.observaciones_origen || ""
  );
  const [observacionesFuero, setObservacionesFuero] = useState(
    crudData.data.observaciones_fuero || ""
  );
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [fueros, setFueros] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState(null);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);
  
  useEffect( () => {
  handleButtonGuardar(); 
  }, [nombreOrigen, selectedFuero, observacionesOrigen])

  function handleButtonGuardar(){
  setButtonGuardarOk(false);
  if((nombreOrigen === "" ||  selectedFuero === null) ){
  setButtonGuardarOk(false) 
  }else if(
    nombreOrigen !== crudData.data.descripcion_origen || observacionesOrigen !== crudData.data.observaciones_origen 
    || selectedFuero.id !== crudData.data.id_fuero ){
  setButtonGuardarOk(true)
  }
  }

  useEffect(() => {
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
      setSelectedFuero(response.data.find((fuero) => fuero.id === crudData.data.id_fuero));
    });
  }, []);

  function updateModifiedValues(row, action) {
    crudData.data.nombre = row.nombre;
    crudData.data.observaciones_origen = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const handleObservacionesOrigen = (e) => {
    setObservacionesOrigen(e.target.value);
  };

  const handleFuero = (e) => {
    //console.log("settingselected fuero value");

    console.log(e);
    setSelectedFuero(fueros.find((fuero) => fuero.id === e.id));
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNombreOrigen = (e) => {
    setNombreOrigen(e.target.value);
  };

  const newOrigenHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    //console.log(selectedFuero);
    OrigenService.createOrigen({
      descripcion: nombreOrigen,
      id_fuero: selectedFuero.id,
      observaciones: observacionesOrigen,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Origen creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateOrigenHandler = () => {
    // setLoading(true);
    const updateObj = {
      id_fuero: selectedFuero.id || crudData.data.id_fuero,
      observaciones: observacionesOrigen,
      descripcion: nombreOrigen,
      id: idOrigen,
    };
    OrigenService.updateOrigen(updateObj)
      .then((response) => {
        //console.log(response);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        setMessage({
          severity: "success",
          messageText: "Origen actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const deleteOrigenHandler = () => {
    // setLoading(true);
    OrigenService.deleteOrigen({
      action: isHabilitado ? "disable" : "enable",
      id: idOrigen,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }

        setMessage({
          severity: "success",
          messageText: isHabilitado ? "Origen fue deshabilitado" : "Origen fue habilitado",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(crudData.data, "delete"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    <>
    {/* // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create"
    //         ? "Nueva Origen"
    //         : crudAction === "readonly"
    //         ? "Información de Origen"
    //         : "Editar Origen"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers> */}
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}
                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "400px", pb: 1, marginBottom: "10px" }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    disabled={crudAction === "readonly"}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Nombre del Origen"
                    autoFocus
                    value={nombreOrigen}
                    onChange={handleNombreOrigen}
                  />
                  {/* <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Apellido del Juez"
                    autoFocus
                    value={apellidoJuez}
                    onChange={handleApellidoJuez}
                  /> */}
                </div>

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    <Autocomplete
                      disableClearable
                      id="combo-box-demo"
                      options={fueros.filter((f)=>f.habilitado)}
                      value={selectedFuero}
                      sx={{ width: "100%", pb: 2 }}
                      getOptionLabel={(option) => option.descripcion ?? ""}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        handleFuero(newValue);
                       
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones_origen"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Origen"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesOrigen}
                    onChange={handleObservacionesOrigen}
                  />
                </div>
                {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesFuero}
                    />
                  </div>
                )}
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                                              disabled={!buttonGuardarOk || crudAction == 'readonly'}
                   
                        onClick={crudAction === "create" ? newOrigenHandler : updateOrigenHandler}
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                      Guardar
                    </MDButton>
                    </span>
                    </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <>
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "gr<>een"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Origen?"
                    : "¿Está seguro que desea habilitar el Origen?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreOrigen}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteOrigenHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
            </>
          )} */}

          {/* {true && (<>
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
    {/* //     </DialogContent>
    //   </Dialog>
    // </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
