/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Component imports
// import DetailSorteoCustomDialog from "./DetailSorteoDialog/DetailSorteoCustomDialog";
import MUIDataTable from "mui-datatables";
import "./index.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import WorkIcon from "@mui/icons-material/Work";
import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
  Divider,
} from "@mui/material";
import { Box } from "@mui/system";

// ##############ICONS IMPORTS################
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import RemoveIcon from "@mui/icons-material/Remove";
import Visibility from "@mui/icons-material/Visibility";
// ##############ICONS IMPORTS################
import FueroService from "services/Fuero.js";
import SorteoService from "services/Sorteo.js";
import { truncateString } from "services/utils";
import DetailDataTableExcluidos from "./../DetailTableExcluidos/index.tsx"
import "global-styles/forms.css";

interface LocalFilters {
  nombre: string;
  habilitado: boolean;
}
function DetailDataTable({ idSorteo }) {
  const [tableOptions, setTableOptions] = useState({});
  // const [tableData, setTableData] = useState([]);

  useEffect(() => {
    //console.log("idSorteo");
    //console.log(idSorteo);
  }, [idSorteo]);


    function moveupPresidenteVocal(arr) { //messing around with declaring function ways
      //console.log("its coming as")
      //console.log(arr)
      let arr_vocales=[]
      let all_the_rest=[]
      let presidente=[]
      presidente.push(arr.find((element) => element.funcion === "presidente"));
      arr_vocales=arr.filter((element) => element.funcion === "vocal");
      //filter out the suplentes from the list because its not important and it can get quite a long list sometimes.
     // all_the_rest=arr.filter((element) => element.funcion !== "vocal" && element.funcion !== "presidente" && element.tipo_juez !== "suplente");
      
     
      //console.log("returning ordered array")
      //console.log(presidente.concat(arr_vocales).concat(all_the_rest))
      return presidente.concat(arr_vocales).concat(all_the_rest)
    };
function firstCharactertoUpperCase(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
  const tOptions = {
    storageKey: "table-fueros",
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    count: 7,
    page: 0,
    pagination: false,
    onTableChange: (action, table) => {
      //console.log(action);
      //console.log(table);
      if (action === "changePage") {
        //console.log("change page event");

        // setTableOptions(tOptions);
        async function getData(params) {
          const response = await SorteoService.getSorteo(params);
          //console.log("setting table data detail inner");
          //console.log(response);
          setTableOptions((prev) => ({
            ...prev,
            count: response.count,
            page: table.page,
            data:response.data || [],
          }));
        }
        getData({
          page: table.page * table.rowsPerPage || 0,
          rowsPerPage: table.rowsPerPage || 10,
          sortOrder: "DESC",
          idSorteo: idSorteo,
        });
      }
    },
    data: [],
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };

  const [displayFilters, setDisplayFilters] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [crudAction, setCrudAction] = useState("create");
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [showDetailDialog, setShowDetailDialog] = useState(false);
  const [selectedRowDetailDialogData, setSelectedRowDetailDialogData] = useState(null);
  const [crudData, setCrudData] = useState({});
  const [localSorteoEstadoList, setLocalSorteoEstadoList] = useState([]);
  const [filtersState, setFiltersState] = useState<LocalFilters>({
    nombre: "",
    habilitado: true,
  });

  const columns = [
    {
      label: "Nombre",
      name: "descripcion_oficina",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              {tableOptions.data[dataIndex].nombre_juez.toUpperCase() +
                " " +
                tableOptions.data[dataIndex].apellido_juez.toUpperCase()}{" "}
            </div>
            <div className="titularTableSubTitle">
              {firstCharactertoUpperCase(tableOptions.data[dataIndex].tipo_juez) ?? ""}
             
            </div>
            {tableOptions.data[dataIndex].id_tipo_exclusion !== "" ? " (Excluido) " : ""}
          </span>
        ),
      },
    },
    {
      label: "Funcion",
      name: "descripcion_oficina",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>{tableOptions.data[dataIndex].funcion.toUpperCase()}</div>

            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {/* Orden: {tableOptions.data[dataIndex].orden_sorteo} */}
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Fecha",
      name: "fecha_sorteo",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].fecha_sorteo_juez}
              </p>
            </div>
            {/* <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].hora_sorteo_juez}
              </p>
            </div> */}
          </span>
        ),
        display: "false",
      },
    },
    { label: "Fecha de Creacion", name: "fecha_creacion", options: { display: "false" } },
    { label: "Habilitado", name: "habilitado", options: { display: "false" } },
    // {
    //   label: "Ver",
    //   name: "acciones",
    //   options: {
    //     filter: false,
    //     sort: false,
    //     download: false,
    //     print: false,
    //     viewColumns: false,
    //     // eslint-disable-next-line react/no-unstable-nested-components
    //     customBodyRenderLite: (dataIndex, rowIndex) => (
    //       <Box>
    //         {/* <Tooltip title="Editar">
    //           <IconButton
    //             aria-label="Editar"
    //             onClick={() => {
    //               //   //console.log(tableOptions.data[dataIndex]);
    //               //   setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
    //               //   setCrudAction("update");
    //               //   setDisplayDialog(true);
    //             }}
    //           >
    //             <Icon>edit</Icon>
    //           </IconButton>
    //         </Tooltip> */}

    //         <Tooltip title="Ver mas">
    //           <IconButton
    //             aria-label="Visibility"
    //             onClick={() => {
    //               setShowDetailDialog(true);

    //               setSelectedRowDetailDialogData(tableOptions.data[dataIndex]);
    //             }}
    //           >
    //             <Icon>visibility</Icon>
    //           </IconButton>
    //         </Tooltip>
    //       </Box>
    //     ),
    //   },
    // },
  ];

  useEffect(() => {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await SorteoService.getSorteo(params);
      //console.log("setting table data detail inner2");
      //console.log(response);
      setLocalSorteoEstadoList(response.sorteo_estado_list.reverse());

      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: moveupPresidenteVocal(response.sorteo_juez_list || []),
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      idSorteo: idSorteo,
    });
  }, []);

  //   useEffect(() => {
  //     //console.log("child filters changed");
  //     //console.log(filtersState);
  //     if (filtersState.nombre == "" && filtersState.habilitado == true) { //if clean filters is applied from child component
  //       applyFiltersHandler();
  //     }
  //   }, [filtersState]);

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................
  const buscarHandler = () => {
    // setDisplayFilters((currentValue) => !currentValue);
  };
  const applyFiltersHandler = () => {
    //childFilters apply button clicked.

    setTableOptions(tOptions);
    // async function getData(params) {
    //   const response = await FueroService.getFuero(params);
    //   //console.log("setting table data");
    //   //console.log(response);
    //   setTableOptions((prev) => ({
    //     ...prev,
    //     count: response.count,
    //     data: response.data || [],
    //   }));
    // }
    // getData({
    //   page: tableOptions.page || 0,
    //   rowsPerPage: tableOptions.rowsPerPage || 10,
    //   sortOrder: "ASC",
    //   filters: filtersState,
    // });
  };

  const updateTableRow = (data) => {
    //console.log("updating table row");
    // //console.log(data);
    // //console.log(data.id);
    // //console.log("*****************");
    // setTableOptions((prev) => ({
    //   ...prev,
    //   data: prev.data.map((row) => {
    //     if (row.id === data.id) {
    //       //console.log("found row");
    //       //console.log(row);
    //       //console.log("*******************  ");
    //       //console.log("returning...");
    //       //console.log(data);
    //       return data;
    //     }
    //     return row;
    //   }),
    // }));
  };

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................

  return (
    <>
      <Card sx={{ height: "100%" }}>
        {localSorteoEstadoList?.map((estado,index) => {
          return (
            <div className="listEstadosSorteo">
              <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
                <ListItem>
                  <ListItemAvatar>
                    <Avatar sx={{  bgcolor: index==0 ? "#d1b500" : "lightGray" }}>{estado.estado.substring(0,1).toUpperCase()}</Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={estado.estado.toUpperCase()} secondary={estado.fecha_sorteo_estado + " "+estado.hora_sorteo_estado } />
                </ListItem>
              </List>
            </div>
          );
        })}


     <DetailDataTableExcluidos idSorteo={idSorteo} />

     <Divider></Divider>

        <MUIDataTable
          title="Jueces Sorteados"
          data={tableOptions.data}
          columns={columns}
          options={tableOptions}
        />
        {/* <Fuero
        color="dark"
        icon="priority_high"
        name="Webflow"
        description="26 March 2020, at 05:00 AM"
        value="Pending"
      /> */}
      </Card>
      {/* {displayDialog && (
        <Crud
          setDisplayDialogParent={setDisplayDialog}
          setMessage={setMessage}
          setShowMessage={setShowMessage}
          crudAction={crudAction}
          crudData={crudData}
          updateTableRow={updateTableRow}
        />
      )} */}
      {/* {showMessage && <Message message={message} setShowMessage={setShowMessage} />} */}
      {/* {showDetailDialog &&  <DetailSorteoCustomDialog selectedRowDetailDialogData={selectedRowDetailDialogData} setShowDetailDialog={setShowDetailDialog} showDetailDialog={showDetailDialog}/>}  */}
    </>
  );
}

export default DetailDataTable;
