import Base from "./axiosBase";

const FueroSorteoService = {
  createFueroSorteo: async (fueroSorteo) =>
    Base.post("/fuero-tipo-sorteo/", fueroSorteo).then((res) => res),
  deleteFueroSorteo: async (fueroSorteo) =>
    Base.delete(
      `fuero-tipo-sorteo/?id=${fueroSorteo.id}&action=${fueroSorteo.action}`
    ).then((res) => res.data),
  updateFueroSorteo: async (fueroSorteo) =>
    Base.put(`fuero-tipo-sorteo/?id=${fueroSorteo.id}`, fueroSorteo).then((res) => res.data),

  // eslint-disable-next-line arrow-body-style
  getFueroSorteo: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (url !== "") {
      url = `fuero-tipo-sorteo/?${url}`;
    } else {
      url = "fuero-tipo-sorteo/";
    }
    if (params.filters) {
      if (params.filters.tipoSorteo) {
        url += `&id_tipo_sorteo=${params.filters.tipoSorteo.id}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => {
      res.data.data.forEach((j) => {
        j.fullName = `${j.nombre} ${j.apellido}`;
      });
      // res.data.map((juez) => {
      //   juez.fullName = `${juez.nombre} ${juez.apellido}`;
      // });
      return res.data;
    });
  },
};

export default FueroSorteoService;
