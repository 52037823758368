import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  List,
  ListItemIcon,
  ListItemButton,
  ListItemText,
  Collapse,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OficinaService from "services/Oficina";
import FueroService from "services/Fuero";
import { ConstructionOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { ExpandLess, ExpandMore, StarBorder, StarBorderOutlined } from "@mui/icons-material";


// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idOficina = crudData.data.id || "";
  const idFuero = crudData.data.id_fuero || "";
  const [nombreOficina, setNombreOficina] = useState(crudData.data.descripcion_oficina || "");
  const [nombreFuero, setNombreFuero] = useState(crudData.data.descripcion_fuero || "");
  const [apellidoJuez, setApellidoJuez] = useState(crudData.data.apellido || "");
  const [observacionesOficina, setObservacionesOficina] = useState(
    crudData.data.observaciones_oficina || ""
  );
  const [observacionesFuero, setObservacionesFuero] = useState(
    crudData.data.observaciones_fuero || ""
  );
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [fueros, setFueros] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState(null);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);

  useEffect(() => {
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
      setSelectedFuero(response.data.find((fuero) => fuero.id === crudData.data.id_fuero) || null);
    });
  }, []);

  useEffect( () => {
    console.log('useEffect called');
    setButtonGuardarOk(false);
    if(selectedFuero === null ||
      selectedFuero === undefined || 
      nombreOficina === ""){
        setButtonGuardarOk(false);
    }else if(
      nombreOficina !== crudData.data.descripcion_oficina || 
      observacionesOficina !== crudData.data.observaciones_oficina || 
      selectedFuero.descripcion !== crudData.data.descripcion_fuero ){
        setButtonGuardarOk(true)
    }
  }, [nombreOficina, observacionesOficina, selectedFuero]);
  

  function updateModifiedValues(row, action) {
    crudData.data.nombre = row.nombre;
    crudData.data.apellido = row.apellido;
    crudData.data.observaciones = row.observaciones;    
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }
  
  const handleObservacionesOficina = (e) => {
    setObservacionesOficina(e.target.value);
  };

  const handleFuero = (e) => {
    if(e !== undefined){setSelectedFuero(fueros.find((fuero) => fuero === e));    
    }
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNombreOficina = (e) => {
    setNombreOficina(e.target.value);    

  };

  const newOficinaHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    //console.log(selectedFuero);
    OficinaService.createOficina({
      descripcion: nombreOficina,
      id_fuero: selectedFuero.id,
      observaciones: observacionesOficina,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "FueroService creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateOficinaHandler = () => {
    // setLoading(true);
    const updateObj = {
      descripcion: nombreOficina,
      observaciones: observacionesOficina,
      id_fuero: selectedFuero.id,
      id: idOficina,
    };
    //console.log("************");
    //console.log(updateObj);
    OficinaService.updateOficina(updateObj)
      .then((response) => {
        //console.log("************");
        //console.log(response);
        //console.log("************");
        if (response.error) {
        
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Oficina actualizada correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteOficinaHandler = () => {
  //   // setLoading(true);
  //   OficinaService.deleteOficina({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idOficina,
  //   })
  //     .then((response) => {
  //       //console.log("_______________________________");
  //       //console.log(response);

  //       if (response == undefined) {
  //         //console.log("undefined");
  //         //temp workaround to find bug
  //         setDisplayDialogParent(false);
  //         return;
  //       }
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Juez fue deshabilitado" : "Juez fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e ?? "error");
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

 

  

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create"
    //         ? "Nueva Oficina"
    //         : crudAction === "readonly"
    //         ? "Información de OFicina"
    //         : "Editar Oficina"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
    <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}{!triggerDeshabilitarConfirmation && (
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              {/* <MDButton
                size="small"
                color={"primary"}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={() => {
                  localStorage.setItem("id_oficina", idOficina);
                  localStorage.setItem("nombre_oficina", nombreOficina);
                  localStorage.setItem("nombre_fuero", nombreFuero);
                  localStorage.setItem("id_fuero", idFuero)
                }}
              >
                Establecer Oficina
              </MDButton> */}
              
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}
                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "400px", pb: 1, marginBottom: "10px" }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    disabled={crudAction === "readonly"}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Nombre de la Oficina"
                    autoFocus
                    value={nombreOficina}
                    onChange={handleNombreOficina}
                  />
                  {/* <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Apellido del Juez"
                    autoFocus
                    value={apellidoJuez}
                    onChange={handleApellidoJuez}
                  /> */}
                </div>

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={fueros.filter((f)=>f.habilitado)}
                      sx={{ width: '100%', marginBottom: "15px" }}
                      value={selectedFuero}
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        handleFuero(newValue);
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Oficina"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesOficina}
                    onChange={handleObservacionesOficina}
                  />
                </div>
                {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesFuero}
                    />
                  </div>
                )}
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                        onClick={crudAction === "create" ? newOficinaHandler : updateOficinaHandler}
                        size="small"
                        color="primary"
                        variant="contained"
                        disabled={buttonGuardarOk === false}
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </MDButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar la Oficina?"
                    : "¿Está seguro que desea habilitar la Oficina?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreOficina}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteOficinaHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
      
    </>
  );
}

export default Crud;

