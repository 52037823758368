/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import MUIDataTable from "mui-datatables";

// ##############ICONS IMPORTS################
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";
import SearchIcon from "@mui/icons-material/Search";
import AssessmentIcon from "@mui/icons-material/Assessment";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";

import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import OrigenService from "#services/Origen.js";
import MateriaService from "services/Materias";
import { getEstadosSorteo } from "#services/utils.js";
import TipoSorteoService from "#services/Tipo-Sorteo";
import JuezService from "#services/Juez.js";
import { StyledEngineProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
  Card,
  Autocomplete,
} from "@mui/material";
import { Box } from "@mui/system";

import "./filters.css";

function Filters({ filtersState, setFiltersState, applyFiltersHandler, exportCSVHandler }) {
  // const [localFilters, setLocalFilters] = useState<LocalFilters>(filtersState);
  const [origenes, setOrigenes] = useState([]);
  const [estados, setEstados] = useState([]);
  const [materias, setMaterias] = useState([]);
  const [tiposSorteo, setTiposSorteo] = useState([]);
  const [jueces, setJueces] = useState([]);
  const [tiposAgrupadoresSorteo, setTiposAgrupadoresSorteo] = useState(["todos","regular","resolucion-exclusion"]);
  const [descripcionFunciones, setDescripcionFunciones] = useState(["presidente","vocal","todos"]);
  const [isTipoAgrupadorTodos, setIsTipoAgrupadorTodos] = useState(true);
  useEffect(() => {
    setEstados(getEstadosSorteo());
    async function getData(params) {
      const response_tipo_sorteo = await TipoSorteoService.getSorteo(params);
      //console.log("setting table dataaaaaaa");
      //console.log(response_tipo_sorteo);
      setTiposSorteo(response_tipo_sorteo.data);
      const response_origenes = await OrigenService.getOrigen(params);
      //console.log("setting table datafilters table");
      //console.log(response_origenes);
      setOrigenes(response_origenes.data);

      const response_juez = await JuezService.getJuez(params);
      //console.log("setting table datafilters table");
      //console.log(response_juez);
      setJueces(response_juez.data);

      const response_materias = await MateriaService.getOficinaMateria(params);
      console.log("setting table datafilters table");
      console.log(response_materias);
      
      setMaterias(response_materias.data);
    }
    getData({
      sortOrder: "ASC",
    });
  }, []);

  useEffect(() => {
  
    if (filtersState.tipoAgrupadorSorteo === "todos"||filtersState.tipoAgrupadorSorteo === null) {
      setFiltersState(prev => ({ ...prev, tipoSorteo: null ,estado: null}));
      setIsTipoAgrupadorTodos(true);


      
    }else{
       setIsTipoAgrupadorTodos(false);
      }
    //reload tipo sorteo data with the selected

    async function getData(params) {
    
      const response_tipo_sorteo = await TipoSorteoService.getSorteoPorFuero(params);
      
      setTiposSorteo(response_tipo_sorteo.data);
     
    }
    console.log("use Effect tipoAgrupadorSorteo")
    getData({
      sortOrder: "ASC",
   
      filters:{tipoAgrupadorSorteo:filtersState.tipoAgrupadorSorteo,habilitado: true}
    });

  }, [filtersState.tipoAgrupadorSorteo]);
  return (
    <StyledEngineProvider injectFirst>
      <div>
        <div className="containerTitle">
          {/* If you want to display the title, uncomment this */}
          {/* <MDTypography variant="h6" fontWeight="medium">
            Búsqueda de Fueros {filtersState.nombre}
          </MDTypography> */}
        </div>
        <Divider variant="middle" className="thickDivider" />
  


        <div id="cssportal-grid">
	<div id="div1">
    
  <DatePicker
                  sx={{ width: '280px', marginBottom: "15px" }}
              label="Fecha Desde"
              value={filtersState.fechaDesde ?? null}
              onChange={(event, newValue) => {
             
                setFiltersState((prev) => ({ ...prev, fechaDesde: dayjs(event) }));
              }}
            />
            
          </div>
	<div id="div2"> 
   


  <Autocomplete
              id="combo-box-estado"
              options={estados}
              disabled={isTipoAgrupadorTodos}
              value={filtersState.estado|| null}
              sx={{ width: '280px', marginBottom: "15px" }}
              getOptionLabel={(option) => option ?? ""}
              renderInput={(params) => <TextField {...params} label="Estado" />}
              onChange={(event, newValue) => {
                setFiltersState((prev) => ({ ...prev, estado: newValue }));
              }}
            />



   </div>
	<div id="div3"> 
  
  

  <TextField
              sx={{ width: "280px", marginBottom: "15px" }}
              id="outlined-basic"
              label="Numero de Expediente"
              variant="outlined"
              value={filtersState.numeroExpediente ?? ""}
              onChange={(event) => {
                setFiltersState((prev) => ({ ...prev, numeroExpediente: event.target.value }));
              }}
            />
       


  </div>
	<div id="div4">
  
  <DatePicker
                 sx={{ width: '280px', marginBottom: "15px" }}
              label="Fecha Hasta"
              value={filtersState.fechaHasta ?? null}
              onChange={(event, newValue) => {
             
                setFiltersState((prev) => ({ ...prev, fechaHasta: dayjs(event) }));
              }}
            />
            
           </div>
	<div id="div5">  
  
 
 
  <Autocomplete
              id="combo-box-materia"
              options={materias}
              value={filtersState.materia || null}
              sx={{ width: '280px', marginBottom: "15px" }}
              getOptionLabel={(option) => option.descripcion_materia ?? ""}
              renderInput={(params) => <TextField {...params} label="Materia" />}
              onChange={(event, newValue) => {
               setFiltersState((prev) => ({ ...prev, materia: newValue }));
              }}
            /> 
  
  
  </div>
	<div id="div6">
    
            

  <TextField
           sx={{ width: '400px', marginBottom: "15px" }}
            
            id="outlined-basic"
            label="Carátula de Expediente"
            variant="outlined"
            value={filtersState.caratula ?? ""}
            onChange={(event) => {
              setFiltersState((prev) => ({ ...prev, caratula: event.target.value }));
            }}
           
          />


            </div>
	<div id="div7"><Autocomplete
              id="combo-box-tipoSorteo"
              options={tiposAgrupadoresSorteo}
              value={filtersState.tipoAgrupadorSorteo || "todos"}
              sx={{ width: '280px', marginBottom: "15px" }}
              getOptionLabel={(option) => option ?? ""}
              renderInput={(params) => <TextField {...params} label="Tipo Agrupador" />}
              onChange={(event, newValue) => {
                setFiltersState((prev) => ({ ...prev, tipoAgrupadorSorteo: newValue }));
              
              }}
            /> </div>
	<div id="div8">
  

  <TextField
              sx={{ width: "280px", marginBottom: "15px" }}
              id="outlined-basic"
              label="Codigo de Sorteo"
              variant="outlined"
              value={filtersState.shortId ?? ""}
              onChange={(event) => {
                setFiltersState((prev) => ({ ...prev, shortId: event.target.value }));
              }}
            />

            
  </div>
	<div id="div9">  
  
            
 <Autocomplete
id="combo-box-estado"
options={jueces}
value={filtersState.juez|| null}
sx={{ width: "280px", marginBottom: "15px" }}
getOptionLabel={(option) => option.nombre +" "+ option.apellido }
renderInput={(params) => <TextField {...params} label="Juez" />}
onChange={(event, newValue) => {
  console.log("value juez");
  console.log(newValue);
  setFiltersState((prev) => ({ ...prev, juez: newValue }));
  if (newValue!=null){
   //enable the funcion
   // setIsFuncionDisabled(false);
  }else{
   // setIsFuncionDisabled(true);
    //clean selected value of funcion
    setFiltersState((prev) => ({ ...prev, descripcionFuncion: "todos" }));
  }

}}
/> 
          
          </div>
	<div id="div10">



  <Autocomplete
              id="combo-box-tipoSorteoAgrupador"
              options={tiposSorteo}
              value={filtersState.tipoSorteo || null}
              disabled={isTipoAgrupadorTodos}
              sx={{ width: '280px', marginBottom: "15px" }}
              getOptionLabel={(option) => option.descripcion_tipo_sorteo ?? ""}
              renderInput={(params) => <TextField {...params} label="Tipo Sorteo" />}
              onChange={(event, newValue) => {
                setFiltersState((prev) => ({ ...prev, tipoSorteo: newValue }));
              }}
            />

     

    
            </div>
	<div id="div11">






    <Autocomplete
 id="combo-box-origen"
 options={origenes}
 value={filtersState.origen}
 sx={{ width: '280px', marginBottom: "15px" }}
 getOptionLabel={(option) => option.descripcion_origen ?? ""}
 renderInput={(params) => <TextField {...params} label="Origen" />}
 onChange={(event, newValue) => {
   setFiltersState((prev) => ({ ...prev, origen: newValue }));
 }}
 /> 


            
            
            
            
            </div>
	<div id="div12">
   


<Autocomplete
               id="combo-box-funcion"
               options={descripcionFunciones}
               value={filtersState.descripcionFuncion|| null}
               sx={{ width: "280px", marginBottom: "15px" }}
              getOptionLabel={(option) =>option ?? ""}
              renderInput={(params) => <TextField {...params} label="Funcion" />}
               onChange={(event, newValue) => {
             
               
                 setFiltersState((prev) => ({ ...prev, descripcionFuncion: newValue }));
                

            }}
            />
            </div>
	<div id="div13"></div>
	<div id="div14"></div>
	<div id="div15">
    
  <Button color="warning" style={{marginRight:"10px"}} variant="contained" startIcon={<CheckIcon/>} onClick={applyFiltersHandler}>
        Aplicar
      </Button>


     <Button
        color="warning"
        variant="contained"
        style={{marginRight:"10px"}}
        startIcon={<SearchIcon />}
        onClick={() => {
          setFiltersState({
            tipoSorteo: "",
            habilitado: true,
            fechaDesde: null,
            fechaHasta: null,
            origen: "",
            estado: "",
            caratula: "",
            anulado: false,
            tipoAgrupadorSorteo: "todos",
          });
        }}
      >
        Limpiar
      </Button>
      <Button color="warning" variant="contained" startIcon={<AssessmentIcon/>} onClick={exportCSVHandler}>
        Exportar CSV
      </Button>
      </div>
</div>



  
        <Divider variant="middle" className="thickDivider" />

      </div>
    </StyledEngineProvider>
  );
  
  
}

export default Filters;









