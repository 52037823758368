import { getPickersFadeTransitionGroupUtilityClass } from "@mui/x-date-pickers/DateCalendar/pickersFadeTransitionGroupClasses"

export function validateFormData(obj: any,selectedTipoSorteo:any) {

    let input = obj
    let errorsHuman=""
    let errorsRobot=[]
    let purged_jueces_elegibles_list=input?.juecesPurgedList?.filter(i=>i.checked)??[]

    //validate if tipo de sorteo is selected
    //console.log("selected tipo sorteo error validation")
    //console.log(selectedTipoSorteo)
    //console.log("selected obj jueces array validation")
    //console.log(input)

    if(selectedTipoSorteo==null){
         
       
        errorsRobot.push("tipo_sorteo")
    }
    if(input==null){
         
       
        errorsRobot.push("juez_checked_required")
    }
  

    //validate that there is at least one judge to be excluded in the list
    if (purged_jueces_elegibles_list.length<1)
    {
        errorsHuman=errorsHuman+"Debe elegir por lo menos un Juez para excluir."
        errorsRobot.push("juez_checked_required")
    }
  
    for(let a=0;a<purged_jueces_elegibles_list.length;a++)
    {  
        //console.log("input jueces elegibles")
        //console.log(purged_jueces_elegibles_list)
        let j=purged_jueces_elegibles_list[a]
        //console.log("suspicious value")
        //console.log(j)
        if (!("tipoExclusionSelected" in j))
        {
            //console.log("tipo exclusion not in j")
            errorsHuman=errorsHuman+" Falta elegir el tipo de exclusion en el/los jueces seleccionados";
            errorsRobot.push("juez_tipo_exclusion_required")
            
            
        }
        if (j.observaciones==null || j.observaciones.length<3)
        {
            errorsHuman=errorsHuman+" Falta poner alguna observacion en el/los jueces seleccionados";
            errorsRobot.push("observaciones_required")
        ;
            
        }
    }

   
    return {"errorsHuman":errorsHuman,"errorsRobot":errorsRobot};

}

