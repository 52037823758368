import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MateriaService from "services/Materias";
import OficinaService from "services/Oficina";
import FueroService from "services/Fuero";
import TipoSorteoService from "services/Tipo-Sorteo";
import FueroSorteoService from "services/Fuero-Sorteo";

// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idFueroSorteo = crudData.data.id || "";
  const idFuero = crudData.data.id_fuero || "";
  const idMateria = crudData.data.id_materia || "";
  const idTipoSorteo = crudData.data.id_tipo_sorteo || "";
  const [nombreMateria, setNombreMateria] = useState(crudData.data.descripcion_materia || "");
  const [observacionesOficinaMateria, setObservacionesOficinaMateria] = useState(
    crudData.data.observaciones || ""
  );

  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [tiposSorteo, setTiposSorteo] = useState([]);
  const [selectedTipoSorteo, setSelectedTipoSorteo] = useState(null);
  const [fueros, setFueros] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState("");

  const loadTiposSorteo = () => {
    TipoSorteoService.getSorteo({params:[]})

      .then((response) => {
        console.log(response)
        setTiposSorteo(response.data);
        let array_temp=[]
        array_temp=response.data.filter((item)=>item.habilitado==true)
        console.log("*************************************************")
        console.log(array_temp)
        console.log("*************************************************")
        setSelectedTipoSorteo(response.data.find((t) => t.id === idTipoSorteo && t.habilitado) || null);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  useEffect(() => {
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
      setSelectedFuero(response.data.find((fuero) => fuero.id === idFuero) || "");
    });

    loadTiposSorteo();
  }, []);

  function updateModifiedValues(row, action) {
    crudData.data.id_fuero = row.id_fuero;
    crudData.data.id_materia = row.id_materia;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    //inquiry about what to show when its disabled/deleted./

    return crudData.data;
  }

  const handleObservacionesOficinaMateria = (e) => {
    setObservacionesOficinaMateria(e.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNombreMateria = (e) => {
    setNombreMateria(e.target.value);
  };

  const newFueroSorteoHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    FueroSorteoService.createFueroSorteo({
      id_fuero: selectedFuero.id,
      id_tipo_sorteo: selectedTipoSorteo.id,
      observaciones: observacionesOficinaMateria,
    })
      .then((response) => {
        //console.log(response.data);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        setMessage({
          severity: "success",
          messageText: "Fuero Tipo creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateFueroSorteoHandler = () => {
    // setLoading(true);
    const updateObj = {
      id: idFueroSorteo,
      id_tipo_sorteo: selectedTipoSorteo.id ?? idTipoSorteo,
      observaciones: observacionesOficinaMateria,
      id_fuero: selectedFuero.id ?? crudData.data.id_fuero,
    };
    FueroSorteoService.updateFueroSorteo(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Fuero Tipo actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteFueroTipoSorteoHandler = () => {
  //   // setLoading(true);
  //   FueroSorteoService.deleteFueroSorteo({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idFueroSorteo,
  //   })
  //     .then((response) => {
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado
  //           ? "Tipo sorteo fue deshabilitado"
  //           : "Tipo sorteo fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create"
    //         ? "Nuevo Fuero-Tipo de Sorteo"
    //         : crudAction === "readonly"
    //         ? "Información de Fuero-Tipo de Sorteo"
    //         : "Editar Fuero-Tipo de Sorteo"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
    <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={fueros.filter((item)=>item.habilitado==true)}
                      isOptionEqualToValue={(option, selectedFuero) => option.selectedFuero === selectedFuero.selectedFuero}
                      value={selectedFuero || ""}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion || ""}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        setSelectedFuero(newValue);
                      }}
                    />

                    <Autocomplete
                      id="combo-box-demo"
                      options={tiposSorteo.filter((item)=>item.habilitado==true)}
                      isOptionEqualToValue={(option, selectedTipoSorteo) => option.selectedTipoSorteo === selectedFuero.selectedTipoSorteo}
                      value={selectedTipoSorteo || ""}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion || ""}
                      renderInput={(params) => <TextField {...params} label="Tipo de Sorteo" />}
                      onChange={(event, newValue) => {
                        setSelectedTipoSorteo(newValue);
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Fuero-tipo Sorteo"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesOficinaMateria}
                    onChange={handleObservacionesOficinaMateria}
                  />
                </div>
                {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesOficinaMateria}
                    />
                  </div>
                )}

                {/* reeplace this for a filter and exclude the disabled ones */}

                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <MDButton
                    onClick={
                      crudAction === "create" ? newFueroSorteoHandler : updateFueroSorteoHandler
                    }
                    size="small"
                    color="primary"
                    variant="contained"
                    disabled={crudAction === "readonly"}
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </MDButton>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar la Fuero-tipo Sorteo?"
                    : "¿Está seguro que desea habilitar la Fuero-tipo Sorteo?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {descripcio}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteFueroTipoSorteoHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
    </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
