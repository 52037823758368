import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import FueroService from "services/Fuero";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  // crudData is an array with the data of the fuero to be edited 0 is descripcion
  const [nombreFuero, setNombreFuero] = useState(crudData.data.descripcion || "");
  const [observacionesFuero, setObservacionesFuero] = useState(crudData.data.observaciones || "");
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const idFuero = crudData.data.id || "";
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] = useState(MAX_LENGTH_OBSERVACIONES);
  const [buttonOk, setButtonOk] = useState(false)


  function updateModifiedValues(row, action) {
    crudData.data.descripcion = row.descripcion;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const buttonGuardarHandler = (e) => {
    if((e.target.value == "" || crudData.data.descripcion===e.target.value) || (crudData.data.observaciones===e.target.value)){
      setButtonOk(false)
    }else{ setButtonOk(true)}

  }

  const handleNombreFuero = (e) => {   
      setNombreFuero(e.target.value);
      if(e.target.value == "" || crudData.data.descripcion===e.target.value){
        setButtonOk(false)
      }else{ setButtonOk(true)}
  
      // buttonGuardarHandler(e);
  };
  const handleObservacionesFuero = (e) => {
    setCharactersLeftObservaciones(MAX_LENGTH_OBSERVACIONES + 1 - e.target.value.length);
    if (e.target.value.length <= MAX_LENGTH_OBSERVACIONES) {
      setObservacionesFuero(e.target.value);
    }
    // buttonGuardarHandler(e);

    if(crudData.data.observaciones===e.target.value && crudData.data.observaciones !== ""){
      setButtonOk(false)
    }else{ setButtonOk(true)}

  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const newFueroHandler = () => {
    // setLoading(true);
    FueroService.createFuero({
      descripcion: nombreFuero,
      observaciones: observacionesFuero,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Fuero creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateFueroHandler = () => {

    // setLoading(true);
    const updateObj = {
      descripcion: nombreFuero,
      observaciones: observacionesFuero,
      id: idFuero,
    };
    FueroService.updateFuero(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setButtonOk(false);
          setShowMessage(true);
          return;
        }
        setMessage({
          severity: "success",
          messageText: "Fuero actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteFueroHandler = () => {
  //   // setLoading(true);
  //   FueroService.deleteFuero({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idFuero,
  //   })
  //     .then((response) => {
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.data.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Fuero fue deshabilitado" : "Fuero fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create" ? "Nuevo Fuero" : "Editar Fuero"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
          <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Nombre del Fuero"
                    autoFocus
                    value={nombreFuero}
                    onChange={handleNombreFuero}
                  />
                </div>
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ width: "100%", pb: 2 }}
                    multiline
                    rows={5}
                    maxLength={400}
                    value={observacionesFuero}
                    onChange={handleObservacionesFuero}
                  />
                </div>
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>

                  
                  <Tooltip title={ !buttonOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                        disabled={!buttonOk || crudAction === "readonly"} 
                        onClick={crudAction === "create" ? newFueroHandler : updateFueroHandler}
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </MDButton>
                    </span>
                  </Tooltip>
 
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Fuero?"
                    : "¿Está seguro que desea habilitar el Fuero?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreFuero}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteFueroHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
    </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
