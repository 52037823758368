import Base from "./axiosBase";

const MateriaService = {
  createMateria: async (materia) => Base.post("/materia/", materia),
  deleteMateria: async (materia) =>
    Base.delete(
      `materia/?id=${materia.id}&action=${materia.action}`
    ).then((res) => res.data),
  updateMateria: async (materia) => Base.put(`materia/?id=${materia.id}`, materia).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getMateria: async (params) => {
    // eslint-disable-next-line prettier/prettier
   
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (params.filters.habilitado) {
        url += `&habilitado=H`;
      } else {
        url += `&habilitado=D`;
      }
    }





    if (url !== "") {
      url = `materia/?${url}&id_oficina=${localStorage.getItem("id_oficina")}`;
    } else {
      url = `materia/?id_oficina=${localStorage.getItem("id_oficina")}`;
    }
    return Base.get(url).then((res) => res.data);
  },


  getOficinaMateria: async (params) => {
    // eslint-disable-next-line prettier/prettier
   
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=H`;
      } else {
        url += `&habilitado=D`;
      }
    }





    if (url !== "") {
      url = `oficina-materia/?${url}&id_oficina=${localStorage.getItem("id_oficina")}`;
    } else {
      url = `oficina-materia/?id_oficina=${localStorage.getItem("id_oficina")}`;
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default MateriaService;
