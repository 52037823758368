import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MateriaService from "services/Materias";
import FueroService from "services/Fuero";

// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idMateria = crudData.data.id || "";
  const [nombreMateria, setNombreMateria] = useState(crudData.data.descripcion_materia || "");
  const [nombreCortoMateria, setNombreCortoMateria] = useState(crudData.data.descripcion_corta_materia || "");
  const [observacionesMateria, setObservacionesMateria] = useState(
    crudData.data.observaciones_materia || ""
  );
  const [observacionesFuero, setObservacionesFuero] = useState(
    crudData.data.observaciones_fuero || ""
  );
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [fueros, setFueros] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState(null);

  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);
  
  useEffect( () => {
  handleButtonGuardar(); 
  }, [nombreMateria, nombreCortoMateria, selectedFuero, observacionesMateria])

  function handleButtonGuardar(){
  setButtonGuardarOk(false);
  if((nombreMateria === "" || nombreCortoMateria === "" || selectedFuero === null) ){
  setButtonGuardarOk(false) 
  }else if(
    nombreMateria !== crudData.data.descripcion_materia || observacionesMateria !== crudData.data.observaciones_materia 
    || nombreCortoMateria !== crudData.data.descripcion_corta_materia || selectedFuero.id !== crudData.data.id_fuero ){
  setButtonGuardarOk(true)
  }
  }
  

  useEffect(() => {
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
      console.log(response.data)
      setSelectedFuero(response.data.find((fuero) => fuero.id === crudData.data.id_fuero) || null);
    });
    //console.log("*********************");
    //console.log(crudData.data);
    //console.log("*********************");
  }, []);

  function updateModifiedValues(row, action) {
    crudData.data.nombre = row.nombre;
    crudData.data.apellido = row.apellido;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const handleObservacionesMateria = (e) => {
    setObservacionesMateria(e.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNombreMateria = (e) => {
    setNombreMateria(e.target.value);
  };

  const handleNombreCortoMateria = (e) => {
    setNombreCortoMateria(e.target.value);
  };

  const newMateriaHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    MateriaService.createMateria({
      descripcion: nombreMateria,
      descripcion_corta: nombreCortoMateria,
      id_fuero: selectedFuero.id,
      observaciones: observacionesMateria,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "FueroService creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateMateriaHandler = () => {
    // setLoading(true);
    const updateObj = {
      descripcion: nombreMateria,
      descripcion_corta: nombreCortoMateria,
      id_fuero: selectedFuero.id || crudData.data.id_fuero,
      observaciones: observacionesMateria,
      id: idMateria,
    };
    MateriaService.updateMateria(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Materia actualizada correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const deleteMateriaHandler = () => {
    // setLoading(true);
    MateriaService.deleteMateria({
      action: isHabilitado ? "disable" : "enable",
      id: idMateria,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("disabled OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: isHabilitado ? "Materia deshabilitada" : "Materia habilitada",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(crudData.data, "delete"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create"
    //         ? "Nueva Materia"
    //         : crudAction === "readonly"
    //         ? "Información de Materia"
    //         : "Editar Materia"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
    <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}
                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "100%", pb: 2 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    disabled={crudAction === "readonly"}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Nombre de la Materia"
                    autoFocus
                    value={nombreMateria}
                    onChange={handleNombreMateria}
                  />
                  <TextField
                    sx={{ width: "100%", pb: 2 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion_corta"
                    label="Descripción corta de la Materia"
                    value={nombreCortoMateria}
                    onChange={handleNombreCortoMateria}
                  />
                </div>

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    <Autocomplete
                      id="combo-box-demo"
                      options={fueros.filter((f)=>f.habilitado)}
                      sx={{ width: "100%", pb: 2 }}
                      value={selectedFuero}
                      //value={crudData.data.id_fuero}
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        if (newValue !== selectedFuero)
                          setSelectedFuero(newValue);
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Materia"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesMateria}
                    onChange={handleObservacionesMateria}
                  />
                </div>
                {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesFuero}
                    />
                  </div>
                )}
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                    <MDButton
                                          disabled={!buttonGuardarOk || crudAction == 'readonly'}

                    onClick={crudAction === "create" ? newMateriaHandler : updateMateriaHandler}
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </MDButton>
                  </span>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <>
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar la Materia?"
                    : "¿Está seguro que desea habilitar la Materia?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreMateria}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteMateriaHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
            </>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
    </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
