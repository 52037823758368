import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import MateriaService from "services/Materias";
import OficinaService from "services/Oficina";
import FueroService from "services/Fuero";
import OficinaMateriaService from "services/Oficina-Materia";

// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idOficinaMateria = crudData.data.id || "";
  const idMateria = crudData.data.id_materia || "";
  const idOficina = crudData.data.id_oficina || "";
  const idFuero = crudData.data.id_fuero || "";
  const [nombreMateria, setNombreMateria] = useState(crudData.data.descripcion_materia || "");
  const [observacionesOficinaMateria, setObservacionesOficinaMateria] = useState(
    crudData.data.observaciones || ""
  );

  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [isHabilitadoFuero, setIsHabilitadoFuero] = React.useState(
    crudData.data.habilitado_fuero || false
  );
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [materias, setMaterias] = useState([]);
  const [oficinas, setOficinas] = useState([]);
  const [selectedOficina, setSelectedOficina] = useState(null);
  const [selectedMateria, setSelectedMateria] = useState(null);
  const [fueros, setFueros] = useState([]);
  const [selectedFuero, setSelectedFuero] = useState(null);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);

  useEffect( () => {
    handleButtonGuardar(); 
    return () => {
      setButtonGuardarOk(false);
          };
  }, [selectedMateria, selectedOficina, observacionesOficinaMateria, selectedFuero])
  
  function handleButtonGuardar(){
  setButtonGuardarOk(false);
  if((selectedMateria === null || selectedOficina === null || selectedFuero == null) ){
  setButtonGuardarOk(false) 
  }else if(selectedMateria?.id !== idMateria || observacionesOficinaMateria !== crudData.data.observaciones || selectedOficina?.id !== idOficina || selectedFuero?.id !== idFuero){
  setButtonGuardarOk(true)
  }
  }

  useEffect(() => {
    // OficinaService.getOficina({}).then((response) => {
    //   setOficinas(response.data);
    // });
    // MateriaService.getMateria({}).then((response) => {
    //   setMaterias(response.data);
    // });
    //console.log("crudDatacrudDatacrudDatacrudData");
    //console.log(crudData);
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
    });
    if (idMateria !== "") {
      loadMaterias();
    }
    if (idOficina !== "") {
      loadOficinas();
    }
  }, []);

  const loadOficinas = (id_fuero) => {
    OficinaService.getOficina({ id_fuero: id_fuero })

      .then((response) => {
        setOficinas(response.data);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  const loadMaterias = (id_fuero) => {
    MateriaService.getMateria({ id_fuero: id_fuero })

      .then((response) => {
        setMaterias(response.data);
      })
      .catch((e) => {
        //console.log(e);
      });
  };

  function updateModifiedValues(row, action) {
    crudData.data.id_fuero = row.id_fuero;
    crudData.data.id_materia = row.id_materia;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.observaciones = row.id.observaciones;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    //inquiry about what to show when its disabled/deleted./

    return crudData.data;
  }

  const handleObservacionesOficinaMateria = (e) => {
    setObservacionesOficinaMateria(e.target.value);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNombreMateria = (e) => {
    setNombreMateria(e.target.value);
  };

  const newOficinaMateriaHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    OficinaMateriaService.createOficinaMateria({
      id_materia: selectedMateria?.id || idMateria,
      id_oficina: selectedOficina?.id || idOficina,
      observaciones: observacionesOficinaMateria,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.data.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Oficina-Materia creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateMateriaHandler = () => {
    // setLoading(true);
    const updateObj = {
      id_materia: selectedMateria?.id || idMateria,
      id_oficina: selectedOficina?.id || idOficina,
      id_fuero: selectedFuero?.id || idFuero,
      observaciones: observacionesOficinaMateria,
      id: idOficinaMateria,
    };
    OficinaMateriaService.updateOficinaMateria(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Oficina-Materia actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteMateriaHandler = () => {
  //   // setLoading(true);
  //   OficinaMateriaService.deleteOficinaMateria({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idOficinaMateria,
  //   })
  //     .then((response) => {
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Oficina Materia fue deshabilitado" : "Oficina Materia fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    <>
     {/* <StyledEngineProvider injectFirst>
       <Dialog open onClose={handleClose}>
         <DialogTitle id="form-dialog-title">
        {crudAction === "create"
    //         ? "Nueva Oficina-Materia"
    //         : crudAction === "readonly"
    //         ? "Información de Oficina-Materia"
    //         : "Editar Oficina-Materia"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers> */}
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && crudAction !== "readonly" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                {(crudAction === "create" || crudAction === "update") && (
                  <div>
                    <Autocomplete
                      disableClearable
                      id="combo-box-demo"
                      options={fueros}
                      value={fueros.find((fuero) => fuero.id === crudData.data.id_fuero) || selectedFuero}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion}
                      renderInput={(params) => <TextField {...params} label="Fuero" />}
                      onChange={(event, newValue) => {
                        setSelectedFuero(newValue);
                        loadMaterias(newValue.id);
                        loadOficinas(newValue.id);
                      }}
                    />

                    <Autocomplete
                      disableClearable
                      id="combo-box-demo"
                      options={materias}
                      value={materias.find((materia) => materia.id === idMateria) || selectedMateria}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion_materia}
                      renderInput={(params) => <TextField {...params} label="Materia" />}
                      onChange={(event, newValue) => {
                        setSelectedMateria(newValue);
                      }}
                    />

                    <Autocomplete
                      disableClearable
                      id="combo-box-demo"
                      options={oficinas}
                      value={oficinas.find((oficina) => oficina.id === idOficina) || selectedOficina}
                      sx={{ width: 350, marginBottom: "15px" }}
                      getOptionLabel={(option) => option.descripcion_oficina}
                      renderInput={(params) => <TextField {...params} label="Oficina" />}
                      onChange={(event, newValue) => {
                        setSelectedOficina(newValue);
                      }}
                    />
                  </div>
                )}
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label="Observaciones Oficina-Materia"
                    sx={{ width: "100%", pb: 2 }}
                    disabled={crudAction === "readonly"}
                    multiline
                    rows={5}
                    value={observacionesOficinaMateria}
                    onChange={handleObservacionesOficinaMateria}
                  />
                </div>
                {crudAction === "readonly" && (
                  <div className="observacionescrud">
                    <TextField
                      id="observaciones_fuero"
                      variant="outlined"
                      size="small"
                      name="observaciones"
                      label="Observaciones Fuero"
                      sx={{ width: "100%", pb: 2 }}
                      disabled={crudAction === "readonly"}
                      multiline
                      rows={5}
                      value={observacionesOficinaMateria}
                    />
                  </div>
                )}

                {/* reeplace this for a filter and exclude the disabled ones */}

                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                    <MDButton
                                            disabled={!buttonGuardarOk || crudAction == 'readonly'}

                      onClick={
                        crudAction === "create" ? newOficinaMateriaHandler : updateMateriaHandler
                      }
                      size="small"
                      color="primary"
                      variant="contained"
                      startIcon={<SaveIcon />}
                    >
                    Guardar
                  </MDButton>
                  </span>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <>
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar la Oficina-Materia?"
                    : "¿Está seguro que desea habilitar la Oficina-Materia?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreMateria}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteMateriaHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
            </>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
    {/* //     </DialogContent>
    //   </Dialog>
    // </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
