import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import StyledEngineProvider from "@mui/styled-engine/StyledEngineProvider";
import MUIDataTable from "mui-datatables";
import { set } from "lodash";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import SorteoQty from "services/sorteoQty.js";
import dayjs from "dayjs";
const TablaJuecesMaterias = () => {
  const [tableOptions, setTableOptions] = useState({});
  const [filters, setFilters] = useState({
    tipoGrupo: "regular",
    funcion: "presidente",
    fechaDesde: null,
    fechaHasta: null,
  });

  const tOptions = {
    storageKey: "table-fueros",
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    pagination: false,

    // count: 7,
    // page: 0,
    onTableChange: (action, table) => {
      //console.log(action);
      //console.log(table);
      if (action === "changePage") {
        console.log("change page event");

        setTableOptions(tOptions);
        async function getData(params) {
          const response = await SorteoQty.getSorteo(params);
          //console.log("setting table data");
          //console.log(response);

          //   if (response.error) {
          //     if (
          //       response.message_code == "user-cu-not-authoridez" ||
          //       response.message_code == "err-usher"
          //     ) {
          //       setInsufficientPermissions(true);
          //     }
          //     return;
          //   }
          setTableOptions((prev) => ({
            ...prev,
            // count: response.count,
            // page: table.page,
            data: response.data || [],
          }));
        }
        getData({
          page: table.page * table.rowsPerPage || 0,
          rowsPerPage: table.rowsPerPage || 10,
          sortOrder: "DESC",
        });
      }
    },
    data: [],
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };

  const columns = [
    {
      label: "Juez",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {tableOptions.data[dataIndex].apellido +
                  " " +
                  tableOptions.data[dataIndex].nombre || ""}
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Civil",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find(
                    (i) => i.descripcion == "CIVIL COMERCIAL MINAS"
                  )?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Comp. Originaria",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find(
                    (i) => i.descripcion == "COMPETENCIA ORIGINARIA"
                  )?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Contravencional",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find(
                    (i) => i.descripcion == "CONTRAVENCIONAL"
                  )?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Familia",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find(
                    (i) => i.descripcion == "FAMILIA Y VIOLENCIA FAMILIAR"
                  )?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Laboral",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find((i) => i.descripcion == "LABORAL")
                  ?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Paz",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find((i) => i.descripcion == "PAZ LETRADO")
                  ?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Penal",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find((i) => i.descripcion == "PENAL")
                  ?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Penal Juvenil",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find(
                    (i) => i.descripcion == "PENAL JUVENIL"
                  )?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Tributario",
      name: "nombre",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find((i) => i.descripcion == "TRIBUTARIO")?.cantidad || 0
                }
              </p>
            </div>
          </span>
        ),
      },
    },
    {
      label: "Total",
      name: "total",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            <div>
              <p style={{ color: "#888", fontWeight: "bold" }}>
                {
                  tableOptions.data[dataIndex].materias.find((i) => i.descripcion == "TOTAL")?.cantidad || 0
                }
              </p>
            </div>
          </span> 
        ),
      },
    },

    { label: "Fecha de Creacion", name: "fecha_creacion", options: { display: "false" } },
    { label: "Habilitado", name: "habilitado", options: { display: "false" } },
  ];
  const handleChangeFuncion = (event) => {
    console.log(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      funcion: event.target.value,
    }));
  };

  const handleChangeTipo = (event) => {
    console.log(event.target.value);
    setFilters((prevFilters) => ({
      ...prevFilters,
      tipoGrupo: event.target.value,
    }));
  };

  const clearFilters = () => {
    setFilters({
      tipoGrupo: "todo",
      funcion: "presidente",
      fechaDesde: null,
      fechaHasta: null,
    });
    applyFilters();
  };
  const applyFilters = () => {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await SorteoQty.getSorteo(params);
      setTableOptions((prev) => ({
        ...prev,
        // count: response.count,
        // page: tOptions.page,
        data: response.data || [],
      }));
    }
    getData({
      page: tOptions.page * tOptions.rowsPerPage || 0,
      rowsPerPage: tOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filters,
    });
  };
  useEffect(() => {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await SorteoQty.getSorteo(params);
      setTableOptions((prev) => ({
        ...prev,
        // count: response.count,
        // page: tOptions.page,
        data: response.data || [],
      }));
    }
    getData({
      page: tOptions.page * tOptions.rowsPerPage || 0,
      rowsPerPage: tOptions.rowsPerPage || 10,
      sortOrder: "DESC",
      filters: filters,
    });
  }, []);

  useEffect(() => {
    if (
     filters.funcion=="presidente"&&
     filters.tipoGrupo=="todo"&&
     filters.fechaDesde==null&&
     filters.fechaHasta==null
      
    ) {
      applyFilters();
    }
  }, [filters]);

  return (
    <StyledEngineProvider injectFirst>
      <Accordion style={{ width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          {/* Your summary content */}
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="presidente"
                  name="radio-buttons-group"
                  value={filters.funcion}
                  onChange={handleChangeFuncion}
                >
                  <FormControlLabel value="presidente" control={<Radio />} label="Presidente" />
                  <FormControlLabel value="vocal" control={<Radio />} label="Vocal" />
                
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <FormControl>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label2"
                  defaultValue="todo"
                  name="radio-buttons-group2"
                  value={filters.tipoGrupo}
                  onChange={handleChangeTipo}
                >
                  <FormControlLabel value="regular" control={<Radio />} label="Regular" />
                  <FormControlLabel
                    value="resolucion-exclusion"
                    control={<Radio />}
                    label="Resolucion-Exclusion"
                  />
                  <FormControlLabel value="todo" control={<Radio />} label="todos" />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4} md={3} lg={2}>
              <DatePicker
                sx={{ width: "100%", marginBottom: "15px" }}
                label="Fecha Desde"
                value={filters.fechaDesde ?? null}
                onChange={(event, newValue) => {
                  setFilters((prev) => ({ ...prev, fechaDesde: dayjs(event) }));
                }}
              />
              <DatePicker
                sx={{ width: "100%" }}
                label="Fecha Hasta"
                value={filters.fechaHasta ?? null}
                onChange={(event, newValue) => {
                  setFilters((prev) => ({ ...prev, fechaHasta: dayjs(event) }));
                }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="right">
              <div style={{ marginBottom: "20px" }}>
                <Button
                  onClick={clearFilters}
                  variant="contained"
                  color="warning"
                  style={{ marginRight: "20px" }}
                >
                  Limpiar
                </Button>

                  
  {/* <Button color="warning" style={{marginRight:"10px"}} variant="contained" startIcon={<CheckIcon/>} onClick={applyFiltersHandler}>
        Aplicar
      </Button> */}
                <Button color="warning" onClick={applyFilters} variant="contained">
                  Aplicar
                </Button>
              </div>
            </Grid>
          </Grid>
          <MUIDataTable
            title=""
            data={tableOptions.data}
            columns={columns}
            options={tableOptions}
          />
        </AccordionDetails>
        {/* Your AccordionActions */}
      </Accordion>
    </StyledEngineProvider>
  );
};

export default TablaJuecesMaterias;
