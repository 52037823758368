/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import Card from "@mui/material/Card";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { StyledEngineProvider } from "@mui/styled-engine";
// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Main";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import Statsservice from "services/Stats";
import { useEffect, useState } from "react";
import TablaJuecesMaterias from "./components/TablaJuecesMaterias/TablaJuecesMaterias.tsx";

function Dashboard() {
  const [monthlyStats, setMonthlyStats] = useState({
    labels: ["En", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    datasets: {
      label: "Sorteos de causas por mes",
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    },
  });
  const [stats, setStats] = useState({
    judges: [],
    dispersion_judges: {
      max: 0,
      min: 0,
      diff: 0,
    },
    year: {
      count: 0,
    },
    cantidad_sr_year: {
      count: 0,
    },
    day: {
      count: 0,
    },
  });

  const [barChartData, setBarChartData] = useState({
    labels: ["ADARO", "DAY", "GARAY", "GOMEZ", "LLORENTE", "PALERMO", "VALERIO"],
    datasets: { label: "Sorteos", data: [30, 20, 10, 22, 50, 10, 40] },
  });

  const [percentageSubLabel, setPercentageSubLabel] = useState({
    color: "success",
    amount: "0%",
    label: "diferencia entre la distribución de causas",
  });
  const [currentMonthCount, setCurrentMonthCount] = useState(0);
  const [currentTime, setCurrentTime] = useState("");
  const [selectedStatsType, setSelectedStatsType] = useState("presidentes_sr");
  function findMAXandMIN(judges) {
    if (judges === undefined) return { max: 0, min: 0 };

    if (judges.length === 0) return { max: 0, min: 0 };

    let max = judges[0].count;
    let min = judges[0].count;
    judges.forEach((judge) => {
      if (judge.count > max) {
        max = judge.count;
      }
      if (judge.count < min) {
        min = judge.count;
      }
    });
    const diff = max - min;
    return { max, min, diff };
  }

  function assembleBarChartData(res) {
    const labels = [];
    const data = [];
    if (selectedStatsType == "presidentes_sr") {
      res.sorteos_sr_presidente?.forEach((judge) => {
        labels.push(judge.apellido);
        data.push(judge.count);
      });
    }
    if (selectedStatsType == "vocales_sr") {
      res.sorteos_sr_vocal.forEach((judge) => {
        labels.push(judge.apellido);
        data.push(judge.count);
      });
    }
    if (selectedStatsType == "presidentes_sre") {
      res.sorteos_sre_presidente.forEach((judge) => {
        labels.push(judge.apellido);
        data.push(judge.count);
      });
    }
    if (selectedStatsType == "vocales_sre") {
      res.sorteos_sre_vocal.forEach((judge) => {
        labels.push(judge.apellido);
        data.push(judge.count);
      });
    }

    // judges.forEach((judge) => {
    //   labels.push(judge.apellido);
    //   data.push(judge.count);
    // });

    return { labels, data };
  }

  function assembleMonthChartData(months) {
    const data = [];
    const labels = [];
    const monthsDescription = [
      { name: "En", index: 0 },
      { name: "Feb", index: 1 },
      { name: "Mar", index: 2 },
      { name: "Abr", index: 3 },
      { name: "May", index: 4 },
      { name: "Jun", index: 5 },
      { name: "Jul", index: 6 },
      { name: "Ago", index: 7 },
      { name: "Sep", index: 8 },
      { name: "Oct", index: 9 },
      { name: "Nov", index: 10 },
      { name: "Dic", index: 11 },
    ]; 
    //console.log(months);
    monthsDescription.forEach((m) => {
      const month = months.find((target) => m.index + 1 === target.id);
      if (month) {
        labels.push(m.name);
        data.push(month.count);
      } else {
        labels.push(m.name);
        data.push(0);
      }
    });
    return { labels, data };
  }

  function getCurrentMonthCount(months) {
    const monthCount = months.find((t) => t.id == new Date().getMonth() + 1)?.count || 0;
    console.log("returning month count");
    console.log(monthCount);
    return monthCount;
  }

  function getTodaysCount(stats) {
    if (selectedStatsType == "presidentes_sr" || selectedStatsType == "vocales_sr") {
      return stats.cantidad_sr_today;
    }
    if (selectedStatsType == "presidentes_sre" || selectedStatsType == "vocales_sre") {
      return stats.cantidad_sre_today;
    }
    return 0;
  }

  function getCurrentTime() {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  }

  function loadStats() {
    Statsservice.getOrigen({})
      .then((res) => res.data)
      .then((res) => {
        //console.log("from dasboard component");
        console.log(res);

        //will set the judges based on the selected type and overwrite the judges array
        if (selectedStatsType !== "todos") {
          if (selectedStatsType === "presidentes_sr") {
            res.judges = res.sorteos_sr_presidente;
            res.month_qty = res.cantidad_sr_monthly;
            setCurrentMonthCount(getCurrentMonthCount(res.month_qty));
          }
          if (selectedStatsType === "vocales_sr") {
            res.judges = res.sorteos_sr_vocal;
            res.month_qty = res.cantidad_sr_vocal_monthly;
          }
          if (selectedStatsType === "presidentes_sre") {
            res.judges = res.sorteos_sre_presidente;
            res.month_qty = res.cantidad_sre_monthly;
          }
          if (selectedStatsType === "vocales_sre") {
            res.judges = res.sorteos_sre_vocal;
            res.month_qty = res.cantidad_sre_vocales_monthly;
          }
        }

        //console.log(findMAXandMIN(res.judges));
        if (res == undefined) return;

        res["dispersion_judges"] = findMAXandMIN(res.judges);
        setPercentageSubLabel({
          color: "success",
          amount:
            Number.isFinite(res.dispersion_judges.diff) &&
            isFinite(res.year.count) &&
            res.year.count !== 0
              ? `${((res.dispersion_judges.diff * 100) / res.year.count).toFixed(0)}%`
              : "",
          label: "diferencia entre la distribución de causas",
        });
        setBarChartData({
          ...barChartData,
          labels: assembleBarChartData(res).labels,
          datasets: { label: "Sorteos", data: assembleBarChartData(res).data },
        });

        setMonthlyStats({
          ...monthlyStats,
          datasets: {
            data: assembleMonthChartData(res.month_qty).data,
            labels: assembleMonthChartData(res.month_qty).labels,
          },
        });
        setStats(res);

        //console.log(assembleMonthChartData(res.month_qty));
        setCurrentMonthCount(getCurrentMonthCount(res.month_qty));
        setCurrentTime(getCurrentTime());
      });
  }

  useEffect(() => {
    loadStats();
  }, []);

  useEffect(() => {
    loadStats();
  }, [selectedStatsType]);

  return (
    <StyledEngineProvider injectFirst>
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12} lg={12}>
              <MDBox mb={1.5}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    backgroundColor: "white",
                    boxShadow: 3,
                    overflow: "visible",
                  }}
                >
                  <MDBox
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* Content of the card goes here */}
                  </MDBox>

                  {/* Container for the title and the Select component */}
                  <MDBox sx={{ m: 1, width: "auto" }}>
                    {/* Smaller title above the Select component */}
                    <Typography variant="subtitle2" sx={{ mb: 1 }}>
                      Ver estadísticas agrupadas por:
                    </Typography>

                    <FormControl size="small" sx={{ width: "100%" }}>
                      <InputLabel id="demo-select-small-label">Tipos de Sorteos</InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={selectedStatsType}
                        label="Tipos de Sorteos"
                        defaultValue="presidentes_sr"
                        onChange={(e) => {
                          setSelectedStatsType(e.target.value);
                          //console.log(e.target.value);
                        }}
                      >
                        <MenuItem value="presidentes_sr">Presidentes</MenuItem>
                        <MenuItem value="vocales_sr">Vocales</MenuItem>
                        <MenuItem value="presidentes_sre">SRE Presidentes</MenuItem>
                        <MenuItem value="vocales_sre">SRE Vocales</MenuItem>
                      </Select>
                    </FormControl>
                  </MDBox>
                </Card>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>

        <MDBox py={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="dark"
                  icon="weekend"
                  title="Total de Sorteos en este año"
                  count={stats.year.count || 0}
                  // percentage={{
                  //   color: "success",
                  //   amount: "+55%",
                  //   label: "que la semana pasada",
                  // }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  icon="leaderboard"
                  title="Dispersión actual"
                  count={stats.dispersion_judges.diff || 0}
                  percentage={percentageSubLabel}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="success"
                  icon="store"
                  title="Cant. de sorteos"
                  count={currentMonthCount || 0}
                  percentage={{
                    color: "success",
                    label: "en este mes",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  color="primary"
                  icon="person_add"
                  title="Sorteos realizados hoy"
                  // count={stats.cantidad_sr_today || 0}
                  count={getTodaysCount(stats) || 0}
                  // percentage={{
                  //   color: "success",
                  //   amount: "",
                  //   label: { currentTime },
                  // }}
                />
              </MDBox>
            </Grid>
          </Grid>
          <MDBox mt={4.5}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsBarChart
                    color="info"
                    title="distribución de Sorteos"
                    description="Acumulado en el corriente año"
                    date="campaign sent 2 days ago"
                    chart={barChartData}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={3}>
                  <ReportsLineChart
                    color="success"
                    title="Causas Sorteadas"
                    description={<>Sorteadas por mes en el corriente año</>}
                    chart={monthlyStats}
                  />
                </MDBox>
              </Grid>
              {/* <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                 <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                /> 
              </MDBox>
            </Grid> */}
            </Grid>
          </MDBox>

          <MDBox py={3}>
            <Grid container>
              <Grid item xs={12}>
                {" "}
                {/* Adjusted the Grid item */}
                <MDBox mb={1.5}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      backgroundColor: "white",
                      boxShadow: 3,
                      overflow: "visible",
                    }}
                  >
                    <MDBox
                      sx={{
                        flexGrow: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {/* Content of the card goes here */}
                    </MDBox>

                    {/* Container for the title and the Select component */}
                    <MDBox sx={{ m: 1, width: "100%" }}>
                      {/* Smaller title above the Select component */}
                      <Typography variant="subtitle2" sx={{ mb: 1 }}>
                        Total de causas sorteadas
                      </Typography>
                      <TablaJuecesMaterias judges={stats.judges} />
                    </MDBox>
                  </Card>
                </MDBox>
              </Grid>
            </Grid>
          </MDBox>

          <MDBox>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12} lg={12}>
                <Projects />
              </Grid>
              {/* <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid> */}
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    </StyledEngineProvider>
  );
}

export default Dashboard;
