/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useState, useEffect } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Component imports
import Crud from "layouts/administracion/components/Origenes/dialogs/Crud";
import MUIDataTable from "mui-datatables";
import "./index.css";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";

// ##############ICONS IMPORTS################
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityIcon from "@mui/icons-material/Visibility";
import RemoveIcon from "@mui/icons-material/Remove";
// ##############ICONS IMPORTS################
import OrigenService from "services/Origen.js";
import Filters from "./filters/filters.tsx";
import Message from "../Notifications/Message";
import "global-styles/forms.css";
import { CrudLayout } from "layouts/administracion/CrudLayout";
import { AccionesLayout } from "layouts/administracion/AccionesLayout";
import { AlertDisableLayout } from "layouts/administracion/AlertDisableLayout";

function Oficinas() {
  const [tableOptions, setTableOptions] = useState({});
  // const [tableData, setTableData] = useState([]);
  const [filtersState, setFiltersState] = useState<LocalFilters>({
    nombre: "",
    habilitado: true,
    fuero: null,
  });
  const tOptions = {
    storageKey: "table-Oficinas",
    rowsPerPageOptions: [10],
    rowsPerPage: 10,
    serverSide: true,
    count: 7,
    page: 0,
    onTableChange: (action, table) => {
      console.log(action);
      console.log(table);
      if (action === "changePage") {
        setTableOptions(tOptions);
        // eslint-disable-next-line no-inner-declarations
        async function getData(params) {
          const response = await OrigenService.getOrigen(params);
          setTableOptions((prev) => ({
            ...prev,
            count: response.count,
            page: table.page,
            data: response.data || [],
          }));
        }
        getData({
          page: table.page * table.rowsPerPage || 0,
          rowsPerPage: table.rowsPerPage || 10,
          sortOrder: "ASC",
          filters: filtersState,
        });
      }
    },
    data: [],
    download: false,
    filter: false,
    print: false,
    search: false,
    viewColumns: false,
    selectableRowsHeader: false,
    selectableRows: "none",
    elevation: 5,
    textLabels: {
      body: {
        noMatch: "",
      },
      pagination: {
        next: "Próxima",
        previous: "Previa",
        rowsPerPage: "Filas:",
        displayRows: "de",
      },
    },
  };

  const [displayFilters, setDisplayFilters] = useState(false);
  const [displayDialog, setDisplayDialog] = useState(false);
  const [crudAction, setCrudAction] = useState("create");
  const [message, setMessage] = useState({});
  const [showMessage, setShowMessage] = useState(false);
  const [crudData, setCrudData] = useState({});
  const [displayAlert, setDisplayAlert] = useState(false);
  const [fullName, setFullName] = useState('');

  const columns = [
    {
      label: "Nombre",
      name: "descripcion_origen",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            {`${tableOptions.data[dataIndex].descripcion_origen}`}{" "}
            <div className="deshabilitadoJuezFont">
              {tableOptions.data[dataIndex].habilitado ? "" : "Deshabilitado"}
            </div>
          </span>
        ),
      },
    },
    {
      label: "Fuero",
      name: "descripcion_fuero",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <span>
            {`${tableOptions.data[dataIndex].descripcion_fuero}`}{" "}
            <div className="deshabilitadoJuezFont">
              {tableOptions.data[dataIndex].habilitado_fuero ? "" : "Deshabilitado"}
            </div>
          </span>
        ),
      },
    },
    {
      label: "Observaciones",
      name: "observaciones",
      options: {
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          <div>
            <div>
              {tableOptions.data[dataIndex].observaciones_fuero.length > 0 ||
              tableOptions.data[dataIndex].observaciones_fuero.length > 0 ? (
                <Box
                sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                alignItems: "center",
                width: 100,
                    marginBottom: "15px",
                    marginLeft: "1px",
                    marginRight: "1px",                                
                }}
               >
                  <Tooltip title="Ver detalle">
                    <IconButton
                      onClick={() => {
                        console.log("WILL SET VALUE");
                        console.log(tableOptions.data[dataIndex]);
                        setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
                        setCrudAction("readonly");
                        setDisplayDialog(true);
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              ) : (
                ""
              )}
            </div>
          </div>
        ),
      },
    },
    { label: "ID Usuario", name: "id_usuario", options: { display: "false" } },
    { label: "Fecha de Creacion", name: "fecha_creacion", options: { display: "false" } },
    { label: "Habilitado", name: "habilitado", options: { display: "false" } },
    {
      label: "Acciones",
      name: "acciones",
      options: {
        filter: false,
        sort: false,
        download: false,
        print: false,
        viewColumns: false,
        // eslint-disable-next-line react/no-unstable-nested-components
        customBodyRenderLite: (dataIndex, rowIndex) => (
          // <Box>
          //   <Tooltip title="Editar">
          //     <IconButton
          //       aria-label="Editar"
          //       onClick={() => {
          //         console.log("WILL SET VALUE");
          //         console.log(tableOptions.data[dataIndex]);
          //         setCrudData({ data: tableOptions.data[dataIndex], index: dataIndex });
          //         setCrudAction("update");
          //         setDisplayDialog(true);
          //       }}
          //     >
          //       <Icon>edit</Icon>
          //     </IconButton>
          //   </Tooltip>
          // </Box>
          <AccionesLayout
          setCrudData = {setCrudData}
          crudData = {crudData}
          setCrudAction={setCrudAction}
          setDisplayDialogParent={setDisplayDialog}
          dataIndex = {dataIndex}
          tableOptions = {tableOptions}
          setFullName={setFullName}
          datoAlert={'descripcion_origen'}
          setDisplayAlertParent={setDisplayAlert}
          
   />
        ),
      },
    },
  ];

  useEffect(() => {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await OrigenService.getOrigen(params);
      console.log("setting table data on START");
      console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "ASC",
      filters: "",
    });
  }, []);

  useEffect(() => {
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await OrigenService.getOrigen(params);
      console.log("setting table data");
      console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "ASC",
      filters: filtersState,
    });
  }, [displayDialog]);

  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................
  useEffect(() => {
    console.log("child filters changed");
    console.log(filtersState);
    if (
      filtersState.nombre == "" &&
      filtersState.habilitado == true
    ) {
      //if clean filters is applied from child component
      applyFiltersHandler();
    }
  }, [filtersState]);

  const buscarHandler = () => {
    setDisplayFilters((currentValue) => !currentValue);
  };

  const updateTableRow = (data) => {
    console.log("updating table row");
    console.log(data);
    console.log(data.id);
    console.log("*****************");
    setTableOptions((prev) => ({
      ...prev,
      data: prev.data.map((row) => {
        if (row.id === data.id) {
          console.log("found row");
          console.log(row);
          console.log("*******************  ");
          console.log("returning...");
          console.log(data);
          return data;
        }
        return row;
      }),
    }));
  };

  const applyFiltersHandler = () => {
    //childFilters apply button clicked.    
    setTableOptions(tOptions);
    async function getData(params) {
      const response = await OrigenService.getOrigen(params);
      console.log("setting table data");
      console.log(response);
      setTableOptions((prev) => ({
        ...prev,
        count: response.count,
        data: response.data || [],
      }));
    }
    getData({
      page: tableOptions.page || 0,
      rowsPerPage: tableOptions.rowsPerPage || 10,
      sortOrder: "ASC",
      filters: filtersState,
    });
  };
  // ..............HANDLERS.........................................
  // ..............HANDLERS.........................................

  return (
    <>
      <Card sx={{ height: "100%" }}>
        <Box sx={{ m: 1, p: 1 }}>
          <Grid container spacing={1} textAlign="right">
            <Grid item xs={12} md={6} lg={12}>
              <Button
                color="warning"
                variant="contained"
                startIcon={<AddIcon />}
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setCrudData({ data: {}, index: {} }); // clear edit data
                  setCrudAction("create");
                  setDisplayDialog(true);
                }}
              >
                Nuevo
              </Button>
              <Button
                className={displayFilters ? "selectedButton" : "unselectedButton"}
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={buscarHandler}
              >
                Buscar
              </Button>
              {displayFilters && (
                <Filters
                  filtersState={filtersState}
                  setFiltersState={setFiltersState}
                  applyFiltersHandler={applyFiltersHandler}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <MUIDataTable
          title="Orígenes"
          data={tableOptions.data}
          columns={columns}
          options={tableOptions}
        />
        {/* <Fuero
        color="dark"
        icon="priority_high"
        name="Webflow"
        description="26 March 2020, at 05:00 AM"
        value="Pending"
      /> */}
      </Card>
      {(displayDialog || displayAlert) && (
        <CrudLayout setDisplayDialogParent={setDisplayDialog} setDisplayAlertParent={setDisplayAlert}

        title={
          crudAction === "create"
          ? "Nuevo Origen"
          : crudAction === "readonly"
          ? "Información de Origen"
          : "Editar Origen"}
      >
        {displayDialog && (

          <Crud
            setDisplayDialogParent={setDisplayDialog}
            setMessage={setMessage}
            setShowMessage={setShowMessage}
            crudAction={crudAction}
            crudData={crudData}
            updateTableRow={updateTableRow}
          />
        )}
        {displayAlert && (
          <AlertDisableLayout
            setDisplayDialogParent={setDisplayDialog}
            setDisplayAlertParent={setDisplayAlert}
            setMessage={setMessage}
            setShowMessage={setShowMessage}          
            crudAction={crudAction}
            crudData={crudData}
            updateTableRow={updateTableRow}
            tipo="Origenes"
            datos={fullName}
            service ={OrigenService.deleteOrigen}
          />
        )}  
        </CrudLayout>
      )}
      {showMessage && <Message message={message} setShowMessage={setShowMessage} />}
    </>
  );
}

export default Oficinas;
