/* eslint-disable prefer-template */
import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import { Fragment } from "react";
import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useEffect } from "react";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import DetailDataTable from "./DetailTable/index.tsx";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";
import SorteoService from "services/Sorteo.js";
import "./styles.css";

function DetailSorteoCustomDialog({
  selectedRowDetailDialogData,
  setShowDetailDialog,
  showDetailDialog,
}) {
  const [idSorteo, setIdSorteo] = useState(null);
  const [sorteoReportList, setSorteoReportList] = useState([]);

  useEffect(() => {
    //console.log("selectedRowDetailDialogIndex");
    //console.log(selectedRowDetailDialogData);
    setIdSorteo(selectedRowDetailDialogData.id_sorteo);
  }, [selectedRowDetailDialogData]);

  function renderDescription(description) {
    const words = description.split(" ");

    const elements = words.map((word, index) => {
      if (index === 1 && words.length > 2) {
        return (
          <Fragment key={index}>
            {word} <br />
          </Fragment>
        );
      } else if (index !== words.length - 1) {
        return word + " ";
      } else {
        return word;
      }
    });

    return elements;
  }

  async function getData(params) {
    const response = await SorteoService.getSorteo(params);
    //console.log("setting table data detail inner2");
    //console.log(response);
    //setSorteoReportList(response.sorteo_estado_list.reverse());

    // setTableOptions((prev) => ({
    //   ...prev,
    //   count: response.count,
    //   data: moveupPresidenteVocal(response.sorteo_juez_list || []),
    // }));
    return response;
  }

  function moveupPresidenteVocal(arr) {
    //messing around with declaring function ways
    //console.log("its coming as");
    //console.log(arr);
    let arr_vocales = [];
    let all_the_rest = [];
    let presidente = [];
    presidente.push(arr.find((element) => element.funcion === "presidente"));
    arr_vocales = arr.filter((element) => element.funcion === "vocal");
    //filter out the suplentes from the list because its not important and it can get quite a long list sometimes.
    // all_the_rest=arr.filter((element) => element.funcion !== "vocal" && element.funcion !== "presidente" && element.tipo_juez !== "suplente");
  }
  const generateReport = async () => {
    const detailData = await getData({
      page: 0,
      rowsPerPage: 100,
      sortOrder: "DESC",
      idSorteo: idSorteo,
    });

    const doc = new jsPDF();

    // Add text content as before
    doc.setFont("times", "bold");
    doc.text("Constancia de Sorteo ", 70, 5);
    doc.setFont("times", "italic", "normal");
    let counterA = 12;
    let counterB = 19;
    let jumpA = 10;
    let jumpB = 10;
    doc.setFont("times", "bold");
    doc.text("Fuero: ", 10, counterA);
    doc.setFont("times", "italic", "normal");
    doc.text(selectedRowDetailDialogData.descripcion_fuero ?? "", 10, counterB);
    doc.setFont("times", "bold");
    doc.text("Oficina: ", 10, counterA + jumpA * 1.5);
    doc.setFont("times", "italic", "normal");
    doc.text(selectedRowDetailDialogData.descripcion_oficina ?? "", 10, counterB + jumpB * 1.5);
    doc.setFont("times", "bold");
    doc.text("Materia: ", 10, counterA + jumpA * 3);
    doc.setFont("times", "italic", "normal");
    doc.text(selectedRowDetailDialogData.descripcion_materia ?? "", 10, counterB + jumpB * 3);
    doc.setFont("times", "bold");
    doc.text("Expediente: ", 10, counterA + jumpA * 4.4);
    doc.setFont("times", "italic", "normal");
    doc.text(selectedRowDetailDialogData.descripcion_origen ?? "", 10, counterB + jumpB * 4.5);
    doc.text(selectedRowDetailDialogData.numero_expediente ?? "", 10, counterB + jumpB * 5.1);
    doc.text(selectedRowDetailDialogData.caratula_expediente ?? "", 10, counterB + jumpB * 5.75);

    // doc.setFont("times", "bold");
    // doc.text("Sorteo: ", 10, counterA + jumpA * 4.4);
    // doc.setFont("times", "italic", "normal");
    // doc.text(selectedRowDetailDialogData.descripcion_materia ?? "", 10, counterB + jumpB * 4.4);
    doc.setFont("times", "bold");
    doc.text("Sorteo: ", 10, counterA + jumpA * 7.2); //fecha . id , colegiado etc, 4estado activco,
    doc.setFont("times", "italic", "normal");
    doc.text(
      (selectedRowDetailDialogData.fecha_sorteo ?? "") +
        " a las " +
        (selectedRowDetailDialogData.hora_sorteo ?? "") ?? "",
      10,
      counterB + jumpB * 7.3
    );
    doc.text(selectedRowDetailDialogData.codigo_sorteo ?? "", 10, counterB + jumpB * 7.9);
    doc.text(
      selectedRowDetailDialogData.descripcion_tipo_sorteo ?? "",
      10,
      counterB + jumpB * 8.55
    );
    doc.text(
      selectedRowDetailDialogData.estado_actual_sorteo.toUpperCase() ?? "",
      10,
      counterB + jumpB * 9.12
    );
    doc.setFont("times", "bold");
    // doc.text("Origen: ", 10, counterA + jumpA * 7.6);
    // doc.setFont("times", "italic", "normal");
    // doc.text(selectedRowDetailDialogData.descripcion_origen ?? "", 10, counterB + jumpB * 7.6);
    // doc.setFont("times", "bold");
    // doc.text("Tipo de Sorteo: ", 10, counterA + jumpA * 9.2);
    // doc.setFont("times", "italic", "normal");
    // doc.text(selectedRowDetailDialogData.descripcion_tipo_sorteo ?? "", 10, counterB + jumpB * 9.2);
    // doc.setFont("times", "bold");
    // doc.text("Estado: ", 10, counterA + jumpA * 10.7);
    // doc.setFont("times", "italic", "normal");
    // doc.text(selectedRowDetailDialogData.estado_actual_sorteo ?? "", 10, counterB + jumpB * 10.7);
    // doc.setFont("times", "bold");
    doc.text("Operador: ", 10, counterA + jumpA * 10.5);
    doc.setFont("times", "italic", "normal");
    doc.text(selectedRowDetailDialogData.username ?? "", 10, counterB + jumpB * 10.5);

    doc.setFont("times", "normal", "normal");
    doc.setFontSize(10); // Adjust the size as needed
    doc.text("Fecha de Emisión de constancia: ",150, counterA + jumpA * 27.5);

    // Format current date
    const now = new Date();
    const formattedDate =
      now.getDate().toString().padStart(2, "0") +
      "/" +
      (now.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      now.getFullYear() +
      " " +
      now.getHours().toString().padStart(2, "0") +
      ":" +
      now.getMinutes().toString().padStart(2, "0");

    // Set font for the date
    doc.setFont("times", "italic", "normal");
    doc.setFontSize(10); // Adjust the size as needed
    doc.text(formattedDate, 150, counterB + jumpB * 27.5);

    // ... add more text content

    // Prepare table data
    // Replace the below line with your actual data extraction logic

    //console.log("here data");
    //console.log(detailData.sorteo_juez_list);
    const tableRowWidth = 4;
    if (detailData.sorteo_juez_exclusion_list?.length > 0) {
      autoTable(doc, {
        startY: 150, // adjust this according to your content
        head: [["Juez", "Tipo de Exclusion", "Estado", "Observaciones"]],
        body: detailData.sorteo_juez_exclusion_list.map((item) => [
          item.apellido_juez + " " + item.nombre_juez,
          item.descripcion_tipo_exclusion,
          item.resolucion == "shl"
            ? "Si hace lugar"
            : item.resolucion == "nhl"
            ? "No hace lugar"
            : "Pendiente",
          item.notas_resolucion,
        ]),
        // You can add more options here to customize the look of your table
      });
    }

    // Add the table to the PDF
    autoTable(doc, {
      startY: 180 + 20 + tableRowWidth * detailData.sorteo_juez_exclusion_list?.length || 1, // adjust this according to your content
      head: [["Juez", "Carácter", "Función"]],
      body: detailData.sorteo_juez_list
        .filter((i) => i.sorteado == true)
        .map((item) => {
          return [
            item.apellido_juez + " " + item.nombre_juez,
            item.tipo_juez.toUpperCase(),
            item.funcion.toUpperCase(),
          ];
        }),
      // You can add more options here to customize the look of your table
    });

    // Save the PDF
    doc.save("constancia-sorteo-" + selectedRowDetailDialogData.codigo_sorteo + ".pdf");
  };

  // const generateReport = () => (
  //   <PDFDownloadLink document={<MyDocument />} fileName="example-document.pdf">
  //     {({ blob, url, loading, error }) => (loading ? "Loading document..." : "Download now!")}
  //   </PDFDownloadLink>
  // );

  return (
    <div>
      <Dialog
        open={true}
        fullWidth
        maxWidth="lg"
        onClose={() => {
          //console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN OUTSIDE ONCLOSE");
          //  setShowExclusionConfirmCustomDialog(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            className="detailDialog"
            id="alert-dialog-description"
            component={"span"}
          >
            {/* previous line is a fix where material ui puts a div inside a p tag gotta add component= {'span'} prop */}

            <div className="detail-container">
              <div className="top">
                <div>
                  <span style={{ fontWeight: "900" }}>
                    <h2>{selectedRowDetailDialogData.caratula_expediente ?? ""}</h2>
                  </span>
                </div>

                <span style={{ fontWeight: "800" }}>
                  Nro de Expediente: {selectedRowDetailDialogData.numero_expediente ?? ""}
                  <div>Tipo de Sorteo: {selectedRowDetailDialogData.tipo_sorteo.toUpperCase() ?? ""}</div>
                </span>
              </div>
              <div className="sub-detail">
                <div className="a">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fuero</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_fuero ?? ""}
                </div>
                <div className="b">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>N Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.codigo_sorteo ?? ""}
                </div>
                <div className="c">
                  {" "}
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Materia</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_materia ?? ""}
                </div>
                <div className="d">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Oficina</u>
                    </span>
                  </div>
                  {renderDescription(selectedRowDetailDialogData.descripcion_oficina ?? "")}
                </div>
                <div className="e">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Origen</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_origen ?? ""}
                </div>
                <div className="f">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Tipo de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.descripcion_tipo_sorteo ?? ""}
                </div>
                <div className="g">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Estado de Sorteo</u>
                    </span>
                  </div>
                  {(selectedRowDetailDialogData.estado_actual_sorteo ?? "").toUpperCase()}
                </div>
                <div className="h">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Fecha de Sorteo</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.fecha_sorteo +
                    " " +
                    selectedRowDetailDialogData.hora_sorteo}
                </div>
                <div className="i">
                  <div>
                    <span style={{ fontWeight: "900" }}>
                      <u>Creado Por</u>
                    </span>
                  </div>
                  {selectedRowDetailDialogData.username ?? ""}
                </div>
              </div>
            </div>

            <div className="detailTableMargins">
              <DetailDataTable idSorteo={idSorteo} />
            </div>
            
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              //console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN CANCELARBTN");
              setShowDetailDialog(false);
            }}
            autoFocus
          >
            Cerrar
          </Button>

          <Button
            onClick={() => {
              //console.log("DIALOG HAS BEEN CLOSED FROM CLICKIN CANCELARBTN");
              // setShowDetailDialog(false);
              generateReport();
            }}
            autoFocus
          >
            Generar Reporte
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default DetailSorteoCustomDialog;
