import Base from "./axiosBase";

const JuezService = {
  createJuez: async (juez) => Base.post("/juez/", juez).then((res) => res.data),
  deleteJuez: async (juez) =>
    Base.delete(`juez/?id=${juez.id}&action=${juez.action}`).then((res) => res.data),
  // updateJuez: async (juez) => Base.put(`juez/`, juez).then((res) => res.data),
  updateJuez: async (juez) => 
    Base.put(`juez/?id=${juez.id}`, juez).then((res) => res.data),


  // eslint-disable-next-line arrow-body-style
  getJuez: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (url !== "") {
      url = `juez/?${url}`;
    } else {
      url = "juez/";
    }
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&nombre=${params.filters.nombre}`;
      }
      if (params.filters.apellido) {
        url += `&apellido=${params.filters.apellido}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => {
      res.data.data.forEach((j) => {
        j.fullName = `${j.nombre} ${j.apellido}`;
      });
      // res.data.map((juez) => {
      //   juez.fullName = `${juez.nombre} ${juez.apellido}`;
      // });
      return res.data;
    });
  },
};

export default JuezService;
