/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
/* eslint-disable no-console */
/* eslint-disable prettier/prettier */
import axios from "axios";
import errorsList from "../errors/errors_list";


const axiosBase = axios.create({
  // baseURL: `http://10.147.20.11:5438/api/v1/`,
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "Content-type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    // Authorization: `Bearer ${localStorage.getItem("token")}`,
  },
});

axiosBase.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    
    response.error = false; //check where this is used and remove it
    response.data.error=false;
    // alert("interceptor 1")
    //console.log("interceptor 1")
    //console.log(response)
    if ("error_code" in response.data) {
      //there been a controlled API error
      //console.log("there has been a controlled API error");
      if(response.data.error_code==="err-access-token-05")
      {
        alert("la session ha expirado por favor vuelva a loguearse")
      }
      response.error = true;
      response.data.error=true;
      response.data.human_message = "Error al intentar realizar la operación";
     
      errorsList.find((error) => {
       //console.log(response.data.message_code)
        if (error.message_code === response.data.message_code) {
          //console.log("error found");
          //console.log(error);
          response.data.human_message = error.human_message;
        }
      });
      //console.log("returning response por axios2");
      //console.log(response)
      
      return response;
    }
    console.log("response from axios base")
      console.log(response)
    //console.log("returning response por axios3");
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default axiosBase;
