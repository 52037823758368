import Base from "./axiosBase";

const FueroService = {
  createFuero: async (fuero) => Base.post("/fuero/", fuero),
  deleteFuero: async (fuero) =>
    Base.delete(`fuero/?id=${fuero.id}&action=${fuero.action}`).then(
      (res) => res.data
    ),
  // updateFuero: async (fuero) => Base.put(`fuero/`, fuero).then((res) => res.data),
  updateFuero: async (fuero) => 
    Base.put(`fuero/?id=${fuero.id}`, fuero).then((res) => res.data),



  // eslint-disable-next-line arrow-body-style
  getFuero: async (params) => {
    // let url = `fuero/?`;
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    if (url !== "") {
      url = `fuero/?${url}`;
    } else {
      url = "fuero/";
    }

    // eslint-disable-next-line prettier/prettier
    return Base.get(url).then((res) => res.data);
  },
};

export default FueroService;
