import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    LinearProgress,
    TextField,
    Typography,
    Switch,
    Autocomplete,
  } from "@mui/material";
  import { StyledEngineProvider } from "@mui/material/styles";
  import CloseIcon from "@mui/icons-material/Close";


import MDBox from "components/MDBox"
import { useEffect, useState } from "react";


export function CrudLayout( {
    setDisplayDialogParent, 
    setDisplayAlertParent,
    children, 
    title = ''} ) {
   
    // const [displayDialogPa, setDisplayDialogPa] = useState(true)
      const handleClose = () => {
        // eslint-disable-next-line react/prop-types
        setDisplayDialogParent(false);
        setDisplayAlertParent(false);
      };

    // // const [stateDialogParent, setStateDialogParent] = useEffect(false);
    // // // const handleClose = () => {
    // // //     // eslint-disable-next-line react/prop-types
    // // //     setDisplayDialogParent(false);
    // // //   };
    return(

        <>
            <StyledEngineProvider injectFirst>
                <Dialog open onClose={handleClose}>
                    
                    <DialogTitle id="form-dialog-title">
                        { title }
                        <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: "absolute",
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                        >
                        <CloseIcon />
                        </IconButton>
                    </DialogTitle>

                    <DialogContent dividers>
                        <MDBox >
                            <MDBox
                                sx={{
                                display: "flex",
                                justifyContent: "center",
                                flexDirection: "column",
                                alignItems: "center",
                                width: 350,
                                    marginBottom: "15px",
                                    marginLeft: "50px",
                                    marginRight: "50px",                                
                                }}
                            >
                                {children}
                            </MDBox>
                        </MDBox>
                    </DialogContent>
                </Dialog>
        </StyledEngineProvider>
        </>
    )
}

        
     