import Base from "./axiosBase";

const StatsService = {
  // eslint-disable-next-line arrow-body-style
  getOrigen: async (params) => {
    // eslint-disable-next-line prettier/prettier
    //console.log("from service");
    //console.log(params);
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (url !== "") {
      url = `stats/?id_fuero=${localStorage.getItem("id_fuero") + url}`;
    } else {
      url = `stats/?id_fuero=${localStorage.getItem("id_fuero")}`;
    }

    return Base.get(url).then((res) => res.data);
  },
};

export default StatsService;
