import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TipoSorteoService from "services/Tipo-Sorteo";
import { CrudLayout } from "layouts/administracion/CrudLayout";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  
  const tiposSorteos = [
    { id: 1, descripcion: "colegiado", cantidad: 3 },
    { id: 2, descripcion: "resolucion-exclusion", cantidad: 0 },
    { id: 3, descripcion: "plenario", cantidad: 7 },
    { id: 4, descripcion: "unipersonal", cantidad: 1 },
  ];

  const idSorteo = crudData.data.id || "";
  const [nombreSorteo, setNombreSorteo] = useState(crudData.data.descripcion || "");
  const [tipoSorteo, setTipoSorteo] = useState(
    tiposSorteos.find((tipo) => tipo.descripcion === crudData.data.tipo) || ""
  );
  const [selectedTipoSorteo, setSelectedTipoSorteo] = useState(
    tiposSorteos.find((tipo) => tipo.descripcion === crudData.data.tipo) || ""
  );

  const [observacionesSorteo, setObservacionesSorteo] = useState(crudData.data.observaciones || "");

  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  // const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] =
    useState(MAX_LENGTH_OBSERVACIONES);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false)

    useEffect( () => {
      console.log('useEffect called');
      setButtonGuardarOk(false);
      if(selectedTipoSorteo === null ||
        selectedTipoSorteo === undefined || 
        nombreSorteo === ""){
          setButtonGuardarOk(false);
      }else if(
        nombreSorteo !== crudData.data.descripcion || 
        observacionesSorteo !== crudData.data.observaciones || 
        selectedTipoSorteo.descripcion !== crudData.data.tipo ){
          setButtonGuardarOk(true)
      }
    }, [nombreSorteo, selectedTipoSorteo, observacionesSorteo]);

  function updateModifiedValues(row, action) {
    crudData.data.nombre = row.nombre;
    crudData.data.apellido = row.apellido;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const handleNombreSorteo = (e) => {
    setNombreSorteo(e.target.value);
  };
  const handleTipoSorteo = (e) => {
    console.log('evento')
    console.log(e.target.textContent)
    console.log(tiposSorteos.find((tipo) => console.log(tipo.descripcion)))
    setTipoSorteo(e.target.textContent);
    // setTipoSorteo(tiposSorteos.find((tipo) => tipo.descripcion === e.target.textContent))
    // setSelectedTipoSorteo(tiposSorteos.find((tipo) => tipo.descripcion === e.target.textContent));
    setSelectedTipoSorteo(e.target.textContent);

  };
  const handleObservacionesSorteo = (e) => {
    setCharactersLeftObservaciones(MAX_LENGTH_OBSERVACIONES + 1 - e.target.value.length);
    if (e.target.value.length <= MAX_LENGTH_OBSERVACIONES) {
      setObservacionesSorteo(e.target.value);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const newSorteoHandler = () => {
    // setLoading(true);
    ////console.log("created OK");
    TipoSorteoService.createSorteo({
      descripcion: nombreSorteo,
      tipo: tipoSorteo,
      cantidad: tiposSorteos.find((t) => t.descripcion === tipoSorteo).cantidad,
      observaciones: observacionesSorteo,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        ////console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Tipo de Sorteo creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        ////console.log(e);
      });
  };

  const updateSorteoHandler = () => {
    setLoading(true);
    const updateObj = {
      descripcion: nombreSorteo,
      tipo: tipoSorteo.descripcion,
      cantidad: tipoSorteo.cantidad,
      observaciones: observacionesSorteo,
      id: idSorteo,
    };
    console.log('llamada al service')
    TipoSorteoService.updateSorteo(updateObj)
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          setButtonGuardarOk(false);
          setLoading(false);
          return;
        }
        ////console.log("updated OK");
        ////console.log(response);
        setMessage({
          severity: "success",
          messageText: "Tipo de Sorteo actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        ////console.log(e);
      });
  };

  // const deleteSorteoHandler = () => {
  //   // setLoading(true);
  //   TipoSorteoService.deleteSorteo({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idSorteo,
  //   })
  //     .then((response) => {
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       ////console.log("disabled OK");
  //       ////console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Tipo de Sorteo deshabilitado" : "Tipo de Sorteo habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       ////console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <CrudLayout  title={crudAction === "create" ? "Nuevo Tipo de Sorteo" : "Deshabilitar Tipo de Sorteo"} >
       <>
       {/* {!triggerDeshabilitarConfirmation && ( */}
            <>
              {/* {crudAction !== "create" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Descripcion del Sorteo"
                    autoFocus
                    value={nombreSorteo}
                    onChange={handleNombreSorteo}
                  />
                  <Autocomplete
                    disableClearable
                    id="combo-box-demo"
                    options={tiposSorteos}
                    value={selectedTipoSorteo || null }
                    getOptionLabel={(option) => option.descripcion}
                    style={{ marginTop: "10px", marginBottom: "15px" }}
                    // onChange={handleTipoSorteo}
                    onChange={(event, newInputValue) => {
                      console.log(newInputValue)
                      debugger;
                      setTipoSorteo(newInputValue?.descripcion || "");
                      setSelectedTipoSorteo(newInputValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Tipo" variant="outlined" />
                    )}
                  />
                </div>
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ width: "100%", pb: 2 }}
                    multiline
                    rows={5}
                    value={observacionesSorteo}
                    onChange={handleObservacionesSorteo}
                  />
                </div>
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                  <MDButton
                    disabled = {!buttonGuardarOk || crudAction === "readonly"}
                    onClick={crudAction === "create" ? newSorteoHandler : updateSorteoHandler}
                    size="small"
                    color="primary"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Guardar
                  </MDButton>
                  </span>
                  </Tooltip>
                </div>
              </div>
            </>
          {/* )} */}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Tipo de Sorteo?"
                    : "¿Está seguro que desea habilitar el Tipo de Sorteo?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreSorteo}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteSorteoHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

    {/* // </CrudLayout> */}
    </>

         

  //         {/* {true && (
  //           <MDBox
  //             sx={{
  //               p: 1,
  //               color: "SlateGrey",
  //               border: "1px solid DarkGray",
  //               borderRadius: "3px",
  //             }}
  //           >
  //             <Typography variant="h8" component="div" gutterBottom>
  //               Creado:
  //             </Typography>

  //             <Typography variant="h8" component="div" gutterBottom>
  //               Última actualizacion:
  //             </Typography>

  //             <Typography variant="h8" component="div" gutterBottom>
  //               Último usuario que actualizó:
  //             </Typography>
  //           </MDBox>
  //         )} */}
  //         </MDBox>
  //         </MDBox>
  //       </DialogContent>
  //     </Dialog>
  //   </StyledEngineProvider>
  );
}

export default Crud;
