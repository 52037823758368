/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import MUIDataTable from "mui-datatables";

// ##############ICONS IMPORTS################
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import Autocomplete from "@mui/material/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import { StyledEngineProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import FueroService from "services/Fuero";
import MateriaService from "services/Materias"

import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  IconButton,
  Card,
} from "@mui/material";
import { Box } from "@mui/system";

import "./filters.css";

import {LocalFilters} from "../LocalFilters";

function Filters({ filtersState, setFiltersState, applyFiltersHandler }) {
  // const [localFilters, setLocalFilters] = useState<LocalFilters>(filtersState);
  const [fueros, setFueros] = useState([]);
  const [materias, setMaterias] = useState([]);

  

  useEffect(() => {
    FueroService.getFuero({}).then((response) => {
      setFueros(response.data);
    });
    MateriaService.getOficinaMateria({}).then((response) => {
      setMaterias(response.data);
    }
    );
  }, []);

  return (
    <StyledEngineProvider injectFirst>
      <div>
        <div className="containerTitle">
          <MDTypography variant="h6" fontWeight="medium">
            Búsqueda de Expedientes {filtersState.nombre}
          </MDTypography>
        </div>
        <Divider variant="middle" className="thickDivider" />
        <div className="container">
          <div className="input1">
            {" "}
            <TextField
              id="standard-basic"
              label="Numero"
              variant="standard"
              value={filtersState.numero ?? ""}
              onChange={(value) => {
                setFiltersState((prev) => ({ ...prev, numero: value.target.value }));
              }}
            />
          </div>
          <div className="input2">
            <Autocomplete
              id="combo-box-demo"
              options={materias}
              value={filtersState.materia}
              sx={{ width: 300, marginBottom: "15px" }}
              getOptionLabel={(option) => option.descripcion_materia}
              renderInput={(params) => <TextField {...params} label="Materias" />}
              onChange={(event, newValue) => {
                setFiltersState((prev) => ({ ...prev, materia: newValue }));
              }}
            />
          </div>
          <div className="input3">
            {" "}
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={filtersState.habilitado}
                    onChange={(value) => {
                      setFiltersState((prev) => ({
                        ...prev,
                        habilitado: value.target.checked,
                      }));
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label="Habilitado"
              />
            </FormGroup>
          </div>
          {/* <div className="input3">
            {" "}
            <TextField id="standard-basic" label="Fecha de Creacion" variant="standard" />
          </div> */}
        </div>
        <div className="containerCaratula">
          <div className="inputCaratula">
            {" "}
            <TextField
              id="standard-basic"
              label="Carátula"
              variant="standard"
              style={{ width: "300px" }}
              value={filtersState.caratula ?? ""}
              onChange={(value) => {
                setFiltersState((prev) => ({ ...prev, caratula: value.target.value }));
              }}
            />
          </div>
      
        </div>
        <Divider variant="middle" className="thickDivider" />
        <Button
          color="warning"
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={applyFiltersHandler}
        >
          Aplicar
        </Button>
        <Button
          color="warning"
          variant="contained"
          startIcon={<SearchIcon />}
          onClick={() => {
            setFiltersState({ caratula: "",numero: "", habilitado: true, fuero:null });
             // will catch in useEffect if values are default, do a backend call
          }}
        >
          Limpiar
        </Button>
      </div>
    </StyledEngineProvider>
  );
}

export default Filters;
