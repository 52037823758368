import { Alert, Snackbar } from "@mui/material";
import { useState } from "react";
import PropTypes from "prop-types";

function Message({ message, setShowMessage }) {
  const [open, setOpen] = useState(true);

  const handleCloseSnackBar = () => {
    setOpen(false);
     setShowMessage(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={3000} onClose={handleCloseSnackBar}>
      <Alert
        onClose={handleCloseSnackBar}
        severity={message.severity}
        variant="filled"
        sx={{ width: "100%" }}
      >
        {message.messageText}
      </Alert>
    </Snackbar>
  );
}

export default Message;
