import Base from "./axiosBase";

const ExclusionService = {
  createExclusion: async (exclusion) => Base.post("/exclusion/", exclusion).then((res) => res.data),
  deleteExclusion: async (exclusion) =>
    Base.delete(
      `exclusion/?id=${exclusion.id}&motivo=${exclusion.motivo}&id=${exclusion.id}`
    ).then((res) => res.data),
  updateExclusion: async (exclusion) =>
    Base.put(`exclusion/?id=${exclusion.id}`, exclusion).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getExclusion: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (url !== "") {
      url = `exclusion/?${url}`;
    } else {
      url = "exclusion/";
    }
    //console.log("PARAMS FILTERS");
    //console.log(params.filters);
    if (params.filters) {
      if (params.filters.juez) {
        url += `&id_juez=${params.filters.juez.id}`;
      }
      if (params.filters.tipo) {
        url += `&tipo=${params.filters.tipo.descripcion}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default ExclusionService;
