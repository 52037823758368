import Base from "./axiosBase";

const OficinaJuezService = {
  createOficinaJuez: async (OficinaJuez) => Base.post("/oficina-juez/", OficinaJuez),
  deleteOficinaJuez: async (OficinaJuez) =>
    Base.delete(
      `oficina-juez/?id=${OficinaJuez.id}&action=${OficinaJuez.action}`
    ).then((res) => res.data),
  updateOficinaJuez: async (OficinaJuez) =>
    Base.put(`oficina-juez/?id=${OficinaJuez.id}`, OficinaJuez).then((res) => res.data),

  // eslint-disable-next-line arrow-body-style
  getOficinaJuez: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (url !== "") {
      url = `oficina-juez/?id_oficina=${localStorage.getItem("id_oficina") + url}`;
    } else {
      url = `oficina-juez/?id_oficina=${localStorage.getItem("id_oficina")}`;
    }

    // if (url !== "") {
    //   url = `oficina-juez/?${url}`;
    // } else {
    //   url = "oficina-juez/";
    // }
    if (params.filters) {
      if (params.filters.oficina) {
        url += `&id_oficina=${params.filters.oficina.id}`;
      }
      if (params.filters.juez) {
        url += `&id_juez=${params.filters.juez.id}`;
      }
      if (params.filters.habilitado !== undefined) {
        //console.log("WHAT THE HELL");
        //console.log(params.filters.habilitado);
        if (!params.filters.habilitado) {
          url += `&habilitado=D`;
        } else {
          url += `&habilitado=H`;
        }
      }
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default OficinaJuezService;
