import Base from "./axiosBase";

const SREService = {
  createSRE: async (sorteo) => Base.post("/sorteo-resolucion-exclusion/", sorteo),
  deleteSorteo: async (sorteo) =>
    Base.delete(
      `sorteo-resolucion-exclusion/?id=${sorteo.id}&id_oficina=${localStorage.getItem(
        "id_oficina"
      )}&motivo=${sorteo.deletionComments}"
      )}&action=${sorteo.action}`
    ).then((res) => res.data),
  updateSorteo: async (sorteo) =>
    Base.put(`sorteo-resolucion-exclusion-juez-elegible/`, sorteo).then((res) => res.data),
  // eslint-disable-next-line arrow-body-style
  getSREJuezElegible: async (params) => {
    // eslint-disable-next-line prettier/prettier
    let url = "";
    //console.log("params");
    //console.log(params);
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }
    if (params.idSorteo) {
      url += `&id=${params.idSorteo}`;
    }
    if (url !== "") {
      url = `sorteo-resolucion-exclusion-juez-elegible/?${url}`;
    } else {
      url = "sorteo-resolucion-exclusion-juez-elegible/";
    }
    // if (params.filters) {
    //   if (params.filters.nombre) {
    //     url += `&descripcion=${params.filters.nombre}`;
    //   }
    //   if (!params.filters.habilitado) {
    //     url += `&habilitado=D`;
    //   } else {
    //     url += `&habilitado=H`;
    //   }
    // }
    return Base.get(url).then((res) => res.data);
  },
};

export default SREService;
