import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import JuezService from "services/Juez";
// import { AlertEditLayout } from "layouts/administracion/AlertEditLayout";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idJuez = crudData.data.id || "";
  const [nombreJuez, setNombreJuez] = useState(crudData.data.nombre || "");
  const [apellidoJuez, setApellidoJuez] = useState(crudData.data.apellido || "");
  const [observacionesJuez, setObservacionesJuez] = useState(crudData.data.observaciones || "");
  const [userNameJuez, setUserNameJuez] = useState(crudData.data.username || "");
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] = useState(MAX_LENGTH_OBSERVACIONES);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);
  // const [triggerHabilitarParaEditar, settriggerHabilitarParaEditar] = useState(crudAction=== 'update' ? !isHabilitado : isHabilitado);
    

  useEffect( () => {
      handleButtonGuardar();    
    }, [nombreJuez, apellidoJuez, observacionesJuez])
 
  function handleButtonGuardar(){
      setButtonGuardarOk(false);
      if((apellidoJuez === "" || nombreJuez === "") ){
        setButtonGuardarOk(false)   
      }else if(apellidoJuez !== crudData.data.apellido || observacionesJuez !== crudData.data.observaciones || nombreJuez !== crudData.data.nombre ){
        setButtonGuardarOk(true)
      }
    }
      
    

  function updateModifiedValues(row, action) {
    crudData.data.nombre = row.nombre;
    crudData.data.apellido = row.apellido;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const handleNombreJuez = (e) => {
    console.log('set nombre juez' + nombreJuez)
    setNombreJuez(e.target.value);

  };
  const handleApellidoJuez = (e) => {
    console.log('set nombre juez')
    setApellidoJuez(e.target.value);
  };
  const handleObservacionesJuez = (e) => {
    setCharactersLeftObservaciones(MAX_LENGTH_OBSERVACIONES + 1 - e.target.value.length);
    if (e.target.value.length <= MAX_LENGTH_OBSERVACIONES) {
      setObservacionesJuez(e.target.value);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const newJuezHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    JuezService.createJuez({
      nombre: nombreJuez,
      apellido: apellidoJuez,
      observaciones: observacionesJuez,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Juez creado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateJuezHandler = () => {
    
    // setLoading(true);
    const updateObj = {
      nombre: nombreJuez,
      apellido: apellidoJuez,
      observaciones: observacionesJuez,
      id: idJuez,
    };
    JuezService.updateJuez(updateObj)
      .then((response) => {
        //console.log("updated OK");
        //console.log(response);
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          setButtonGuardarOk(false);
          return;
        }
        setMessage({
          severity: "success",
          messageText: "Juez actualizado correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
        //console.log("catched err");
      });
  };

  // const deleteJuezHandler = () => {
  //   // setLoading(true);

  //   JuezService.deleteJuez({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idJuez,
  //   })
  //     .then((response) => {
  //       if (response.error) {
  //         setMessage({
  //           severity: "error",
  //           messageText: response.human_message,
  //         });
  //         setShowMessage(true);
  //         return;
  //       }
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Juez fue deshabilitado" : "Juez fue habilitado",
  //       });
  //       setShowMessage(true);

  //       if(!isHabilitado){ 
  //         // settriggerHabilitarParaEditar(false);
  //         setIsHabilitado(true);     
  //       }else{
  //         setDisplayDialogParent(false);
  //       }
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };


  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    <>
    {/* <StyledEngineProvider injectFirst>
      <Dialog open onClose={handleClose}>
        <DialogTitle id="form-dialog-title">
          {crudAction === "create" ? "Nuevo Juez" : "Editar Juez"}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent dividers> */}
          {!triggerDeshabilitarConfirmation &&  (
            <>
              {/* {crudAction !== "create" && (
                <div className="botonDeshabilitar">
                  <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton>
                </div>
              )} */}
              <div className="containercrud">
                {loading && <LinearProgress color="primary" className="progressBar" />}

                <div className="nombrecrud">
                  <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Apellido del Juez"
                    autoFocus
                    value={apellidoJuez}
                    onChange={handleApellidoJuez}
                  />

                  <TextField
                    sx={{ width: "400px", pb: 1 }}
                    inputProps={{
                      autoComplete: "off",
                      maxLength: 80,
                    }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Nombre del Juez"
                    value={nombreJuez}
                    onChange={handleNombreJuez}
                  />
                </div>
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ width: "100%", pb: 2 }}
                    multiline
                    rows={5}
                    value={observacionesJuez}
                    onChange={handleObservacionesJuez}
                  />
                </div>
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                                              disabled={!buttonGuardarOk || crudAction == 'readonly'}

                        onClick={crudAction === "create" ? newJuezHandler : updateJuezHandler}
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Guardar
                      </MDButton>
                    </span>
                  </Tooltip>
                </div>
              </div>
            </>
          )}

          {/* alerta para habilitar juez para edición de datos */}
          
          {/* { crudAction == 'update' &&  triggerHabilitarParaEditar && (

            // <AlertEditLayout 
            //     setDisplayDialogParent={setDisplayDialogParent}
            //     settriggerHabilitarParaEditar={settriggerHabilitarParaEditar}
            //     title = "No se puede editar un Juez deshabilitado. ¿desea habilitarlo? "
            //     deleteHandler={deleteJuezHandler}
            //     dato1={nombreJuez}
            //     dato2={apellidoJuez}
            //     />
            // <div className="containercrud">
            //   <div className="nombrecrud">
            //     <Typography
            //       variant="h8"
            //       component="div"
            //       gutterBottom
            //       color={"green" }
            //     >
            //       {"No se puede editar un Juez deshabilitado. ¿desea habilitarlo? "}
            //     </Typography>
            //   </div>
            //   <div className="fueroName">
            //     <Typography variant="h8" component="div" gutterBottom>
            //       {apellidoJuez}&nbsp;{nombreJuez}
            //     </Typography>
            //   </div>
            //   <div className="botonesaccionescrud">
            //     <MDButton
            //       sx={{ marginRight: "5px" }}
            //       size="small"
            //       color="secondary"
            //       onClick={() => {
            //         settriggerHabilitarParaEditar(false);
            //         setDisplayDialogParent(false);
            //       }}
            //       variant="contained"
            //       startIcon={<SaveIcon />}
            //     >
            //       Cancelar
            //     </MDButton>
            //     <MDButton
            //       sx={{ marginRight: "5px" }}

            //       onClick={ deleteJuezHandler}

            //       size="small"
            //       color="success"
            //       variant="contained"
            //       startIcon={<SaveIcon />}
            //     >
            //       Habilitar y editar
            //     </MDButton>
            //     <MDButton
            //       onClick={ () => { deleteJuezHandler();
            //         setDisplayDialogParent(false);
            //       }}
            //       size="small"
            //       color="primary"
            //       variant="contained"
            //       startIcon={<SaveIcon />}
            //     >
            //       Habilitar y cerrar
            //     </MDButton>
               
            //   </div>
            // </div>
          )} */}
          {/* ************************************************ */}

          {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Juez?"
                    : "¿Está seguro que desea habilitar el Juez?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {apellidoJuez}&nbsp;{nombreJuez}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={() => {
                    setTriggerDeshabilitarConfirmation(false);
                  }}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteJuezHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
    //       )} */}
    {/* //     </DialogContent> */}
    {/* //   </Dialog> */}
    {/* // </StyledEngineProvider> */}
    </>
  );
}

export default Crud;
