import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import MDBox from "components/MDBox";
import { StyledEngineProvider } from "@mui/material/styles";
import "./crud.css";
import "global-styles/progressBar.css";
import MDButton from "components/MDButton";
import React, { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography,
  Switch,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import TipoExclusionService from "services/Tipo-Exclusion";

const MAX_LENGTH_OBSERVACIONES = 400;
// eslint-disable-next-line react/prop-types
function Crud({
  setDisplayDialogParent,
  setMessage,
  setShowMessage,
  crudAction,
  crudData,
  updateTableRow,
}) {
  const idExclusion = crudData.data.id || "";
  const [nombreExclusion, setNombreExclusion] = useState(crudData.data.descripcion || "");
  const [tipoExclusion, setTipoExclusion] = useState(crudData.data.tipo || "");
  const [observacionesExclusion, setObservacionesExclusion] = useState(
    crudData.data.observaciones || ""
  );
  const tiposExclusiones = [
    { id: 1, descripcion: "expediente" },
    { id: 2, descripcion: "temporal" },
    { id: 3, descripcion: "permanente" },
  ];
  const [selectedTipoExclusion, setSelectedTipoExclusion] = useState(
    tiposExclusiones.find((tipo) => tipo.descripcion === tipoExclusion) || ""
  );
  const [loading, setLoading] = useState(false);
  const [isHabilitado, setIsHabilitado] = React.useState(crudData.data.habilitado || false);
  const [triggerDeshabilitarConfirmation, setTriggerDeshabilitarConfirmation] = useState(false);
  const [charactersLeftObservaciones, setCharactersLeftObservaciones] = useState(MAX_LENGTH_OBSERVACIONES);
  const [buttonGuardarOk, setButtonGuardarOk] = useState(false);
    const [triggerHabilitarParaEditar, settriggerHabilitarParaEditar] = useState(crudAction=== 'update' ? !isHabilitado : isHabilitado);
    
    useEffect( () => {
    handleButtonGuardar(); 
    }, [nombreExclusion, tipoExclusion, observacionesExclusion]);

    function handleButtonGuardar(){
    setButtonGuardarOk(false);
    if((nombreExclusion === "" || tipoExclusion === "" || tipoExclusion === null) ){
    setButtonGuardarOk(false) 
    }else if(nombreExclusion !== crudData.data.descripcion || observacionesExclusion !== crudData.data.observaciones || tipoExclusion !== crudData.data.tipo ){
    setButtonGuardarOk(true)
    }
    }
    
  function updateModifiedValues(row, action) {
    crudData.data.tipo = row.tipo;
    crudData.data.descripcion = row.descripcion;
    crudData.data.observaciones = row.observaciones;
    crudData.data.username = row.username;
    crudData.data.id_usuario = row.id_usuario;
    crudData.data.id = row.id;
    crudData.data.habilitado = action === "delete" ? !isHabilitado : isHabilitado;
    return crudData.data;
  }

  const handleNombreExclusion = (e) => {
    setNombreExclusion(e.target.value);
  };

  const handleTipoExclusion = (e) => {
    console.log(e.target.textContent);
    setTipoExclusion(e.target.textContent);
    setSelectedTipoExclusion(
      tiposExclusiones.find((target) => target.descripcion === e.target.textContent)
    );
  };
  const handleObservacionesExclusion = (e) => {
    setCharactersLeftObservaciones(MAX_LENGTH_OBSERVACIONES + 1 - e.target.value.length);
    if (e.target.value.length <= MAX_LENGTH_OBSERVACIONES) {
      setObservacionesExclusion(e.target.value);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const newExclusionHandler = () => {
    // setLoading(true);
    //console.log("created OK");
    TipoExclusionService.createTipoExclusion({
      descripcion: nombreExclusion,
      tipo: tipoExclusion,
      observaciones: observacionesExclusion,
    })
      .then((response) => {
        if (response.error) {
          setMessage({
            severity: "error",
            messageText: response.human_message,
          });
          setShowMessage(true);
          return;
        }
        //console.log(response.data);
        setMessage({
          severity: "success",
          messageText: "Exclusion creada correctamente",
        });
        setShowMessage(true);
        setDisplayDialogParent(false);
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  const updateExclusionHandler = () => {
    // setLoading(true);
    const updateObj = {
      descripcion: nombreExclusion,
      tipo: tipoExclusion,
      observaciones: observacionesExclusion,
      id: idExclusion,
    };
    TipoExclusionService.updateTipoExclusion(updateObj)
      .then((response) => {
        //console.log("updated OK");
        //console.log(response);
        setMessage({
          severity: "success",
          messageText: "Exclusion actualizado correctamente",
        });

        setShowMessage(true);
        setDisplayDialogParent(false);
        updateTableRow(updateModifiedValues(updateObj, "update"));
      })
      .catch((e) => {
        // setLoading(false);
        //console.log(e);
      });
  };

  // const deleteExclusionHandler = () => {
  //   // setLoading(true);
  //   // console.log({
  //   //   id: idExclusion,
  //   //   motivo: "deshabilitado desde front",
  //   // });
  //   TipoExclusionService.deleteTipoExclusion({
  //     action: isHabilitado ? "disable" : "enable",
  //     id: idExclusion,
  //     motivo: "deshabilitado desde front",
  //   })
  //     .then((response) => {
  //       //console.log("disabled OK");
  //       //console.log(response);
  //       setMessage({
  //         severity: "success",
  //         messageText: isHabilitado ? "Exclusion fue deshabilitado" : "Exclusion fue habilitado",
  //       });
  //       setShowMessage(true);
  //       setDisplayDialogParent(false);
  //       updateTableRow(updateModifiedValues(crudData.data, "delete"));
  //     })
  //     .catch((e) => {
  //       // setLoading(false);
  //       //console.log(e);
  //     });
  // };

  const handleClose = () => {
    // eslint-disable-next-line react/prop-types
    setDisplayDialogParent(false);
  };

  return (
    // <StyledEngineProvider injectFirst>
    //   <Dialog open onClose={handleClose}>
    //     <DialogTitle id="form-dialog-title">
    //       {crudAction === "create" ? "Nuevo Tipo de Exclusión" : "Editar Tipo de Exclusión"}
    //       <IconButton
    //         aria-label="close"
    //         onClick={handleClose}
    //         sx={{
    //           position: "absolute",
    //           right: 8,
    //           top: 8,
    //           color: (theme) => theme.palette.grey[500],
    //         }}
    //       >
    //         <CloseIcon />
    //       </IconButton>
    //     </DialogTitle>

    //     <DialogContent dividers>
        <>
          {!triggerDeshabilitarConfirmation && (
            <>
              {/* {crudAction !== "create" && (
                <MDBox 
                sx={{
                  display: "flex",
                  justifyContent: "right",
                  flexDirection: "row",
                  alignItems: "right",
                  marginBottom: "2%"
                  
                }} */}
                {/* > */}
                {/* <div className="botonDeshabilitar"> */}
                  {/* <MDButton
                    size="small"
                    color={isHabilitado ? "error" : "success"}
                    variant="contained"
                    startIcon={<SaveIcon />}
                    onClick={() => {
                      setTriggerDeshabilitarConfirmation(true);
                    }}
                  >
                    {isHabilitado ? "Deshabilitar" : "Habilitar"}
                  </MDButton> */}
                {/* </div> */}
                {/* </MDBox>
              )} */}
              <MDBox >
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  
                }}
              >
              {/* <div className="containercrud"> */}
                {loading && <LinearProgress color="primary" className="progressBar" />}

                <div className="nombrecrud">
                  <TextField
                    // sx={{ width: "400px", pb: 1 }}
                    // inputProps={{
                    //   autoComplete: "off",
                    //   maxLength: 80,
                    // }}
                    id="descripcion"
                    variant="outlined"
                    size="small"
                    name="descripcion"
                    label="Descripción de Tipo de Exclusión"
                    sx={{ 
                      width: 350, 
                      marginBottom: "15px", 
                      marginLeft: "50px", 
                      marginRight: "50px" }}
                    autoFocus
                    value={nombreExclusion}
                    onChange={handleNombreExclusion}
                  />                  
                  <Autocomplete
                    id="combo-box-demo"
                    getOptionLabel={(option) => option.descripcion || ""} // if not found, return empty string to avoid controlled/uncontrolled component warning
                    options={tiposExclusiones}
                    value={selectedTipoExclusion}
                    // sx={{ width: 200, marginTop: "10px", marginBottom: "10px" }}
                    sx={{ 
                      width: 350, 
                      marginBottom: "15px", 
                      marginLeft: "50px", 
                      marginRight: "50px" }}
                    renderInput={(params) => <TextField {...params} label="Tipo" />}
                    onChange={(event, newInputValue) => {
                      console.log(newInputValue)
                      setTipoExclusion(newInputValue?.descripcion || "");
                      console.log(tipoExclusion)

                      setSelectedTipoExclusion(newInputValue);
                    }}
                  />
                </div>
                <div className="observacionescrud">
                  <TextField
                    id="observaciones"
                    variant="outlined"
                    size="small"
                    name="observaciones"
                    label={`Observaciones (${charactersLeftObservaciones} caracteres restantes)`}
                    sx={{ 
                      width: 350, 
                      marginBottom: "15px", 
                      marginLeft: "50px", 
                      marginRight: "50px" }}
                    multiline
                    rows={5}
                    value={observacionesExclusion}
                    onChange={handleObservacionesExclusion}
                  />
                </div>
                <div className="botonesaccionescrud">
                  <MDButton
                    sx={{ marginRight: "5px" }}
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Cancelar
                  </MDButton>
                  <Tooltip title={ !buttonGuardarOk ? "No ha realizado ningún cambio en los datos" : ""}>
                    <span>
                      <MDButton
                        onClick={crudAction === "create" ? newExclusionHandler : updateExclusionHandler}
                        size="small"
                        color="primary"
                        variant="contained"
                        startIcon={<SaveIcon />}
                        disabled={!buttonGuardarOk || crudAction == 'readonly'}
                        >
                        Guardar
                      </MDButton>
                    </span>
                  </Tooltip>
                </div>
              {/* </div> */}
              </MDBox>
              </MDBox>
            </>
          )}

          {/* {triggerDeshabilitarConfirmation && (
            <div className="containercrud">
              <div className="nombrecrud">
                <Typography
                  variant="h8"
                  component="div"
                  gutterBottom
                  color={isHabilitado ? "red" : "green"}
                >
                  {isHabilitado
                    ? "¿Está seguro que desea deshabilitar el Tipo de Exclusión?"
                    : "¿Está seguro que desea habilitar el Tipo de Exclusión?"}
                </Typography>
              </div>
              <div className="fueroName">
                <Typography variant="h8" component="div" gutterBottom>
                  {nombreExclusion}
                </Typography>
              </div>
              <div className="botonesaccionescrud">
                <MDButton
                  sx={{ marginRight: "5px" }}
                  size="small"
                  color="secondary"
                  onClick={handleClose}
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Cancelar
                </MDButton>
                <MDButton
                  onClick={deleteExclusionHandler}
                  size="small"
                  color="primary"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  Confirmar
                </MDButton>
              </div>
            </div>
          )} */}

          {/* {true && (
            <MDBox
              sx={{
                p: 1,
                color: "SlateGrey",
                border: "1px solid DarkGray",
                borderRadius: "3px",
              }}
            >
              <Typography variant="h8" component="div" gutterBottom>
                Creado:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Última actualizacion:
              </Typography>

              <Typography variant="h8" component="div" gutterBottom>
                Último usuario que actualizó:
              </Typography>
            </MDBox>
          )} */}
        {/* </DialogContent>
      </Dialog>
      </StyledEngineProvider> */}
    </>

  );
}

export default Crud;
