import Base from "./axiosBase";

const OrigenService = {
  createOrigen: async (origen) => Base.post("/origen/", origen),
  deleteOrigen: async (origen) =>
    Base.delete(`origen/?id=${origen.id}&action=${origen.action}`),
  updateOrigen: async (origen) => Base.put(`origen/?id=${origen.id}`, origen),

  // eslint-disable-next-line arrow-body-style
  getOrigen: async (params) => {
    // eslint-disable-next-line prettier/prettier
    //console.log("from service222");
    //console.log(params);
    let url = "";
    if (params.page) {
      url += `&first=${params.page}&`;
    }
    if (params.rowsPerPage) {
      url += `&rows=${params.rowsPerPage}&`;
    }
    if (params.sortOrder) {
      url += `&sortorder=${params.sortOrder}`;
    }

    if (params.filters) {
      if (params.filters.nombre) {
        url += `&descripcion=${params.filters.nombre}`;
      }
      if (params.filters.fuero) {
        url += `&id_fuero=${params.filters.fuero.id}`;
      }
      if (!params.filters.habilitado) {
        url += `&habilitado=D`;
      } else {
        url += `&habilitado=H`;
      }
    }

    if (url !== "") {
      url = `origen/?${url}`;
    } else {
      url = "origen/";
    }
    return Base.get(url).then((res) => res.data);
  },
};

export default OrigenService;
